import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import UpdatePasswordForm from './UpdatePasswordForm';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { passwordUpdate } from '../Api/Authentication';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .trim()
    .min(8, 'forgotten-password.confirm.password.invalid')
    .required('forgotten-password.confirm.password.required'),
  password: Yup.string()
    .trim()
    .min(8, 'forgotten-password.confirm.password.invalid')
    .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])/, 'supplier-registration.password.invalid')
    .required('forgotten-password.confirm.password.required'),
  confirmPassword: Yup.string()
    .trim()
    .required('forgotten-password.confirm.confirm-password.required')
    .oneOf([Yup.ref('password')], 'forgotten-password.confirm.confirm-password.error-match'),
});
const values = { currentPassword: '', password: '', confirmPassword: '' };

const styles = theme => ({
  root: {
    display: 'flex',
  },
  paperForm: {
    display: 'flex',
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing.unit * 2,
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing.unit * 5,
      width: '600px',
    },
    flexDirection: 'column',
    border: '1px solid #CCCBC8',
    alignItems: 'center',
  },
  topHeader: {
    paddingBottom: theme.spacing.unit * 2,
    textAlign: 'center',
    paddingTop: theme.spacing.unit * 5,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing.unit * 12,
    },
  },
  midHeader: {
    paddingBottom: theme.spacing.unit * 4,
    textAlign: 'center',
  },
});

function UpdatePassword(props) {
  const { classes, enqueueSnackbar } = props;

  return (
    <div className={classes.root}>
      <Formik
        render={props => <UpdatePasswordForm {...props} />}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          const data = {
            currentPassword: values.currentPassword,
            password: values.password,
            confirmPassword: values.confirmPassword,
          };
          passwordUpdate(data)
            .then(() => {
              enqueueSnackbar(<FormattedMessage id="forgotten-password.confirm.password.success" />);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      />
    </div>
  );
}

export default withStyles(styles)(withRouter(withSnackbar(UpdatePassword)));
