import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { getProjects } from '../Api/Jobs';
import { withStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import HeaderPageLinkBack from '../Pages/HeaderPageLinkBack';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '../FormFields/Divider';
import ProjectStatusChip from './Components/ProjectStatusChip';
import Progress from '../FormFields/Progress';
import { format } from 'date-fns';
import AvatarStack from './Components/AvatarStack';
import { FormattedMessage } from 'react-intl';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getJobComms } from '../Api/Comms';
import ShortlistComponent from './Components/ShortlistComponent';
import ProjectBriefComponent from './Components/ProjectBriefComponent';
import { ProfileContext } from '../Profile/ProfileContext';
import { statuses } from './ProjectStatuses';
import SowsComponent from './Components/SowsComponent';
import FinishProjectComponent from './Components/FinishProjectComponent';

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing.unit * 2,
      paddingLeft: theme.spacing.unit * 3,
      paddingRight: theme.spacing.unit * 3,
      paddingBottom: theme.spacing.unit,
    },
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 10,
    display: 'flex',
    flexDirection: 'column',
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  projectStatusChip: {
    marginLeft: theme.spacing.unit * 2,
  },
  dateArea: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit,
  },
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
  tabBarRoot: {
    flexGrow: '1',
  },
  dividerBottom: {
    marginBottom: theme.spacing.unit * 2,
  },
});

export function ProjectPage(props) {
  const { classes, match, history } = props;
  const [project, setProject] = useState(false);
  const [tabs, setTabs] = useState([]);
  const [comms, setComms] = useState(false);
  const value = match.params.page ? match.params.page : 'shortlist';
  const projectId = Number(match.params.id);
  const { userType } = useContext(ProfileContext);
  const supplierTabs = [
    {
      key: 'brief',
      label: 'projects.management-page.project-brief',
      component: <ProjectBriefComponent project={project} />,
      disabled: false,
    },
    {
      key: 'sow',
      label: 'projects.management-page.sow',
      component: <SowsComponent comms={comms} />,
      disabled: true,
    },
    {
      key: 'finish-project',
      label: 'projects.management-page.finish-project',
      component: <FinishProjectComponent project={project} userType={userType} />,
      disabled: true,
    },
  ];
  const clientTabs = [
    {
      key: 'shortlist',
      label: 'projects.management-page.shortlist',
      component: <ShortlistComponent project={project} comms={comms} setComms={setComms} />,
      disabled: false,
    },
    ...supplierTabs,
  ];

  async function fetchData() {
    const [projectResp, commResp] = await Promise.all([getProjects(), getJobComms(projectId)]);

    setProject(get(projectResp, 'data.data', []).find((p) => p.id === projectId));
    setComms(get(commResp, 'data.data', []));
  }

  useEffect(() => {
    fetchData();
  }, [projectId]);

  useEffect(() => {
    if (project && comms && userType) {
      if (project.status >= statuses.pending_sow) {
        supplierTabs[1].disabled = false;
      }

      if (project.sow && project.sow.signed_sow && project.status !== statuses.done) {
        supplierTabs[2].disabled = false;
      }

      setTabs(userType === 'supplier' ? supplierTabs : clientTabs);
      if (userType === 'supplier' && value === 'shortlist') {
        history.push({
          pathname: `/project/${projectId}/brief`,
        });
      }
    }
  }, [userType, project, comms]);

  return (
    <HeaderPageLinkBack>
      <Paper className={classes.root}>
        {project && comms && userType ? (
          <>
            <Typography variant="h4" color="inherit" className={classes.headerRow}>
              {project.title} <ProjectStatusChip status={project.status} className={classes.projectStatusChip} />
            </Typography>
            <Typography variant="caption" className={classes.dateArea}>
              Created on {format(new Date(project.created_at), 'DD/MM/YYYY')}
            </Typography>
            <AvatarStack items={get(project, 'shortlist.suppliers', [project.client])} size="tiny" />
            <Divider className={classes.divider} />
            <Tabs
              classes={{ root: classes.tabBarRoot }}
              value={value}
              variant="scrollable"
              scrollButtons="off"
              onChange={(e, v) => {
                history.push({
                  pathname: `/project/${projectId}/${v}`,
                });
              }}
            >
              {tabs.map((tabInfo) => {
                const label = (
                  <Typography variant="body2" color="textPrimary" className={classes.tab} key={tabInfo.key}>
                    <FormattedMessage id={tabInfo.label} />
                  </Typography>
                );
                return (
                  <Tab
                    value={tabInfo.key}
                    label={label}
                    key={tabInfo.key}
                    classes={{ root: classes.tabRoot }}
                    disabled={tabInfo.disabled}
                  />
                );
              })}
            </Tabs>
            <Divider className={classes.dividerBottom} />

            {tabs.map(
              (tabInfo) => value === tabInfo.key && <TabContainer key={tabInfo.key}>{tabInfo.component}</TabContainer>,
            )}
          </>
        ) : (
          <Progress />
        )}
      </Paper>
    </HeaderPageLinkBack>
  );
}

function TabContainer(props) {
  return <div>{props.children}</div>;
}

export default withStyles(styles)(withRouter(ProjectPage));
