import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import Divider from '../FormFields/Divider';
import Typography from '@material-ui/core/Typography';
import TextInput from '../FormFields/TextInput';
import ChipCheckboxGroup from '../FormFields/ChipCheckboxGroup';
import FileUploader from '../FormFields/FileUploader';
import { fileUploads } from '../Api/SupplierProfile';
import ProgressButton from '../FormFields/ProgressButton';
import Prompt from '../Prompt/Prompt';

const styles = (theme) => ({
  root: {
    '& > *': {
      marginBottom: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing.unit * 4,
      },
    },
  },
  smallerMarginBottom: {
    marginBottom: theme.spacing.unit * 2,
  },
  saveForLater: { justifyContent: 'center', display: 'flex' },
});

function PortfolioForm(props) {
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    isValid,
    handleChange,
    handleBlur,
    // handleSubmit,
    // handleReset,
    setFieldValue,
    classes,
    availableSkills,
    unavailableSkills,
    intl,
  } = props;

  return (
    <form onSubmit={props.handleSubmit} className={classes.root}>
      <Prompt when={dirty} message={() => intl.formatMessage({ id: 'prompt.unsaved' })} />
      <Typography variant="body2" color="inherit" className={classes.topHeader}>
        <FormattedMessage id="supplier-profile.step2.add-project.prose" />
      </Typography>
      <Typography variant="body2" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedMessage id="required-fields.prose" />
      </Typography>
      <TextInput
        required
        fullWidth
        margin="normal"
        name="title"
        value={values.title}
        placeholder="supplier-profile.project-title.placeholder"
        label="supplier-profile.project-title.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        required
        fullWidth
        margin="normal"
        name="bio"
        value={values.bio}
        placeholder="supplier-profile.project-bio.placeholder"
        label="supplier-profile.project-bio.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
        multiline
        rows="4"
      />
      <TextInput
        fullWidth
        margin="normal"
        name="url"
        value={values.url}
        placeholder="supplier-profile.project-url.placeholder"
        label="supplier-profile.project-url.label"
        helperText={
          touched.url && errors.url && values.url && values.url.length > 0
            ? errors.url
            : 'supplier-profile.project-url.helper'
        }
        error={Boolean(touched.url && errors.url && values.url && values.url.length > 0)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <FileUploader
        name="files"
        showFiletypeIcon
        iconFiletypes={['jpg', 'png', 'pdf']}
        uploadPromise={fileUploads}
        files={values.files}
        onClick={setFieldValue}
      />
      <Typography variant="h6" color="inherit" className={classes.topHeader}>
        <FormattedMessage id="supplier-profile.step2.skills-used" />*
      </Typography>
      <ChipCheckboxGroup name="skills" options={availableSkills} storedValues={values.skills} onClick={setFieldValue} />
      <Divider />
      <Typography variant="h6" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedMessage id="supplier-profile.step2.skills-not-available" />
      </Typography>
      <Typography variant="body2" color="inherit" className={classes.smallerMarginBottom}>
        (<FormattedMessage id="supplier-profile.step2.skills-not-available.more-info" />)
      </Typography>
      <ChipCheckboxGroup name="unavailableSkills" options={unavailableSkills} disabled />
      <Divider />
      <ProgressButton
        type="submit"
        submitting={isSubmitting}
        buttonBusy={isSubmitting}
        disabled={!isValid}
        data-testid="submit-button"
      >
        {props.intl.formatMessage({
          id: 'portfolio.save',
        })}
      </ProgressButton>
    </form>
  );
}

export default withStyles(styles)(injectIntl(PortfolioForm));
