import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { injectIntl, FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { withTheme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import ProgressButton from '../../FormFields/ProgressButton';
import TextInput from '../../FormFields/TextInput';
import { sendSigning } from '../../Api/SOW';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';

const styles = theme => ({
  dialogContent: {
    paddingBottom: 0,
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: theme.spacing.unit * 85,
    display: 'flex',
    '& > *': {
      width: theme.spacing.unit * 52,
    },
  },
  blurb: {
    marginTop: theme.spacing.unit * 2,
  },
  dialogActions: {
    paddingRight: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
    flexDirection: 'column',
    margin: 0,
  },
});

const defaultValues = { removeSupplierReason: '1' };

const validationSchema = Yup.object().shape({
  signature: Yup.string()
    .required('required-fields.invalid')
    .trim()
    .min(1),
});

function SignSOWModal(props) {
  const { classes, theme, modalOpen, onCancel, forceScreen, commId, enqueueSnackbar, intl, history } = props;
  const smallScreen = forceScreen ? false : useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={smallScreen}
      maxWidth={'md'}
      open={modalOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          sendSigning(commId, values).then(props => {
            enqueueSnackbar(
              intl.formatMessage({
                id: 'Signing completed, thank you',
              }),
            );
            onCancel();
            actions.setSubmitting(false);
            history.push({ pathname: '/dashboard' });
          });
        }}
        render={props => {
          const { values, touched, errors, isValid, handleSubmit, handleChange, handleBlur, isSubmitting } = props;
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent className={classes.dialogContent}>
                <Typography variant="h4">
                  <FormattedMessage id="projects.sow.sign.header" />
                </Typography>
                <Typography variant="body2" className={classes.blurb}>
                  <FormattedMessage id="projects.sow.sign.blurb" />
                </Typography>
                <TextInput
                  fullWidth
                  margin="normal"
                  placeholder="projects.sow.sign.text.placeholder"
                  label="projects.sow.sign.text.label"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.signature}
                  helperText={touched.signature && errors.signature ? errors.signature : null}
                  error={Boolean(touched.signature && errors.signature)}
                  name="signature"
                />
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <ProgressButton
                  type="submit"
                  fullWidth
                  disabled={isSubmitting || !isValid}
                  buttonBusy={isSubmitting}
                  submitting={isSubmitting}
                >
                  <FormattedMessage id="projects.sow.sign.button" />
                </ProgressButton>
                <Button onClick={onCancel} fullWidth variant={'outlined'} className={classes.closeButton}>
                  <FormattedMessage id="cancel" />
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
}

SignSOWModal.propTypes = {
  classes: PropTypes.object.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

SignSOWModal.defaultProps = {
  onSubmit: () => {},
};

export function useModal(initialValue) {
  const [modalOpen, setModalOpen] = useState(initialValue);
  return [modalOpen, setModalOpen];
}

export default withTheme()(withStyles(styles)(withSnackbar(withRouter(injectIntl(SignSOWModal)))));
