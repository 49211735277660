import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import { ShortlistContext } from '../Shortlist/ShortlistContext';
import { SmallAvatar } from '../SupplierProfile/SupplierAvatar';
import checkedIcon from '../assets/Icon-CheckmarkSelected.svg';
import ProgressButton from '../FormFields/ProgressButton';
import AvatarStack from '../Projects/Components/AvatarStack';

const styles = theme => ({
  dialogContent: {
    padding: theme.spacing.unit * 3,
    flexDirection: 'column',
  },
  dialogActions: {
    padding: theme.spacing.unit * 3,
    flexDirection: 'column',
  },
  firstButton: {
    marginBottom: theme.spacing.unit * 2,
  },
  listItemRow: {
    alignItems: 'center',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  avatar: {
    height: 48,
    width: 48,
  },
  removalItem: {
    // backgroundColor: 'grey',
  },
});

function ShortlistModal(props) {
  const { classes, theme, modalOpen, onCancel, forceScreen, suppliersToAdd, enqueueSnackbar } = props;
  const smallScreen = forceScreen ? false : useMediaQuery(theme.breakpoints.down('sm'));
  const { shortlistedSuppliers, synchlist } = useContext(ShortlistContext);
  const [forRemoval, setForRemoval] = useState([]);
  const [updateInProgress, setUpdateInProgress] = useState(false);

  function saveChanges() {
    setUpdateInProgress(true);
    synchlist(forRemoval, suppliersToAdd.map(s => s.id))
      .then(() => {
        enqueueSnackbar(<FormattedMessage id="client.shortlist.popup.success" />);
        setUpdateInProgress(false);
        onCancel();
      })
      .catch(() => {
        enqueueSnackbar(<FormattedMessage id="client.shortlist.popup.failure" />);
      });
  }

  return (
    <Dialog
      fullScreen={smallScreen}
      maxWidth={'md'}
      open={modalOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
      scroll="paper"
    >
      <DialogContent className={classes.dialogContent}>
        <DialogTitle>
          <FormattedMessage id="client.shortlist.popup.header" />
        </DialogTitle>
        <Typography variant="body2" align="center" paragraph>
          <FormattedMessage id="client.shortlist.popup.prose" />
        </Typography>
        <Typography variant="overline" paragraph>
          <FormattedMessage id="client.shortlist.popup.add" />
        </Typography>
        <AvatarStack items={suppliersToAdd} />
        <Typography variant="overline" paragraph>
          <FormattedMessage id="client.shortlist.popup.current" />
        </Typography>
        <List>
          {shortlistedSuppliers.map(s => (
            <TogglableShortlistItem
              key={s.id}
              item={s}
              classes={classes}
              forRemoval={forRemoval.find(fr => fr === s.id)}
              onClick={() => {
                forRemoval.find(fr => fr === s.id)
                  ? setForRemoval(prevVals => prevVals.filter(fr => fr !== s.id))
                  : setForRemoval(prevVals => [...prevVals, s.id]);
              }}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ProgressButton
          submitting={updateInProgress}
          buttonBusy={updateInProgress}
          onClick={saveChanges}
          className={classes.firstButton}
          disabled={!forRemoval.length}
        >
          <FormattedMessage id="save" />
        </ProgressButton>
        <Button onClick={onCancel} fullWidth variant="outlined">
          <FormattedMessage id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function useModal(initialValue) {
  const [modalOpen, setModalOpen] = useState(initialValue);
  const [suppliersToAdd, setSuppliersToAdd] = useState([]);
  return [modalOpen, setModalOpen, suppliersToAdd, setSuppliersToAdd];
}

ShortlistModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

ShortlistModal.defaultProps = {
  modalOpen: false,
  suppliersToAdd: [],
};

function TogglableShortlistItem(props) {
  const { item, classes, forRemoval, onClick } = props;

  return (
    <ListItem
      alignItems="center"
      button
      className={classNames(classes.listItemRow, { [classes.removalItem]: forRemoval })}
      onClick={onClick}
    >
      <ListItemAvatar>
        {forRemoval ? (
          <Avatar className={classes.avatar}>
            <img src={checkedIcon} alt="" className={classes.ratesIcon} />
          </Avatar>
        ) : (
          <SmallAvatar item={item} />
        )}
      </ListItemAvatar>
      <ListItemText primary={item.name} />
    </ListItem>
  );
}

export default withTheme()(withStyles(styles)(withSnackbar(ShortlistModal)));
