import React from 'react';
import { useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import RegistrationForm from './RegistrationForm';
import { supplierRegistration, clientRegistration } from '../Api/Authentication';

const validationSchema = Yup.object().shape({
  givenName: Yup.string()
    .trim()
    .required('supplier-registration.givenName.required')
    .min(1),
  email: Yup.string()
    .trim()
    .email('supplier-registration.email.invalid')
    .required('supplier-registration.email.required')
    .min(1),
  password: Yup.string()
    .trim()
    .min(8, 'supplier-registration.password.invalid')
    .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])/, 'supplier-registration.password.invalid')
    .required('supplier-registration.password.required'),
  confirmPassword: Yup.string()
    .trim()
    .required('supplier-registration.confirm-password.required')
    .oneOf([Yup.ref('password')], 'supplier-registration.confirm-password.error-match'),
  agreeToTerms: Yup.bool()
    .required('supplier-registration.terms.label')
    .test('agreeToTerms', 'supplier-registration.terms.label', value => value === true),
});

const styles = theme => ({
  root: {
    maxWidth: '1024px',
    alignItems: 'center',
  },
  paperForm: {
    display: 'flex',
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing.unit * 2,
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing.unit * 5,
      width: '600px',
    },
    flexDirection: 'column',
    border: '1px solid #CCCBC8',
    alignItems: 'center',
  },
  topHeader: {
    paddingBottom: theme.spacing.unit * 2,
    textAlign: 'center',
    paddingTop: theme.spacing.unit * 5,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing.unit * 12,
    },
  },
  midHeader: {
    paddingBottom: theme.spacing.unit * 4,
    textAlign: 'center',
  },
});

function Registration(props) {
  const { classes } = props;

  const [values] = useState({
    givenName: '',
    email: '',
    confirmPassword: '',
    password: '',
    agreeToTerms: false,
    supplierOrClient: 0,
  });

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Typography variant="h2" color="inherit" className={classes.topHeader}>
          <FormattedHTMLMessage id="supplier-registration.prose.header" />
        </Typography>
      </Hidden>
      <Hidden mdUp>
        <Typography variant="h4" color="inherit" className={classes.topHeader}>
          <FormattedHTMLMessage id="supplier-registration.prose.header" />
        </Typography>
      </Hidden>
      <Typography variant="body1" color="inherit" className={classes.midHeader}>
        <FormattedHTMLMessage id="supplier-registration.prose.expanded" />
      </Typography>
      <Paper className={classes.paperForm} elevation={0}>
        <Formik
          render={props => <RegistrationForm {...props} />}
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            const data = {
              givenName: values.givenName,
              email: values.email,
              password: values.password,
              confirmPassword: values.confirmPassword,
              agreeToTerms: values.agreeToTerms,
            };
            (values.supplierOrClient ? clientRegistration(data) : supplierRegistration(data))
              .then(() => {
                props.history.push({
                  pathname: '/register/success',
                  state: { email: values.email },
                });
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
        />
        <Typography variant="body2" color="inherit">
          <FormattedMessage
            id="supplier-registration.preexisting-account.login"
            values={{
              link: (
                <Link to="/login">
                  <FormattedMessage id="login" />
                </Link>
              ),
            }}
          />
        </Typography>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(withRouter(Registration));
