import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as PreparingDashboardImage } from '../assets/Preparing-Dashboard.svg';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing.unit * 6,
    width: theme.spacing.unit * 85,
    border: `1px solid ${theme.palette.grey[400]}`,
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    flexGrow: 1,
    display: 'flex',
    paddingTop: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 6,
  },
  cardContent: {
    maxWidth: theme.spacing.unit * 70,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    flexGrow: 1,
    '& > *': {
      marginBottom: '0.75em',
    },
  },
  timeTaker: {
    paddingTop: '10px',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeTakerText: {
    display: 'flex',
  },
});

function AdminValidationPromptPage(props) {
  const { classes } = props;
  return (
    <Paper className={classes.paper} elevation={0}>
      <Card className={classes.card}>
        <PreparingDashboardImage />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5">
            <FormattedMessage id="admin-validation.prompt.header" />
          </Typography>
          <Typography gutterBottom variant="body2">
            <FormattedMessage id="admin-validation.prompt.more-info-blurb" />
          </Typography>
          <Typography gutterBottom variant="body2" className={classes.timeTaker}>
            <FormattedHTMLMessage
              className={classes.timeTakerText}
              id="admin-validation.prompt.time-estimate"
              values={{ mail: 'hello@jam-pan.com' }}
            />
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default withStyles(styles)(AdminValidationPromptPage);
