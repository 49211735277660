import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Button, Chip } from '@material-ui/core';
import AttachFile from '@material-ui/icons/AttachFile';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'end',
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  iconSmall: {
    fontSize: 20,
  },
  uploadedFiles: { display: 'flex', flexDirection: 'column', alignItems: 'end' },
  chip: {
    marginBottom: theme.spacing.unit,
  },
});

function MessageFileUploader({ classes, name, disabled, files, onChange, onDelete }) {
  return (
    <div>
      <div className={classes.root}>
        <input className={classes.input} id={name} name={name} multiple type="file" onChange={onChange} />
        <label htmlFor={name}>
          <Button className={classes.button} size="small" component="span" disabled={disabled}>
            <AttachFile className={classNames(classes.leftIcon, classes.iconSmall)} />
            <FormattedMessage id="communications.comms.message-box.attach-files" />
          </Button>
        </label>
      </div>
      <div className={classes.uploadedFiles}>
        {files &&
          [...files].map((file, i) => (
            <Chip
              key={`${file.name}-${i}`}
              label={file.name}
              onDelete={() => onDelete(i)}
              className={classes.chip}
              variant="outlined"
            />
          ))}
      </div>
    </div>
  );
}

export default withStyles(styles)(injectIntl(MessageFileUploader));
