import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import { fetchCategories } from '../Api/FetchAndCache';
import { getSearchedSuppliers } from '../Api/ClientSearch';
import SearchResultList from './SearchResultList';
import SearchResultsPageHeader from './Components/SearchResultsPageHeader';
import InfiniteLoader, { usePagination } from './Components/InfiniteLoader';
import { SearchResultsContext } from './SearchResultsContext';
import { ShortlistContext } from '../Shortlist/ShortlistContext';

const styles = (theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing.unit * 5,
    [theme.breakpoints.up('md')]: {
      width: theme.spacing.unit * 90,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export function SearchResultsPage(props) {
  const { classes, match, history, forceScreen } = props;
  const [searchedValue, setSearchedValue] = useState(Number(match.params.id));
  const [searchedLabel, setSearchedLabel] = useState('');

  const [tempSearchResults, setTempSearchResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultCount, setSearchResultCount] = useState(0);
  const [currentPage, setCurrentPage, endPage, setEndPage] = usePagination();
  const [initialised, setInitialised] = useState(false);
  const [loading, setLoading] = useState(false);
  const { selectedFilters, resetSelectedFilters, resetAvailabilityFilters, setSearchItem } = useContext(
    SearchResultsContext,
  );
  const debouncedSelectedFilters = useDebounce(selectedFilters, 2000);
  const { shortlistedSuppliers } = useContext(ShortlistContext);

  useEffect(() => {
    if (initialised) {
      resetSelectedFilters();
      resetAvailabilityFilters();
    }
    history.push({ pathname: `/search/${searchedValue}` });
  }, [searchedValue]);

  useEffect(() => {
    reset();
    fetchCategories().then((result) => {
      setSearchedLabel(result.find((c) => c.id === Number(match.params.id)).name);
      setSearchItem(result.find((c) => c.id === Number(match.params.id)));
    });
  }, [match.params.id]);

  useEffect(() => {
    reset();
  }, [debouncedSelectedFilters]);

  useEffect(() => {
    const mappedTempSearchResults = tempSearchResults.map((r) => {
      r.shortlisted = shortlistedSuppliers.some((ss) => ss.id === r.id);
      return r;
    });
    setSearchResults(mappedTempSearchResults);
  }, [shortlistedSuppliers, tempSearchResults]);

  function reset() {
    setSearchResultCount(0);
    setCurrentPage(0);
    setTempSearchResults([]);
    setSearchResults([]);
    setInitialised(false);
  }

  function loadMoreResults() {
    return new Promise((resolve) => {
      setLoading(true);

      let parsedFilter = { ...selectedFilters };
      Object.keys(parsedFilter).forEach(function (key, index) {
        if (key === 'remote') {
          const onsiteAvailable = parsedFilter[key].find((s) => s === 'onsite');
          const remoteAvailable = parsedFilter[key].find((s) => s === 'remote');
          if (onsiteAvailable && remoteAvailable) {
            parsedFilter[key] = [0];
          } else if (onsiteAvailable && !remoteAvailable) {
            parsedFilter[key] = [0, 1];
          } else if (!onsiteAvailable && remoteAvailable) {
            parsedFilter[key] = [0, 2];
          } else {
            parsedFilter[key] = [0, 1, 2];
          }
        }
        if (key === 'availability') {
          const availabilityValues = parsedFilter[key][1];
          if (availabilityValues) {
            Object.entries(availabilityValues).forEach(([valueKey, val]) => {
              if (Array.isArray(availabilityValues[valueKey])) {
                parsedFilter[valueKey] = availabilityValues[valueKey].join();
              } else {
                parsedFilter[valueKey] = val;
              }
            });
          }
          parsedFilter[key] = parsedFilter[key][0];
        }
      });

      getSearchedSuppliers(Number(match.params.id), currentPage + 1, 10, parsedFilter)
        .then((result) => {
          setInitialised(true);
          setTempSearchResults((prevResults) => {
            return [...prevResults, ...get(result, 'data.data.data', [])];
          });
          setSearchResultCount(get(result, 'data.data.total', 0));
          setCurrentPage(get(result, 'data.data.current_page', 0));
          setEndPage(get(result, 'data.data.last_page', 0));
          resolve(result);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }

  return (
    <>
      <SearchResultsPageHeader onChange={setSearchedValue} loading={loading} />
      <div className={classes.root}>
        <div className={classes.headerRow}>
          <Typography variant="h5" color="inherit">
            <FormattedMessage id="client.find.results-for" values={{ skillLabel: searchedLabel }} />
          </Typography>
          {searchResults.length ? (
            <Typography variant="h5" color="inherit">
              <FormattedMessage id="client.find.results" values={{ count: searchResultCount }} />
            </Typography>
          ) : null}
        </div>
        <InfiniteLoader
          loadMore={loadMoreResults}
          searchResults={searchResults}
          ListChild={SearchResultList}
          currentPage={currentPage}
          endPage={endPage}
          initialised={initialised}
          forceScreen={forceScreen}
        />
      </div>
    </>
  );
}

export default withStyles(styles)(withRouter(SearchResultsPage));
