import { createMuiTheme } from '@material-ui/core/styles';

const primary = '#fac446';
const spacing = 8;
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: primary,
      dark: '#F08730',
    },
    secondary: {
      main: '#00b396',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      '"Avenir Heavy"',
      'AvenirLTStd-Roman',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: spacing * 7,
      fontWeight: '900',
      lineHeight: '68px',
      letterSpacing: '-0.79px',
      color: 'rgba(0,0,0,0.87)',
    },
    h2: {
      fontSize: spacing * 6,
      fontWeight: '900',
      lineHeight: '58px',
      letterSpacing: '-0.36px',
      color: 'rgba(0,0,0,0.87)',
    },
    h3: {
      fontSize: spacing * 5,
      fontWeight: '900',
      lineHeight: '48px',
      color: 'rgba(0,0,0,0.87)',
    },
    h4: {
      fontSize: '28px',
      fontWeight: '900',
      lineHeight: '34px',
      letterSpacing: '-0.16px',
      color: 'rgba(0,0,0,0.87)',
    },
    h5: {
      fontSize: '20px',
      fontWeight: '900',
      lineHeight: '24px',
      color: 'rgba(0,0,0,0.87)',
    },
    h6: {
      fontSize: '18px',
      fontWeight: '900',
      lineHeight: '18px',
      color: 'rgba(0,0,0,0.87)',
    },
    body1: {
      fontFamily: 'AvenirLTStd-Roman',
      fontSize: '18px',
      fontWeight: '300',
      lineHeight: '28px',
      letterSpacing: '0.5px',
      color: 'rgba(0,0,0,0.6)',
    },
    body2: {
      fontFamily: 'AvenirLTStd-Roman',
      fontSize: spacing * 2,
      fontWeight: '300',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: 'rgba(0,0,0,0.6)',
    },
    subtitle1: {
      fontSize: '18px',
      fontWeight: '900',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: 'rgba(0,0,0,0.6)',
    },
    subtitle2: {
      fontSize: '15px',
      fontWeight: '900',
      lineHeight: '24px',
      letterSpacing: '0.1px',
      color: 'rgba(0,0,0,0.6)',
    },
    caption: {
      fontFamily: 'AvenirLTStd-Roman',
      fontSize: '13px',
      fontWeight: '300',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: 'rgba(0,0,0,0.6)',
    },
    overline: {
      fontSize: '13px',
      fontWeight: '900',
      lineHeight: '16px',
      letterSpacing: '2px',
      color: 'rgba(0,0,0,0.6)',
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        borderTopColor: primary,
        borderTopStyle: 'solid',
        borderTopWidth: '2px',
        flexGrow: 1,
        width: '100%',
        display: 'flex',
      },
    },
    MuiTabs: {
      flexContainer: {
        // flex: 1,
      },
      indicator: {
        backgroundColor: 'black',
      },
    },
    MuiTab: {
      label: {
        textTransform: 'none',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: spacing * 2,
        color: '#212121',
      },
    },
    MuiButton: {
      root: {
        height: spacing * 7,
        fontSize: '18px',
        fontWeight: '900',
        textTransform: 'none',
      },
      contained: {
        boxShadow: '0 1px 3px 0 rgba(240,135,48,0.20)',
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'AvenirLTStd-Roman',
        fontWeight: '300',
        fontSize: '14px',
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: 'AvenirLTStd-Roman',
        fontWeight: '300',
      },
    },
    MuiPaper: {
      root: {
        display: 'flex',
      },
    },
    MuiListItem: {
      root: {
        alignItems: 'flex-start',
      },
    },
  },
});
