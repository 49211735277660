export function updateLocalProfile(data) {
  localStorage.setItem('profile', JSON.stringify(data));
}

export function getLocalProfile() {
  return JSON.parse(localStorage.getItem('profile') || '{}');
}

export function updateLocalLanguages(data) {
  localStorage.setItem('languages', JSON.stringify(data));
}

export function getLocalLanguages() {
  return JSON.parse(localStorage.getItem('languages') || '[]');
}

export function updateLocalCountries(data) {
  localStorage.setItem('countries', JSON.stringify(data));
}

export function getLocalCountries() {
  return JSON.parse(localStorage.getItem('countries') || '[]');
}

export function updateLocalCategories(data) {
  localStorage.setItem('categories', JSON.stringify(data));
}

export function getLocalCategories() {
  return JSON.parse(localStorage.getItem('categories') || '[]');
}

export function updateLocalRates(data) {
  localStorage.setItem('rates', JSON.stringify(data));
}

export function getLocalRates() {
  return JSON.parse(localStorage.getItem('rates') || '[]');
}

export function updateLocalProjects(data) {
  localStorage.setItem('projects', JSON.stringify(data));
}

export function getLocalProjects() {
  return JSON.parse(localStorage.getItem('projects') || '[]');
}

export function updateLocalCurrencies(data) {
  localStorage.setItem('currencies', JSON.stringify(data));
}

export function getLocalCurrencies() {
  return JSON.parse(localStorage.getItem('currencies') || '[]');
}

export function updateLocalIndustries(data) {
  localStorage.setItem('industries', JSON.stringify(data));
}

export function getLocalIndustries() {
  return JSON.parse(localStorage.getItem('industries') || '[]');
}

export function updateLocalRoles(data) {
  localStorage.setItem('roles', JSON.stringify(data));
}

export function getLocalRoles() {
  return JSON.parse(localStorage.getItem('roles') || '[]');
}

export function updateLocalCompanySizes(data) {
  localStorage.setItem('companySizes', JSON.stringify(data));
}

export function getLocalCompanySizes() {
  return JSON.parse(localStorage.getItem('companySizes') || '[]');
}

export function setUserType(data) {
  localStorage.setItem('userType', data);
}

export function getUserType() {
  return localStorage.getItem('userType');
}
