import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/LockOutlined';
import TextInput from '../../FormFields/TextInput';
import { Formik } from 'formik';
import { Typography, FormControlLabel, Checkbox, InputAdornment } from '@material-ui/core';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import ProgressButton, { useProgressButton } from '../../FormFields/ProgressButton';
import { sendInvoiceDetails } from '../../Api/Comms';

const values = { name: '', email: '', address: '', ponumber: '', paymentterms: '', legal: false };

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('required-fields.invalid')
    .min(1),
  email: Yup.string()
    .trim()
    .required('required-fields.invalid')
    .min(1),
  address: Yup.string()
    .trim()
    .required('required-fields.invalid')
    .min(1),
  ponumber: Yup.string()
    .trim()
    .required('required-fields.invalid')
    .min(1),
  paymentterms: Yup.number()
    .required('required-fields.invalid')
    .positive('required-fields.invalid')
    .min(1),
  legal: Yup.bool()
    .required('required-fields.invalid')    
});

function parseValuesToInvoice(values) {
  return {
    billingName: values.name,
    billingEmail: values.email,
    billingAddress: values.address,
    billingPONumber: values.ponumber,
    billingTerms: values.paymentterms,
    tcAccepted: values.legal,
  };
}
function ClientPaymentDetails(props) {
  const { classes, intl, onSubmit, commId } = props;
  const [buttonBusy, setButtonBusy] = useProgressButton(false);

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        setButtonBusy(true);
        sendInvoiceDetails(commId, parseValuesToInvoice(values)).then(resp => {
          setButtonBusy(false);
          actions.setSubmitting(false);
          onSubmit(values, actions);
        });
      }}
      render={props => {
        const { values, touched, errors, handleSubmit, handleChange, handleBlur, isSubmitting, isValid } = props;
        return (
          <form className={classes.root} onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 16 }}>
              <LockIcon /> <FormattedMessage id="projects.sow.client-payment.details" />
            </div>
            <Typography variant="body2">
              <FormattedMessage id="projects.sow.client-payment.header" />
            </Typography>
            <div className={classes.row}>
              <TextInput
                placeholder="projects.sow.client-payment.name"
                label="projects.sow.client-payment.name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                helperText={touched.name && errors.name ? errors.name : null}
                error={Boolean(touched.name && errors.name)}
              />
              <TextInput
                placeholder="projects.sow.client-payment.email"
                label="projects.sow.client-payment.email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                helperText={touched.email && errors.email ? errors.email : null}
                error={Boolean(touched.email && errors.email)}
              />
            </div>
            <TextInput
              fullWidth
              placeholder="projects.sow.client-payment.address"
              label="projects.sow.client-payment.address"
              name="address"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              helperText={touched.address && errors.address ? errors.address : null}
              error={Boolean(touched.address && errors.address)}
            />
            <div className={classes.row}>
              <TextInput
                placeholder="projects.sow.client-payment.ponumber"
                label="projects.sow.client-payment.ponumber"
                name="ponumber"
                value={values.ponumber}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                helperText={touched.ponumber && errors.ponumber ? errors.ponumber : null}
                error={Boolean(touched.ponumber && errors.ponumber)}
              />
              <TextInput
                placeholder="projects.sow.client-payment.payment-terms"
                label="projects.sow.client-payment.payment-terms"
                name="paymentterms"
                value={values.paymentterms}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                type="number"
                helperText={touched.paymentterms && errors.paymentterms ? errors.paymentterms : null}
                error={Boolean(touched.paymentterms && errors.paymentterms)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">days</InputAdornment>,
                }}
              />
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  checked={!!values.legal}
                  name="legal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color="primary"
                  variant="outlined"
                />
              }
              label={
                <Typography variant="body2" style={{ color: 'black' }}>
                  {intl.formatMessage({
                    id: 'projects.sow.legal.label',
                  })}
                  *
                </Typography>
              }
            />
            <ProgressButton type="submit" buttonBusy={buttonBusy} disabled={!isValid || isSubmitting}>
              <FormattedMessage id="projects.sow.sign.button" />
            </ProgressButton>
          </form>
        );
      }}
    />
  );
}

const paymentDetailsStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& > *': {
      marginBottom: 16,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      width: '45%',
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
  },
});
export default withStyles(paymentDetailsStyles)(injectIntl(ClientPaymentDetails));
