import React from 'react';
import { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { fetchSupplierProfile } from '../Api/FetchAndCache';
import { updateLocalProfile } from '../Api/LocalStorage';
import get from 'lodash/get';
import Divider from '../FormFields/Divider';
import { fetchRates } from '../Api/FetchAndCache';
import { saveRate } from '../Api/SupplierProfile';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import ProgressButton, { useProgressButton } from '../FormFields/ProgressButton';
import HeaderPageLinkBack from '../Pages/HeaderPageLinkBack';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 5,
  },
  skillsPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
  },
  listRootSkillsPaperHeader: {
    marginTop: '40px',
  },
  rateHeader: {
    marginBottom: theme.spacing.unit,
  },
  selectFormControl: {
    marginTop: theme.spacing.unit * 2,
  },
  divider: {
    margin: `-${theme.spacing.unit * 3}px`,
    marginTop: theme.spacing.unit * 2,
    marginBottom: 0,
  },
});

export function Rates(props) {
  const { classes, match, enqueueSnackbar, history } = props;
  const [rates, setRates] = useState([]);
  const [skill, setSkill] = useState({});
  const [profileCurrency, setProfileCurrency] = useState('');
  const [buttonBusy, setButtonBusy] = useProgressButton(false);

  const fetchData = () => {
    fetchSupplierProfile().then(supplier => {
      setProfileCurrency(get(supplier, 'currency.symbol', ''));
      setSkill(supplier.skills.find(s => s.id === Number(match.params.id)));
    });
    fetchRates().then(result => {
      setRates(result);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  function handleOnClick(e) {
    setButtonBusy(true);
    saveRate(match.params.id, { rate: skill.pivot.cost_ranges_id }).then(result => {
      enqueueSnackbar('Rate saved');
      updateLocalProfile(get(result, 'data.data'));
      setButtonBusy(false);
      history.push({ pathname: '/skills' });
    });
  }

  return (
    <HeaderPageLinkBack>
      <div className={classes.root}>
        <Typography variant="h5" color="inherit">
          <FormattedMessage id="rates.header" />
        </Typography>
        <Paper className={classes.skillsPaper} elevation={1}>
          <Typography variant="h5" color="inherit" className={classes.rateHeader}>
            {skill.name}
          </Typography>
          <Typography variant="body2" color="inherit">
            <FormattedHTMLMessage id={'rates.prose'} />
          </Typography>
          <FormControl variant="outlined" className={classes.selectFormControl}>
            <InputLabel htmlFor="rate">
              <FormattedMessage id={'rates.range'} />
            </InputLabel>
            <Select
              native
              value={get(skill, 'pivot.cost_ranges_id', 0)}
              fullWidth
              input={<OutlinedInput labelWidth={145} name="rate" id="rate" />}
              onChange={e => {
                setSkill({ ...skill, pivot: { cost_ranges_id: e.target.value } });
              }}
            >
              <option value="" />
              {rates.map(r => {
                return (
                  <option value={r.id} key={r.id}>
                    {profileCurrency} {r.interval}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <Divider className={classes.divider} />
          <ProgressButton
            onClick={handleOnClick}
            buttonBusy={buttonBusy}
            disabled={!get(skill, 'pivot.cost_ranges_id', 0)}
          >
            <FormattedMessage id="save" />
          </ProgressButton>
        </Paper>
      </div>
    </HeaderPageLinkBack>
  );
}

export default withStyles(styles)(withSnackbar(withRouter(Rates)));
