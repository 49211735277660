import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PlaceIconFilled from '@material-ui/icons/Place';
import PlaceIconOutlined from '@material-ui/icons/PlaceOutlined';

const remoteArray = [
  {
    label: 'search.remote.yes',
    key: 0,
  },
  {
    label: 'search.remote.yes',
    key: 1,
  },
  {
    label: 'search.remote.remote-only',
    key: 2,
  },
];

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing.unit,
    '&> p': {
      marginLeft: theme.spacing.unit,
    },
  },
});
function RemoteAvailability(props) {
  const { item, classes, iconStyle } = props;
  return (
    <div className={classes.root}>
      {iconStyle === 'filled' ? <PlaceIconFilled /> : <PlaceIconOutlined />}
      <Typography variant="body2" color="textPrimary">
        <FormattedMessage id={remoteArray.filter(r => r.key === item.remote)[0].label} />
      </Typography>
    </div>
  );
}

RemoteAvailability.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  action: PropTypes.oneOf(['filled', 'outlined']),
};

RemoteAvailability.defaultProps = {
  iconStyle: 'filled',
};
export default withStyles(styles)(RemoteAvailability);
