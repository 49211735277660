import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import NoResultsFound from './NoResultsFound';
import SearchListItem from './SearchListItem';

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing.unit * 3,
      paddingLeft: theme.spacing.unit * 3,
      paddingRight: theme.spacing.unit * 3,
      paddingBottom: theme.spacing.unit,
    },
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'column',
  },
});
function SearchResultList(props) {
  const { classes, searchResults, loading, children, noResultsComponent, action, forceScreen } = props;
  return (
    <Paper className={classes.root} elevation={1}>
      {whatComponent(searchResults, loading, noResultsComponent, action, forceScreen)}
      {children}
    </Paper>
  );
}

function whatComponent(searchResults, loading, noResultsComponent, action, forceScreen) {
  if (!loading && searchResults.length === 0) {
    return noResultsComponent;
  } else {
    return (
      <>
        {searchResults.map((searchResult, idx) => {
          return <SearchListItem item={searchResult} key={searchResult.id} action={action} forceScreen={forceScreen} />;
        })}
      </>
    );
  }
}

SearchResultList.defaultProps = {
  searchResults: [],
  loading: false,
  noResultsComponent: <NoResultsFound />,
  action: 'toggle',
  forceScreen: false,
};

SearchResultList.propTypes = {
  action: PropTypes.oneOf(['trash', 'toggle']),
};

export default withStyles(styles)(injectIntl(SearchResultList));
