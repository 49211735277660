import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withCookies } from 'react-cookie';
import ButtonLink from '../FormFields/ButtonLink';
import { GA_TAG } from '../env';

const styles = (theme) => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    flexDirection: 'row',
    justifyContent: 'center',
    '& a': {
      color: theme.palette.primary.dark,
    },
  },
  toolBar: {
    maxWidth: theme.spacing.unit * 128,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  toolBarText: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    margin: theme.spacing.unit * 2,
    width: theme.spacing.unit * 25,
  },
  grow: {
    display: 'flex',
    flexGrow: 1,
  },
  buttonLink: {
    height: 'auto',
    minWidth: 'auto',
    textDecoration: 'underline',
    color: theme.palette.primary.dark,
    // fontSize: 'inherit',
    // fontWeight: 300,
  },
  buttonLinkText: {
    color: theme.palette.primary.dark,
  },
});

function CookieToolbar(props) {
  const { classes, cookies } = props;
  const consentReceived = cookies.get('cookie-consent');

  if (typeof consentReceived === 'undefined') {
    return (
      <AppBar color="default" position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.toolBarText}>
            <Typography variant="h5">
              <FormattedMessage id="cookies.toolbar.header" />
            </Typography>

            <Typography variant="body2">
              <FormattedMessage
                id="cookies.toolbar.policy-prose"
                values={{
                  cookiePolicyLink: (
                    <a href="https://www.jam-pan.com/cookie-policy/" rel="noreferrer noopener" target="_blank">
                      <FormattedMessage id="cookies.toolbar.cookies" />
                    </a>
                  ),
                }}
              />
              <br />
              <FormattedMessage
                id="cookies.toolbar.disent-prose"
                values={{
                  cookieDisentLink: (
                    <ButtonLink onClick={() => cookies.set('cookie-consent', false)} className={classes.buttonLink}>
                      <Typography variant="body2" className={classes.buttonLinkText}>
                        <FormattedMessage id="cookies.toolbar.here" />
                      </Typography>
                    </ButtonLink>
                  ),
                }}
              />
            </Typography>
          </div>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={() => {
              cookies.set('cookie-consent', true);
            }}
          >
            <FormattedMessage id="cookies.toolbar.button" />
          </Button>
        </Toolbar>
      </AppBar>
    );
  } else if (consentReceived === 'false') {
    window[`ga-disable-${GA_TAG}`] = true;
  }
  return null;
}

export default withStyles(styles)(withCookies(CookieToolbar));
