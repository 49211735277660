import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import defaultProfilePic from '../assets/Profile_Picture_Upload_Icon.svg';
import IconButton from '@material-ui/core/IconButton';
import UploadIcon from '@material-ui/icons/CloudUploadOutlined';
import TrashIcon from '@material-ui/icons/DeleteOutlined';
import Dropzone from 'react-dropzone';
import { withSnackbar } from 'notistack';
import { injectIntl } from 'react-intl';
import MuiAvatar from '@material-ui/core/Avatar';
import get from 'lodash/get';
import classNames from 'classnames';
import Progress from './Progress';
import { updateSupplierProfile } from '../Api/FetchAndCache';
import { uploadProfilePic, deleteProfilePic } from '../Api/SupplierProfile';

const avatarStyles = theme => ({
  avatar: { width: 112, height: 112, borderRadius: '50%', position: 'relative' },
  avatarBlank: { backgroundImage: `url(${defaultProfilePic})` },
  avatarMask: {
    backgroundColor: 'black',
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  avatarMaskHover: {
    opacity: 0,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    '&:hover': {
      opacity: 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarUploading: {
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarProgress: {
    color: theme.palette.primary.main,
  },
  avatarImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  uploadIcon: {
    color: 'white',
  },
});
function Avatar(props) {
  const { profilePics, enqueueSnackbar, intl } = props;
  const [imagePreview, setImagePreview] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(profilePics);
  }, [profilePics]);

  function fileError(file) {
    enqueueSnackbar(
      intl.formatMessage(
        {
          id: 'file.upload.invalid',
        },
        { file: file.name },
      ),
    );
  }
  function fileUpload(file) {
    const data = new FormData();
    data.append('file', file);
    setImagePreview(URL.createObjectURL(file));

    uploadProfilePic(data)
      .then(function(response) {
        updateSupplierProfile().then(() => {
          setImagePreview(null);
          setImages(get(response, 'data.data.files', []));
        });
      })
      .catch(e => {
        setImagePreview(null);
        const errorCode = get(e, 'response.status', 500);
        switch (errorCode) {
          case 400:
            // process messages :scream:
            const messages = get(e, 'response.data.messages', [{ file: '', message: 'error' }]);
            messages.forEach(message =>
              enqueueSnackbar(
                intl.formatMessage(
                  {
                    id: message.message,
                  },
                  { file: message.file },
                ),
              ),
            );
            break;
          case 413:
          case 415:
            enqueueSnackbar(
              intl.formatMessage({
                id: `error.${errorCode}`,
              }),
            );
            break;
          default:
            enqueueSnackbar(
              intl.formatMessage({
                id: 'error',
              }),
            );
            break;
        }
      });
  }
  function onDrop(acceptedFiles, rejectedFiles) {
    rejectedFiles.forEach(fileError);
    acceptedFiles.forEach(fileUpload);
  }

  function onDelete(e) {
    setImagePreview(get(images, '112x112.url', ''));
    deleteProfilePic().then(() => {
      updateSupplierProfile().then(() => {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'supplier-profile.avatar.deleted',
          }),
        );
        setImagePreview(null);
        setImages([]);
      });
    });
  }

  if (imagePreview) {
    return <StyledPreviewProgressAvatar image={imagePreview} />;
  } else if (images.length === 0) {
    return <StyledBlankAvatar onDrop={onDrop} />;
  } else {
    return <StyledProfileAvatar onDrop={onDrop} onDelete={onDelete} image={get(images, '112x112.url', '')} />;
  }
}

const uploaderStyles = {
  uploadIcon: {
    color: 'white',
  },
};
function Uploader(props) {
  const { classes, onDrop } = props;
  return (
    <Dropzone onDrop={onDrop} multiple={false} accept={['image/jpeg', 'image/png']} maxSize="2097152">
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <IconButton aria-label="Upload profile pic">
            <UploadIcon className={classes.uploadIcon} />
          </IconButton>
          <input {...getInputProps()} />
        </div>
      )}
    </Dropzone>
  );
}
const StyledUploader = withStyles(uploaderStyles)(Uploader);

function PreviewProgressAvatar(props) {
  const { classes, image } = props;
  return (
    <div className={classes.avatar}>
      <MuiAvatar className={classes.avatarImage} src={image} />
      <div className={classNames(classes.avatarMask, classes.avatarUploading)}>
        <div className={classes.avatarProgressContainer}>
          <Progress className={classNames(classes.avatar, classes.avatarProgress)} />
        </div>
      </div>
    </div>
  );
}
const StyledPreviewProgressAvatar = withStyles(avatarStyles)(PreviewProgressAvatar);

function BlankAvatar(props) {
  const { classes, onDrop } = props;
  return (
    <MuiAvatar className={classNames(classes.avatar, classes.avatarBlank)}>
      <div className={classNames(classes.avatarMask, classes.avatarMaskHover)}>
        <StyledUploader onDrop={onDrop} />
      </div>
    </MuiAvatar>
  );
}
const StyledBlankAvatar = withStyles(avatarStyles)(BlankAvatar);

function ProfileAvatar(props) {
  const { classes, onDrop, onDelete, image } = props;
  return (
    <div className={classes.avatar}>
      <MuiAvatar className={classes.avatarImage} src={image} />
      <div className={classNames(classes.avatarMask, classes.avatarMaskHover)}>
        <StyledUploader onDrop={onDrop} />
        <IconButton aria-label="Delete profile pic" onClick={onDelete}>
          <TrashIcon className={classes.uploadIcon} />
        </IconButton>
      </div>
    </div>
  );
}
const StyledProfileAvatar = withStyles(avatarStyles)(ProfileAvatar);

export default withSnackbar(injectIntl(Avatar));
