import React, { useState } from 'react';
import get from 'lodash/get';
import { getCommMessages } from 'Api/Comms';

function CommsProvider(props) {
  const { children } = props;
  const [comms, setComms] = useState(false);

  const retrieveComms = function(commId) {
    return new Promise((resolve, reject) => {
      setComms(false);
      getCommMessages(commId)
        .then(resp => {
          const data = get(resp, 'data.data');
          setComms(data);
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  };

  return (
    <CommsContext.Provider
      value={{
        comms: comms,
        retrieveComms: retrieveComms,
        setComms: setComms,
      }}
    >
      {children}
    </CommsContext.Provider>
  );
}

CommsProvider.defaultProps = {};

export const CommsContext = React.createContext();
export const CommsConsumer = CommsContext.Consumer;
export default CommsProvider;
