import axios from 'axios';

export function getCountries() {
  return axios.get('/api/countries');
}

export function getLanguages() {
  return axios.get('/api/languages');
}

export function getCategories() {
  return axios.get('/api/categories');
}

export function getRates() {
  return axios.get('/api/cost-ranges');
}

export function getCurrencies() {
  return axios.get('/api/currencies');
}

export function getIndustries() {
  return axios.get('/api/industries');
}

export function getRoles() {
  return axios.get('/api/roles');
}

export function getCompanySizes() {
  return axios.get('/api/company-size-ranges');
}
