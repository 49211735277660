import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ProgressButton from '../FormFields/ProgressButton';

const styles = (theme) => ({
  root: {
    '& > *': {
      marginBottom: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing.unit * 4,
      },
    },
  },
});

function MSAValidationForm(props) {
  const { values, isSubmitting, isValid, handleChange, handleBlur, handleSubmit, classes, intl, file } = props;
  return (
    <>
      <form onSubmit={handleSubmit} className={classes.root}>
        <Divider />
        <Typography variant="caption" color="inherit">
          <FormattedMessage
            id="msa-validation.msa.helper"
            values={{
              link: (
                <a href={file} target="_blank" rel="noreferrer noopener">
                  <FormattedMessage id="download" />
                </a>
              ),
            }}
          />
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={!!values.msaRead}
              name="msaRead"
              onChange={handleChange}
              onBlur={handleBlur}
              color="primary"
              variant="outlined"
            />
          }
          label={`${intl.formatMessage({ id: 'msa-validation.msa-read.label' })}*`}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={!!values.msaAgreed}
              name="msaAgreed"
              onChange={handleChange}
              onBlur={handleBlur}
              color="primary"
              variant="outlined"
              disabled={!values.msaRead}
            />
          }
          label={`${intl.formatMessage({ id: 'msa-validation.msa-agreed.label' })}*`}
        />

        <ProgressButton
          type="submit"
          buttonBusy={isSubmitting}
          disabled={!isValid}
          submitting={isSubmitting}
          data-testid="submit-button"
        >
          {props.intl.formatMessage({
            id: 'msa-validation.submit.text',
          })}
        </ProgressButton>
      </form>
      <Divider />
    </>
  );
}

export default withStyles(styles)(injectIntl(MSAValidationForm));
