import React from 'react';
import { injectIntl } from 'react-intl';
import TextInput from '../FormFields/TextInput';
import { withStyles } from '@material-ui/core/styles';
import ProgressButton from '../FormFields/ProgressButton';

const styles = theme => ({
  root: {
    '& > *': {
      marginBottom: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing.unit * 4,
      },
    },
  },
});

function ResetRequestForm(props) {
  const {
    values,
    touched,
    errors,
    // dirty,
    isSubmitting,
    isValid,
    handleChange,
    handleBlur,
    // handleSubmit,
    // handleReset,
    classes,
  } = props;

  return (
    <form onSubmit={props.handleSubmit} className={classes.root}>
      <TextInput
        required
        fullWidth
        margin="normal"
        name="email"
        value={values.email}
        placeholder="forgotten-password.request.email.placeholder"
        label="forgotten-password.request.email.label"
        helperText={touched.email && errors.email ? errors.email : 'forgotten-password.request.email.helper'}
        error={Boolean(touched.email && errors.email)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />

      <ProgressButton
        type="submit"
        submitting={isSubmitting}
        buttonBusy={isSubmitting}
        disabled={!isValid}
        data-testid="submit-button"
      >
        {props.intl.formatMessage({
          id: 'forgotten-password.request.submit.text',
        })}
      </ProgressButton>
    </form>
  );
}

export default withStyles(styles)(injectIntl(ResetRequestForm));
