import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { Route, Switch, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import axios from 'axios';
import size from 'lodash/size';
import get from 'lodash/get';
import forOwn from 'lodash/forOwn';
import isArray from 'lodash/isArray';
import { withRouter } from 'react-router-dom';

import Login from './Login/Login';
import Logout from './Login/Logout';
import Registration from './Registration/Registration';
import DashboardManager from './Dashboard/DashboardManager';
import Skills from './Skills/Skills';
import Rates from './Skills/Rates';
import Profile from './Profile/Profile';
import Portfolio from './Portfolio/Portfolio';
import PortfolioList from './Portfolio/PortfolioList';
import RegistrationSuccess from './Pages/RegistrationSuccess';
import ResetRequest from './ForgottenPassword/ResetRequest';
import ResetConfirm from './ForgottenPassword/ResetConfirm';
import ResetSuccess from './Pages/ResetSuccess';
import NotFoundPage from './Pages/NotFoundPage';
import ClientProfileOnBoardingVariant from './ClientProfile/ClientProfileOnBoardingVariant';
import SearchResultsPage from './ClientSearch/SearchResultsPage';
import RecommendedSuppliersPage from './ClientSearch/RecommendedSuppliersPage';

import { AuthenticationContext } from './Authentication/AuthenticationContext';
import OnBoardingVariant from './SupplierProfile/OnBoardingVariant';
import SupplierProfileClientView from './SupplierProfile/SupplierProfileClientView';

import { getUserType } from './Api/LocalStorage';
import SkillsAndProjects from './SkillsAndProjects/SkillsAndProjects';
import ShortlistPage from './Shortlist/ShortlistPage';
import HeaderFactory from './Header/HeaderFactory';
import ProjectBrief from './Projects/ProjectBrief';
import NotificationsPage from './Notifications/NotificationsPage';
import ReactGA from 'react-ga';
import CookieToolbar from './Pages/CookieToolbar';
import { GA_TAG, GA_TESTING } from './env';
import CommsPage from './Comms/CommsPage';
import ProjectPage from './Projects/ProjectPage';
import SowEditPage from './Sow/SowEditPage';
import SowPage from './Sow/SowPage';

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  app: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '& >*': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

let initialised = false;

function App(props) {
  const { classes, history, enqueueSnackbar } = props;
  const { logout } = useContext(AuthenticationContext);

  if (!initialised) {
    initialised = true;
    if (GA_TAG) {
      ReactGA.initialize(GA_TAG, { debug: GA_TESTING, testMode: GA_TESTING });
      history.listen(location => {
        return ReactGA.pageview(location.pathname);
      });
    }
    axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (error.response.status === 401) {
          logout();
        }

        if (size(error.response.data.messages) > 0) {
          forOwn(error.response.data.messages, messages => {
            if (isArray(messages)) {
              messages.forEach(message => {
                enqueueSnackbar(
                  props.intl.formatMessage({
                    id: message,
                  }),
                );
              });
            }
          });
        } else {
          enqueueSnackbar(
            props.intl.formatMessage({
              id: get(error, 'response.data.message', 'error'),
            }),
          );
        }
        return Promise.reject(error);
      },
    );
  }

  return (
    <div className={classes.container}>
      <HeaderFactory />
      <div className={classes.app}>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />
          <ProtectedRoute exact path="/logout" component={Logout} isProtected />
          <ProtectedRoute path="/dashboard" component={DashboardManager} isProtected />,
          <ProtectedRoute path="/notifications" component={NotificationsPage} isProtected />,
          <ProtectedRoute path="/comms/:id(\d+)" component={CommsPage} isProtected />,
          <ProtectedRoute path="/project/:id(\d+)/:page" component={ProjectPage} isProtected />,
          <ProtectedRoute path="/project/:id(\d+)" component={ProjectPage} isProtected />,
          <ProtectedRoute path="/sow/:id(\d+)/edit" component={SowEditPage} isProtected />,
          <ProtectedRoute path="/sow/:id(\d+)" component={SowPage} isProtected />,
          <ProtectedRoute exact key="profile-default" path="/profile" component={Profile} isProtected />,
          <ProtectedRoute exact key="profile-page" path="/profile/:page" component={Profile} isProtected />,
          {getUserType() === 'supplier' && [
            <ProtectedRoute exact key="skills-list" path="/skills" component={Skills} isProtected />,
            <ProtectedRoute exact key="skills-rates" path="/skills/rates/:id(\d+)" component={Rates} isProtected />,
            <ProtectedRoute exact key="portfolio-list" path="/portfolios" component={PortfolioList} isProtected />,
            <ProtectedRoute exact key="portfolio-edit" path="/portfolio/:id(\d+)?" component={Portfolio} isProtected />,
            <ProtectedRoute
              exact
              key="supplier-profile"
              path="/supplier/profile"
              component={OnBoardingVariant}
              isProtected
            />,
            <ProtectedRoute
              exact
              key="supplier-profile"
              path="/supplier/skills"
              component={SkillsAndProjects}
              isProtected
            />,
          ]}
          {getUserType() === 'client' && [
            <ProtectedRoute
              exact
              key="client-profile"
              path="/client/profile"
              component={ClientProfileOnBoardingVariant}
              isProtected
            />,
            <ProtectedRoute exact key="search-page" path="/search" component={RecommendedSuppliersPage} isProtected />,
            <ProtectedRoute
              exact
              key="search-results"
              path="/search/:id(\d+)"
              component={SearchResultsPage}
              isProtected
            />,
            <ProtectedRoute
              exact
              key="supplier-propfile"
              path="/supplier/:id(\d+)?"
              component={SupplierProfileClientView}
              isProtected
            />,
            <ProtectedRoute exact key="shortlist" path="/shortlist" component={ShortlistPage} isProtected />,
            <ProtectedRoute exact key="projectbrief" path="/project/brief" component={ProjectBrief} isProtected />,
          ]}
          <ProtectedRoute exact path="/login" component={Login} />
          <ProtectedRoute exact path="/register" component={Registration} />
          <ProtectedRoute exact path="/register/success" component={RegistrationSuccess} />
          <ProtectedRoute exact path="/password-reset/request" component={ResetRequest} />
          <ProtectedRoute exact path="/password-reset/success" component={ResetSuccess} />
          <ProtectedRoute exact path="/password-reset/confirm" component={ResetConfirm} />
          <ProtectedRoute component={NotFoundPage} isProtected />
        </Switch>
      </div>
      <CookieToolbar />
    </div>
  );
}

function ProtectedRoute({ component: Component, isProtected, ...rest }) {
  const { session } = useContext(AuthenticationContext);

  return (
    <Route
      render={props => {
        if (isProtected) {
          return session.token ? <Component {...props} /> : <Redirect to="/login" />;
        } else {
          return session.token ? <Redirect to="/dashboard" /> : <Component {...props} />;
        }
      }}
      {...rest}
    />
  );
}

ProtectedRoute.defaultProps = {
  isProtected: false,
};
export default withStyles(styles)(withSnackbar(withRouter(injectIntl(App))));
