import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import get from 'lodash/get';
import HeaderPage from '../Pages/HeaderPage';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ChipAutoSuggest from '../FormFields/ChipAutoSuggest';
import { updateSupplierProfile } from '../Api/SupplierProfile';
import { updateLocalProfile } from '../Api/LocalStorage';
import { fetchSupplierProfile, fetchRates, fetchCategories, updateProjects } from '../Api/FetchAndCache';
import DeletionModal, { useModal } from '../Modals/DeletionModal';
import ProgressButton, { useProgressButton } from '../FormFields/ProgressButton';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 5,
  },
  skillsPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
  },
  listRootSkillsPaperHeader: {
    marginTop: theme.spacing.unit * 5,
  },
});

const ratesStyles = (theme) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    paddingTop: '2px',
    paddingBottom: '2px',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    width: '100%',
  },
  item: {
    paddingTop: '13px',
    paddingBottom: '13px',
  },
  itemLink: {
    paddingRight: theme.spacing.unit * 2,
  },
});

function Skills(props) {
  const { classes } = props;
  const [availableSkills, setAvailableSkills] = useState([]);
  const [storedSkills, setStoredSkills] = useState([]);
  const [savedSkills, setSavedSkills] = useState([]);
  const [profileCurrency, setProfileCurrency] = useState('');
  const [modalOpen, setModalOpen] = useModal(false);
  const [deletionItem, setDeletionItem] = useState({});
  const [processingDeletion, setProcessingDeletion] = useState(false);
  const [buttonBusy, setButtonBusy] = useProgressButton(false);

  const saveSkills = () => {
    console.log(storedSkills);
    setButtonBusy(true);
    updateSupplierProfile({
      skills: storedSkills.map((skill) => {
        return {
          id: skill.key,
          rate: skill.rate ? skill.rate : null,
        };
      }),
    }).then((result) => {
      setSavedSkills(storedSkills);
      updateLocalProfile(get(result, 'data.data'));
      setButtonBusy(false);
    });
  };

  const fetchData = () => {
    fetchSupplierProfile().then((supplier) => {
      setProfileCurrency(get(supplier, 'currency.symbol', ''));
      const skills = supplier.skills.map((s) => ({
        key: s.id,
        value: s.id,
        label: s.name,
        rate: s.pivot.cost_ranges_id,
      }));
      setStoredSkills(skills);
      setSavedSkills(skills);
    });
    fetchCategories().then((result) => {
      setAvailableSkills(
        result
          .filter((category) => {
            return category.parent_id > 0 && !category.isTool && !category.isPlatform;
          })
          .map((category) => {
            return { value: category.id, key: category.id, label: category.name };
          }),
      );
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <DeletionModal
        onDelete={(e) => {
          setProcessingDeletion(true);
          setStoredSkills(storedSkills.filter((s) => s.key !== deletionItem.key));
          updateSupplierProfile({
            skills: storedSkills
              .filter((s) => s.key !== deletionItem.key)
              .map((s) => ({ id: s.key, rate: s.rate ? s.rate : null })),
          }).then((result) => {
            setSavedSkills(storedSkills.filter((s) => s.key !== deletionItem.key));
            updateLocalProfile(get(result, 'data.data'));
            updateProjects().then(() => {
              setDeletionItem({});
              setProcessingDeletion(false);
              setModalOpen(false);
            });
          });
        }}
        onCancel={(e) => {
          if (!processingDeletion) {
            setModalOpen(false);
          }
        }}
        modalOpen={modalOpen}
        itemDeletionLabel={get(deletionItem, 'label', '')}
      />
      <HeaderPage headerText="skills.header" subtitleText="skills.subtitle">
        <div className={classes.root}>
          <Typography variant="h5" color="inherit">
            <FormattedMessage id="skills.my" />
          </Typography>
          <Paper className={classes.skillsPaper} elevation={1}>
            <ChipAutoSuggest
              fullWidth
              fullWidthInput
              InputProps={{ name: 'skills', color: 'primary' }}
              name="skills"
              dataSource={availableSkills}
              storedValues={storedSkills}
              maxChips={availableSkills.length}
              selectTextFieldProps={{ variant: 'outlined' }}
              onChange={(name, value) => {
                if (value.length < storedSkills.length) {
                  setDeletionItem(storedSkills.find((s) => value.filter((v) => v.key === s.key).length === 0));
                  setModalOpen(true);
                  return;
                }
                setStoredSkills(value);
              }}
            />
            <ProgressButton
              onClick={saveSkills}
              buttonBusy={buttonBusy}
              submitting={buttonBusy}
              disabled={storedSkills.length <= savedSkills.length}
            >
              <FormattedMessage id="save" />
            </ProgressButton>
          </Paper>

          <Typography variant="h5" color="inherit" className={classes.listRootSkillsPaperHeader}>
            <FormattedMessage id="skills.rates" />
          </Typography>
          <StyledRatesComponent storedSkills={savedSkills} profileCurrency={profileCurrency} />
        </div>
      </HeaderPage>
    </React.Fragment>
  );
}

function RatesComponent(props) {
  const { classes, storedSkills } = props;
  const [rates, setRates] = useState([]);

  useEffect(() => {
    fetchRates().then((result) => setRates(result));
  }, []);

  return (
    <Paper className={classes.root} elevation={1}>
      <List className={classes.list}>
        {storedSkills.length === 0 && (
          <ListItem className={classes.item}>
            <ListItemText primary={<FormattedMessage id={'skills.select'} />} />
          </ListItem>
        )}
        {storedSkills.map((skill, idx) => {
          return (
            <React.Fragment key={skill.key}>
              <ListItem className={classes.item}>
                <ListItemText
                  primary={skill.label}
                  primaryTypographyProps={{ component: 'h5', variant: 'h5' }}
                  secondary={
                    skill.rate
                      ? rates.filter((r) => r.id === skill.rate).length
                        ? `${props.profileCurrency} ${rates.find((r) => r.id === skill.rate).interval} per day`
                        : 'No rate set'
                      : 'No rate set'
                  }
                />
                <ListItemSecondaryAction>
                  <Link className={classes.itemLink} to={'/skills/rates/' + skill.key}>
                    {skill.rate ? (
                      <IconButton aria-label="Edit">
                        <EditIcon />
                      </IconButton>
                    ) : (
                      <FormattedMessage id={'rates.add'} />
                    )}
                  </Link>
                </ListItemSecondaryAction>
              </ListItem>
              {idx < storedSkills.length - 1 && <Divider component="li" />}
            </React.Fragment>
          );
        })}
      </List>
    </Paper>
  );
}

const StyledRatesComponent = withStyles(ratesStyles)(RatesComponent);
export default withStyles(styles)(Skills);
