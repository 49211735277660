import React, { useContext } from 'react';

import Header from './Header';
import AuthenticatedHeader from './AuthenticatedHeader';
import ClientAuthenticatedHeader from './ClientAuthenticatedHeader';
import { AuthenticationContext } from '../Authentication/AuthenticationContext';
import { getUserType } from '../Api/LocalStorage';
import { NotificationContext } from '../Notifications/NotificationContext';

const AuthHeader = props => {
  const { useNotifications } = useContext(NotificationContext);

  useNotifications();

  if (getUserType() === 'supplier') {
    return <AuthenticatedHeader />;
  } else {
    return <ClientAuthenticatedHeader />;
  }
};

function HeaderFactory(props) {
  const { session } = useContext(AuthenticationContext);

  if (session.token) {
    return <AuthHeader />;
  }
  return <Header />;
}
export default HeaderFactory;
