import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  tabsRoot: {
    height: '100%',
  },
  tabsFlexContainer: {
    height: '100%',
  },
  tabRoot: {
    marginRight: theme.spacing.unit,
    width: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      minWidth: theme.spacing.unit * 12,
      flexGrow: 1,
    },
  },
  tabAllRoot: {
    [theme.breakpoints.down('sm')]: {
      minWidth: theme.spacing.unit * 10,
    },
  },
  tabLabel: {},
  tabLabelContainer: { padding: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
});

function NotificationsTabs(props) {
  const { items, classes, onChange, value } = props;
  return (
    <Tabs
      classes={{ root: classes.tabsRoot, flexContainer: classes.tabsFlexContainer }}
      variant="scrollable"
      value={value}
      onChange={onChange}
    >
      <Tab value="all" label={<FormattedMessage id="all" />} classes={{ root: classes.tabAllRoot }} />
      {items.map(p => (
        <Tab
          key={p.id}
          value={p.id}
          label={p.title}
          classes={{ root: classes.tabRoot, label: classes.tabLabel, labelContainer: classes.tabLabelContainer }}
        />
      ))}
    </Tabs>
  );
}

export default withStyles(styles)(NotificationsTabs);
