import React from 'react';
import BookmarkFilledIcon from '@material-ui/icons/Bookmark';
import { useEffect, useContext } from 'react';
import AuthenticatedHeader from './AuthenticatedHeader';
import { ShortlistContext } from '../Shortlist/ShortlistContext';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  link: {
    color: 'black',
    display: 'flex',
    flexAlign: 'row',
    alignItems: 'center',
    marginLeft: theme.spacing.unit * 2,
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
});

export function ClientAuthenticatedHeader(props) {
  const { classes } = props;
  const { retrieveShortlist, shortlistedSuppliers } = useContext(ShortlistContext);

  useEffect(() => {
    retrieveShortlist();
  }, []);

  return (
    <AuthenticatedHeader>
      <Link to="/shortlist" className={classes.link}>
        <BookmarkFilledIcon className={classes.icon} />
        {shortlistedSuppliers.length}
      </Link>
    </AuthenticatedHeader>
  );
}

export default withStyles(styles)(ClientAuthenticatedHeader);
