import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import ResetRequestForm from './ResetRequestForm';
import { ReactComponent as ResetRequestImage } from '../assets/ResetRequestImage.svg';
import { passwordResetRequest } from '../Api/Authentication';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
  },
  paper: {
    marginTop: theme.spacing.unit * 6,
    width: theme.spacing.unit * 85,
    border: `1px solid ${theme.palette.grey[400]}`,
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    flexGrow: 1,
    display: 'flex',
  },
  card: {
    flexDirection: 'column',
    maxWidth: '100%',
    textAlign: 'center',
    alignItems: 'center',
    flexGrow: 1,
    display: 'flex',
    paddingTop: theme.spacing.unit * 6,
    // paddingBottom: theme.spacing.unit * 6,
  },
  image: {
    marginBottom: '0.75em',
  },
  cardContent: {
    maxWidth: theme.spacing.unit * 70,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    flexGrow: 1,
    '& > *': {
      marginBottom: '0.75em',
    },
  },
});

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('forgotten-password.request.email.invalid')
    .required('forgotten-password.request.email.required')
    .min(1),
});

const values = { email: '', password: '' };

function ResetRequest(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Card className={classes.card}>
          <ResetRequestImage className={classes.image} />
          <CardContent className={classes.cardContent}>
            <Typography variant="h4">
              <FormattedMessage id="forgotten-password.request.prose.header" />
            </Typography>
            <Typography variant="body2">
              <FormattedHTMLMessage id="notfound.prose" values={{ mail: 'hello@jam-pan.com' }} />
              <a className={classNames('brand', 'no-underline')} href="mailto:hello@jam-pan.com">
                hello@jam-pan.com
              </a>
            </Typography>
            <Formik
              render={props => <ResetRequestForm {...props} />}
              initialValues={values}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                const data = {
                  email: values.email,
                };
                passwordResetRequest(data)
                  .then(() => {
                    props.history.push({
                      pathname: '/password-reset/success',
                      state: { email: values.email },
                    });
                  })
                  .finally(() => {
                    actions.setSubmitting(false);
                  });
              }}
            />
          </CardContent>
        </Card>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(ResetRequest);
