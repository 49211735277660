import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles, Typography, IconButton } from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import get from 'lodash/get';
import HeaderPage from '../Pages/HeaderPage';
import { getPreviousSuppliers, getRecommendedSuppliers } from '../Api/ClientSearch';
import { getLocalProfile } from '../Api/LocalStorage';
import SearchBox from './SearchBox';
import SearchResultList from './SearchResultList';
import Progress from '../FormFields/Progress';
import { ShortlistContext } from '../Shortlist/ShortlistContext';

const styles = (theme) => ({
  expandIcon: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

function HeaderRecommendedSuppliersPage(props) {
  return (
    <HeaderPage headerText="client.find.suppliers" subtitleText="client.find.suppliers.prose">
      <UnheadedRecommendedSuppliersPage {...props} />
    </HeaderPage>
  );
}

function RecommendedSuppliersPage(props) {
  const { classes, forceScreen } = props;
  const [previousSuppliers, setPreviousSuppliers] = useState([]);
  const [isPreviousSuppliersExpanded, setIsPreviousSuppliersExpanded] = useState(false);
  const [recommendedSuppliers, setRecommendedSuppliers] = useState([]);
  const [loadingResults, setLoadingResults] = useState(false);
  const { shortlistedSuppliers } = useContext(ShortlistContext);
  const [tempRecommendedResults, setTempRecommendedResults] = useState([]);

  useEffect(() => {
    setLoadingResults(true);
    getPreviousSuppliers().then((result) => {
      setPreviousSuppliers(get(result, 'data.data', []));
      setLoadingResults(false);
    });
    getRecommendedSuppliers().then((result) => {
      setTempRecommendedResults(get(result, 'data.data.data', []));
      setLoadingResults(false);
    });
  }, []);

  useEffect(() => {
    const mappedTempRecommendedResults = tempRecommendedResults.map((r) => {
      r.shortlisted = shortlistedSuppliers.some((ss) => ss.id === r.id);
      return r;
    });
    setRecommendedSuppliers(mappedTempRecommendedResults);
  }, [shortlistedSuppliers, tempRecommendedResults]);

  return (
    <>
      <Typography variant="h5" color="inherit">
        <FormattedMessage id="client.find.previous-suppliers" />
        <IconButton
          className={classes.expandIcon}
          onClick={() => setIsPreviousSuppliersExpanded(!isPreviousSuppliersExpanded)}
          disabled={previousSuppliers.length === 0}
        >
          {!isPreviousSuppliersExpanded && <AddCircle fontSize="small" />}
          {isPreviousSuppliersExpanded && <RemoveCircle fontSize="small" />}
        </IconButton>
      </Typography>
      {isPreviousSuppliersExpanded && (
        <SearchResultList searchResults={previousSuppliers} loading={loadingResults} forceScreen={forceScreen}>
          {loadingResults && <Progress />}
        </SearchResultList>
      )}
      {!getLocalProfile().hide_suppliers_search && (
        <>
          <Typography variant="h5" color="inherit">
            <FormattedMessage id="client.find.suppliers" />
          </Typography>
          <Typography variant="body1" color="textSecondary">
            <FormattedMessage id="client.find.suppliers.disclaimer" />
          </Typography>
          <SearchBox />
        </>
      )}
      <Typography variant="h5" color="inherit">
        <FormattedMessage id="client.find.recommended" />
      </Typography>
      <SearchResultList searchResults={recommendedSuppliers} loading={loadingResults} forceScreen={forceScreen}>
        {loadingResults && <Progress />}
      </SearchResultList>
    </>
  );
}

RecommendedSuppliersPage.defaultProps = {
  forceScreen: false,
};

export const UnheadedRecommendedSuppliersPage = withStyles(styles)(RecommendedSuppliersPage);
export default HeaderRecommendedSuppliersPage;
