import React from 'react';
import { useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing.unit,
  },
  chip: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    padding: theme.spacing.unit,
  },
});

function ChipCheckboxGroup(props) {
  const { classes, name, options, disabled, storedValues, onClick } = props;

  useEffect(() => {
    onClick(
      name,
      storedValues.filter((sv) => options.filter((o) => o.key === sv.key).length === 1),
      true,
    );
  }, [options]);

  function handleClick(option) {
    const nuValues = storedValues.filter((value) => {
      return option.key !== value.key;
    });
    // we've unclicked one
    if (nuValues.length !== storedValues.length) {
      onClick(name, nuValues, true);
      return;
    }

    onClick(name, [...storedValues, option], true);
  }
  return (
    <div className={classes.root}>
      {options.map((option) => {
        const selected = storedValues.find((sv) => {
          return sv.key === option.key;
        });
        return (
          <Chip
            name={name}
            label={option.label}
            clickable={!disabled}
            className={classes.chip}
            color={selected ? 'primary' : 'default'}
            onClick={() => {
              handleClick(option);
            }}
            variant={selected || disabled ? 'default' : 'outlined'}
            key={option.key}
          />
        );
      })}
    </div>
  );
}
ChipCheckboxGroup.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
ChipCheckboxGroup.defaultProps = {
  onClick: () => {},
  storedValues: [],
  options: [],
  disabled: false,
};
export default withStyles(styles)(injectIntl(ChipCheckboxGroup));
