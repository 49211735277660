import axios from 'axios';

export function authenticate(confirmationQuery, data) {
  return axios.post(`/api/login?${confirmationQuery}`, data);
}

export function passwordResetRequest(data) {
  return axios.post('/api/profiles/password/reset', data);
}

export function passwordResetConfirm(confirmationQuery, data) {
  return axios.post(`/api/profiles/password/changeReset?${confirmationQuery}`, data);
}

export function passwordUpdate(data) {
  return axios.put('/api/profiles/password/change-password', data);
}

export function supplierRegistration(data) {
  return axios.post('/api/profiles/supplier', data);
}

export function clientRegistration(data) {
  return axios.post('/api/profiles/client', data);
}
