import React from 'react';
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function SearchResultsProvider(props) {
  const { children, history } = props;
  const [selectedFilters, setSelectedFilters] = useState({});
  const [availabilityFilters, setAvailabilityFilters] = useState(null);
  const [searchItem, setSearchItem] = useState({});

  useEffect(() => {
    setSearchItem({});
  }, [history.location]);

  const resetSelectedFilters = function () {
    setSelectedFilters({});
  };

  const resetAvailabilityFilters = function () {
    setAvailabilityFilters(null);
  };

  const onChange = function (key, selected, filterValues = [], allowOneFilterPerMenuItem = false) {
    setSelectedFilters((prevVals) => {
      const a = prevVals[key] || [];

      let values = [];
      // The availability menu item only allows one filter selected simultaneously
      // while the other options allow several filters at the same time.
      if (allowOneFilterPerMenuItem) {
        if (filterValues) {
          values = [selected, filterValues];
        } else {
          values = [selected];
        }
      } else {
        values = a.includes(selected) ? a.filter((l) => l !== selected) : [...a, selected];
      }

      return {
        ...prevVals,
        [key]: values,
      };
    });
  };

  return (
    <SearchResultsContext.Provider
      value={{
        onChange: onChange,
        selectedFilters: selectedFilters,
        resetSelectedFilters: resetSelectedFilters,
        availabilityFilters,
        setAvailabilityFilters,
        resetAvailabilityFilters,
        searchItem: searchItem,
        setSearchItem: setSearchItem,
      }}
    >
      {children}
    </SearchResultsContext.Provider>
  );
}

export const SearchResultsContext = React.createContext();
export const SearchResultsConsumer = SearchResultsContext.Consumer;
export default withRouter(SearchResultsProvider);
