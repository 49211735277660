import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { withSnackbar } from 'notistack';
import { endProject } from '../../Api/Jobs';
import FinishProjectForm from './FinishProjectForm';
import FinishProjectSuppliersComponent from './FinishProjectSuppliersComponent';
import { statuses } from '../ProjectStatuses';

function FinishProjectComponent(props) {
  const { project, userType, enqueueSnackbar } = props;
  const validationSchema = Yup.object().shape({
    endProjectConfirmed: Yup.bool().required('projects.management-page.finish-project.supplier.label'),
  });
  const [values] = useState({
    endProjectConfirmed: false,
  });
  if (userType === 'client')
    return (
      <FinishProjectSuppliersComponent
        project={project}
        onSubmit={(status, supplierId) => {
          const data = { end_project_request: status, supplier_id: supplierId };
          endProject(project.id, data).then(() => {
            window.location.reload();
          });
        }}
      />
    );
  return (
    <Formik
      render={(props) => <FinishProjectForm project={project} {...props} />}
      initialValues={values}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        const data = { end_project_request: statuses.end_project_request };
        endProject(project.id, data)
          .then(() => {
            enqueueSnackbar(<FormattedMessage id="projects.management-page.finish-project.supplier.success" />);
          })
          .finally(() => {
            actions.setSubmitting(false);
          });
      }}
    />
  );
}

export default withSnackbar(FinishProjectComponent);
