import React from 'react';
import { useState, useEffect } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import ClientProfileForm from './ClientProfileForm';
import { withStyles } from '@material-ui/core/styles';
import { updateClientProfile } from '../Api/ClientProfile';
import { updateLocalProfile } from '../Api/LocalStorage';
import { withSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';
import {
  fetchClientProfile,
  fetchCountries,
  fetchCategories,
  fetchIndustries,
  fetchRoles,
  fetchCompanySizes,
} from '../Api/FetchAndCache';

const styles = (theme) => ({
  root: {
    maxWidth: '1024px',
    '& > *': {
      marginBottom: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing.unit * 4,
      },
    },
  },
});

export function transformValuesToProfile(values) {
  const data = {
    companyName: values.companyName,
    email: values.email,
    industry: values.industry,
    role: values.role,
    companySize: values.companySize,
    platformsChoice: values.platformsChoice,
    platforms: values.platformsChoice !== 1 ? [] : [values.lms],
    learningSize: values.platformsChoice !== 1 ? 0 : values.lmsSize,
    toolsChoice: values.toolsChoice,
    tools: values.toolsChoice !== 1 ? [] : [Number(values.tool)],
    address1: values.address1,
    address2: values.address2,
    city: values.city,
    postalCode: values.postalCode,
    phone: values.phone,
    country: values.country,
    procurementName: values.procurementName,
    procurementEmail: values.procurementEmail,
    procurementJobTitle: values.procurementJobTitle,
  };
  return data;
}

export function transformProfileToValues(profile) {
  const data = {
    companyName: profile.name || '',
    email: profile.email || '',
    industry: get(profile, 'industry.id', ''),
    role: get(profile, 'role.id', ''),
    companySize: get(profile, 'companySize.id', ''),
    platformsChoice: profile.platformsChoice,
    lms: get(profile, 'platforms[0].id', ''),
    lmsSize: get(profile, 'learningSize.id', ''),
    toolsChoice: profile.toolsChoice,
    tool: get(profile, 'tools[0].id', ''),
    address1: profile.address1 || '',
    address2: profile.address2 || '',
    city: profile.city || '',
    postalCode: profile.postalCode || '',
    phone: profile.phone || '',
    country: profile.country || '',
    procurementName: profile.procurementName || '',
    procurementEmail: profile.procurementEmail || '',
    procurementJobTitle: profile.procurementJobTitle || '',
  };
  return data;
}

function ClientProfile(props) {
  const { classes, enqueueSnackbar, forwardTo } = props;
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [roles, setRoles] = useState([]);
  const [companySizes, setCompanySizes] = useState([]);
  const [values, setValues] = useState({
    companyName: '',
    email: '',
    industry: '',
    role: '',
    companySize: '',
    platformsChoice: '',
    lms: '',
    lmsSize: '',
    toolsChoice: '',
    tool: '',
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    phone: '',
    country: '',
    procurementName: '',
    procurementEmail: '',
    procurementJobTitle: '',
  });

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().trim().required('client-profile.name.required').min(1),
    email: Yup.string().email().required('client-profile.email.required'),
    industry: Yup.string().trim().required('client-profile.industry.required').min(1),
    role: Yup.string().trim().required('client-profile.role.required').min(1),
    companySize: Yup.string().trim().required('client-profile.company-size.required').min(1),
    platformsChoice: Yup.number().oneOf([1, 2, 3]),
    lms: Yup.string()
      .trim()
      .test('lms', 'client-profile.lms.required', function (value) {
        return this.parent.platformsChoice !== 1 || value;
      }),
    lmsSize: Yup.string()
      .trim()
      .test('lmsSize', 'client-profile.lms-size.required', function (value) {
        return this.parent.platformsChoice !== 1 || value;
      }),
    toolsChoice: Yup.number().oneOf([1, 2, 3]),
    tool: Yup.string()
      .trim()
      .test('tool', 'client-profile.tool.required', function (value) {
        return this.parent.toolsChoice !== 1 || value;
      }),
    address1: Yup.string().trim().required('supplier-profile.address1.required').min(1),
    city: Yup.string().trim().required('supplier-profile.city.required').min(1),
    postalCode: Yup.string().trim().required('supplier-profile.postcode.required').min(1),
    phone: Yup.string().trim().required('supplier-profile.telephone.required').min(1),
    country: Yup.string().trim().required('supplier-profile.country.required').min(1),
    procurementName: Yup.string().trim().required('client-profile.procurement-name.required').min(1),
    procurementEmail: Yup.string().email().required('client-profile.procurement-email.required'),
    procurementJobTitle: Yup.string().trim().required('client-profile.procurement-job-title.required'),
  });
  const fetchData = () => {
    fetchClientProfile().then((profile) => {
      setValues(transformProfileToValues(profile));
    });

    fetchCountries().then((result) => {
      setCountries(result);
    });
    fetchCategories().then((result) => {
      setCategories(result);
    });
    fetchIndustries().then((result) => {
      setIndustries(result);
    });
    fetchRoles().then((result) => {
      setRoles(result);
    });
    fetchCompanySizes().then((result) => {
      setCompanySizes(result);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  function saveForLater(values) {
    updateClientProfile(transformValuesToProfile(values)).then((result) => {
      updateLocalProfile(get(result, 'data.data'));
      enqueueSnackbar(<FormattedMessage id="supplier-profile.saved" />);
    });
  }

  return (
    <div className={classes.root}>
      <Formik
        render={(props) => (
          <ClientProfileForm
            {...props}
            saveForLater={saveForLater}
            countries={countries}
            categories={categories}
            industries={industries}
            roles={roles}
            companySizes={companySizes}
            forwardTo={forwardTo}
          />
        )}
        initialValues={values}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          updateClientProfile(transformValuesToProfile(values))
            .then((response) => {
              updateLocalProfile(get(response, 'data.data'));
              setValues(transformProfileToValues(get(response, 'data.data')));
              enqueueSnackbar(<FormattedMessage id="supplier-profile.saved" />);
              if (typeof forwardTo === 'string') {
                props.history.push({
                  pathname: forwardTo,
                  state: { email: get(response, 'data.data.email', '') },
                });
              }
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      />
    </div>
  );
}

ClientProfile.defaultProps = {
  forwardTo: false,
};
export default withStyles(styles)(withSnackbar(withRouter(ClientProfile)));
