import React from 'react';
import Divider from '../FormFields/Divider';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import ChipRadioGroup from '../FormFields/ChipRadioGroup';
import { injectIntl, FormattedMessage } from 'react-intl';
import Prompt from '../Prompt/Prompt';
import TextInput from '../FormFields/TextInput';
import LimitedLengthTextInput from '../FormFields/LimitedLengthTextInput';
import ProgressButton from '../FormFields/ProgressButton';
import { withStyles } from '@material-ui/core/styles';
import ProjectBriefTasks from './Components/ProjectBriefTasks';
import FileUploader from '../FormFields/FileUploader';
import { fileUploads } from '../Api/Jobs';
import AvatarStack from './Components/AvatarStack';

const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 2,
    '& > *': {
      marginBottom: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing.unit * 4,
      },
    },
    smallerMarginBottom: {
      marginBottom: theme.spacing.unit * 2,
    },
  },
  budgetInput: {
    paddingRight: 0,
  },
  durationRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      display: 'flex',
      flexBasis: '49%',
    },
  },
});

function ProjectBriefForm(props) {
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    // handleReset,
    setFieldValue,
    classes,
    intl,
    suppliers,
  } = props;

  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <Prompt when={dirty} message={() => intl.formatMessage({ id: 'prompt.unsaved' })} />
      <Typography variant="h4" color="inherit">
        <FormattedMessage id="project-brief.create-project.header" />
      </Typography>
      <Typography variant="body2" color="inherit">
        <FormattedMessage id="project-brief.create-project.prose" />
      </Typography>
      <AvatarStack items={suppliers} />
      <Typography variant="body2" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedMessage id="required-fields.prose" />
      </Typography>
      <Divider />
      <Typography variant="h6" color="inherit">
        <FormattedMessage id="project-brief.background" />
      </Typography>
      <TextInput
        fullWidth
        required
        margin="normal"
        name="title"
        value={values.title}
        placeholder="project-brief.title.placeholder"
        label="project-brief.title.label"
        helperText={touched.title && errors.title ? errors.title : 'project-brief.title.helper'}
        error={Boolean(touched.title && errors.title)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <LimitedLengthTextInput
        name="description"
        required
        value={values.description}
        placeholder="project-brief.description.placeholder"
        label="project-brief.description.label"
        helperText={touched.description && errors.description ? errors.description : 'project-brief.description.helper'}
        error={Boolean(touched.description && errors.description)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
        multiline
        rows="4"
        maxLength={500}
      />
      <Typography variant="h6" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedMessage id="project-brief.location" />*
      </Typography>
      <ChipRadioGroup
        options={[
          { label: 'project-brief.location.remote', key: '1' },
          { label: 'project-brief.location.onsite', key: '2' },
          { label: 'project-brief.location.flexible', key: '3' },
        ]}
        name="location"
        value={values.location}
        onClick={setFieldValue}
      />
      <Typography variant="h6" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedMessage id="project-brief.tasks" />*
      </Typography>
      <ProjectBriefTasks name="tasks" tasks={values.tasks} />
      <Typography variant="h6" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedMessage id="project-brief.billing-schedule" />
      </Typography>
      <div className={classes.durationRow}>
        <TextInput
          name="deadline"
          required
          type="date"
          value={values.deadline}
          placeholder="project-brief.deadline.placeholder"
          label="project-brief.deadline.label"
          helperText={touched.deadline && errors.deadline ? errors.deadline : null}
          error={Boolean(touched.deadline && errors.deadline)}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <FormControl required variant="outlined" fullWidth>
          <InputLabel htmlFor="industry-select">
            <FormattedMessage id="project-brief.duration.label" />
          </InputLabel>
          <Select
            native
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
            value={values.duration}
            input={<OutlinedInput labelWidth={70} name="duration" id="duration-select" />}
          >
            <option value="" />
            {[{ id: 1, name: '1 Day' }, { id: 2, name: '1 Week' }, { id: 3, name: '1 Month' }].map(duration => (
              <option value={duration.id} key={duration.id}>
                {duration.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>

      <TextInput
        fullWidth
        required
        type="number"
        margin="normal"
        name="budget"
        value={values.budget}
        placeholder="project-brief.budget.placeholder"
        label="project-brief.budget.label"
        helperText={touched.budget && errors.budget ? errors.budget : null}
        error={Boolean(touched.budget && errors.budget)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          className: classes.budgetInput,
          endAdornment: (
            <InputAdornment position="end">
              <FormControl variant="outlined" style={{ minWidth: 140 }}>
                <Select
                  native
                  name="budgetType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.budgetType}
                  input={<OutlinedInput labelWidth={0} name="budget-type" id="budget-type-select" />}
                >
                  {[
                    { id: 1, name: intl.formatMessage({ id: 'project-brief.budget-type.fixed-cost' }) },
                    { id: 2, name: intl.formatMessage({ id: 'project-brief.budget-type.day-rate' }) },
                  ].map(budgetType => (
                    <option value={budgetType.id} key={budgetType.id}>
                      {budgetType.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </InputAdornment>
          ),
        }}
      />
      <Typography variant="h6" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedMessage id="project-brief.supporting-docs" />
      </Typography>
      <FileUploader
        name="files"
        showFiletypeIcon
        iconFiletypes={['jpg', 'png', 'pdf', 'docx']}
        uploadPromise={fileUploads}
        files={values.files}
        onClick={setFieldValue}
      />
      <Divider />
      <ProgressButton
        type="submit"
        buttonBusy={isSubmitting}
        disabled={!isValid}
        submitting={isSubmitting}
        data-testid="submit-button"
      >
        {intl.formatMessage({ id: 'project-brief.send-brief' })}
      </ProgressButton>
    </form>
  );
}
export default withStyles(styles)(injectIntl(ProjectBriefForm));
