import React from 'react';
import { injectIntl } from 'react-intl';
import TextField from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    width: '100%',
  },
  textField: {
    marginBottom: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit,
    fontSize: theme.spacing.unit * 1.5,
  },
});

function MonthsFilter(props) {
  const { classes, months, onChangeMonths } = props;
  return (
    <form className={classes.container}>
      <TextField
        value={months}
        onChange={(e) => e.target.value >= 0 && onChangeMonths(e.target.value)}
        className={classes.textField}
        type="number"
        label={`${props.intl.formatMessage({ id: 'filter.menu.availability.months.label' })}`}
        placeholder={`${props.intl.formatMessage({ id: 'filter.menu.availability.months.label' })}`}
        inputProps={{ min: 0 }}
      />
    </form>
  );
}

export default withStyles(styles)(injectIntl(MonthsFilter));
