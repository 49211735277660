import axios from 'axios';

export function updateSupplierProfile(values) {
  return axios.put('/api/profiles/supplier', values);
}

export function getSupplierProfile() {
  return axios.get('/api/profiles/supplier');
}

export function getSupplierProfileById(id) {
  return axios.get(`/api/suppliers/${id}`);
}

export function fileUploads(values) {
  // Disable the global error interceptor by making a new axios instance
  const instance = axios.create();
  return instance.post('/api/profiles/supplier/project/files', values);
}

export function uploadProfilePic(values) {
  // Disable the global error interceptor by making a new axios instance
  const instance = axios.create();
  return instance.post('/api/profiles/profile-pic', values);
}

export function deleteProfilePic() {
  return axios.delete('/api/profiles/profile-pic');
}

export function saveRate(id, values) {
  return axios.put('/api/profiles/supplier/skill/' + id, values);
}

export function updateCompany(values) {
  return axios.put('/api/profiles/supplier/company', values);
}
