import React from 'react';
import MuiDivider from '@material-ui/core/Divider';

function Divider(props) {
  const { paddingOversize, style, ...rest } = props;
  return (
    <MuiDivider
      {...rest}
      style={{ marginRight: `-${paddingOversize}px`, marginLeft: `-${paddingOversize}px`, ...style }}
    />
  );
}

Divider.defaultProps = {
  paddingOversize: 24,
};

export default Divider;
