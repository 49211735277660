import React from 'react';

import classNames from 'classnames';
import tickImage from '../../assets/Tick.svg';
import companyInfoImage from '../../assets/Company-Info.svg';
import ratesImage from '../../assets/Icon-Rates.svg';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  root: {},
  tasks: {
    marginTop: theme.spacing.unit * 5,

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-around',
  },
});
const taskStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing.unit * 2,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  circle: {
    display: 'flex',
    width: '80px',
    height: '80px',
    borderRadius: '100px',

    [theme.breakpoints.up('sm')]: {
      marginBottom: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing.unit * 2,
    },
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '30%',
  },
  incompleteCircle: {
    border: '2px dashed ' + theme.palette.primary.main,
  },
  completedCircle: {
    border: '2px solid ' + theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${tickImage})`,
  },
  companyInfo: {
    backgroundImage: `url(${companyInfoImage})`,
  },
  rates: {
    backgroundImage: `url(${ratesImage})`,
  },
});

function TaskList(props) {
  const { classes, supplier } = props;
  return (
    <div className={classes.root}>
      <div className={classes.tasks}>
        <Task icon="completedCircle" title="dashboard.skills.profile" to="/profile/details" completed />
        <Task
          icon="rates"
          title="dashboard.skills.rates"
          to="/skills"
          completed={supplier.profileRatesPerSkillComplete}
        />
        <Task
          icon="companyInfo"
          title="dashboard.skills.company-information"
          to="/profile/company"
          completed={supplier.profileCompanyDetailsComplete}
        />
      </div>
    </div>
  );
}

function TaskComponent(props) {
  const { classes, title, completed, to, icon } = props;
  const taskClasses = classNames(classes.circle, {
    [classes[icon]]: !completed,
    [classes.incompleteCircle]: !completed,
    [classes.completedCircle]: completed,
  });
  return (
    <div className={classes.root}>
      <Link to={to}>
        <div className={taskClasses} />
      </Link>
      <Typography variant="subtitle2" color="textPrimary">
        <FormattedMessage id={title} />
      </Typography>
    </div>
  );
}
TaskComponent.defaultProps = {
  title: '',
  icon: '',
  completed: false,
};

export const Task = withStyles(taskStyles)(TaskComponent);
export default withStyles(styles)(TaskList);
