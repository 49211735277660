import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';

import TextInput from '../FormFields/TextInput';
import { withStyles } from '@material-ui/core/styles';
import ProgressButton from '../FormFields/ProgressButton';

const styles = theme => ({
  root: {
    width: '90%',
    '& > *': {
      marginBottom: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing.unit * 4,
      },
    },
  },
});

function LoginForm(props) {
  const {
    values,
    touched,
    errors,
    // dirty,
    isSubmitting,
    isValid,
    handleChange,
    handleBlur,
    // handleSubmit,
    // handleReset,
    classes,
  } = props;

  return (
    <form onSubmit={props.handleSubmit} className={classes.root}>
      <TextInput
        required
        fullWidth
        margin="normal"
        name="email"
        value={values.email}
        placeholder="login.email.placeholder"
        label="login.email.label"
        helperText={touched.email && errors.email ? errors.email : 'login.email.helper'}
        error={Boolean(touched.email && errors.email)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        required
        fullWidth
        type="password"
        margin="normal"
        name="password"
        value={values.password}
        placeholder="login.password.placeholder"
        label="login.password.label"
        helperText={touched.password && errors.password ? errors.password : 'login.password.helper'}
        error={Boolean(touched.password && errors.password)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <Typography gutterBottom variant="body2">
        <Link to="/password-reset/request">
          <FormattedMessage id="login.noexisting-account.forgotten-password" />
        </Link>
      </Typography>

      <ProgressButton
        submitting={isSubmitting}
        type="submit"
        buttonBusy={isSubmitting}
        disabled={!isValid}
        data-testid="submit-button"
      >
        {props.intl.formatMessage({
          id: 'login.submit.text',
        })}
      </ProgressButton>
    </form>
  );
}

export default withStyles(styles)(injectIntl(LoginForm));
