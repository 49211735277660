import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PlaceIconOutlined from '@material-ui/icons/PlaceOutlined';
import TimeIconOutlined from '@material-ui/icons/AccessTimeOutlined';
import { FormattedMessage } from 'react-intl';
const locations = [
  { label: 'project-brief.location.remote', key: 1 },
  { label: 'project-brief.location.onsite', key: 2 },
  { label: 'project-brief.location.flexible', key: 3 },
];
const styles = theme => ({
  fileLink: {
    display: 'flex',
    color: 'black',
    textDecoration: 'none',
  },
});

function ProjectBriefComponent(props) {
  const { classes, project } = props;

  return (
    <>
      <Typography variant="h4">{project.title}</Typography>
      <br />
      <Typography variant="body2">{project.description}</Typography>
      <br />
      <div style={{ flexWrap: 'wrap', display: 'flex', height: 150 }}>
        <StyledProjectKeyElement title="projects.management-page.brief.location">
          <PlaceIconOutlined />
          <FormattedMessage id={locations.find(l => l.key === project.remote).label} />
        </StyledProjectKeyElement>
        <StyledProjectKeyElement title="projects.management-page.brief.deadline">
          {project.planned_finish}
        </StyledProjectKeyElement>
        <StyledProjectKeyElement title="projects.management-page.brief.duration">
          <TimeIconOutlined />
          {
            [{ id: 1, label: '1 Day' }, { id: 2, label: '1 Week' }, { id: 3, label: '1 Month' }].find(
              d => d.id === project.duration,
            ).label
          }
        </StyledProjectKeyElement>
        <StyledProjectKeyElement title="projects.management-page.brief.budget">
          {project.budget} {project.quoteType === 1 ? 'total' : 'per day'}
        </StyledProjectKeyElement>
      </div>
      <Typography variant="body2">
        <FormattedMessage id="projects.management-page.brief.tasks" />
      </Typography>
      {project.job_tasks.map(t => (
        <div style={{ marginTop: 16, marginBottom: 16 }} key={t.id}>
          <Typography variant="body2">{t.value}</Typography>
        </div>
      ))}
      <br />
      <Typography variant="h6">
        <FormattedMessage id="projects.management-page.brief.supporting-documents" />
      </Typography>
      {project.media.map(t => (
        <a key={t.realFileName} href={t.fullUrl} target="_blank" rel="noreferrer noopener" className={classes.fileLink}>
          <Typography variant="body2">{t.realFileName}</Typography>
        </a>
      ))}
    </>
  );
}
export default withStyles(styles)(ProjectBriefComponent);

const projectElementStyles = theme => ({
  root: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing.unit,
  },
  subSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
});
function ProjectKeyElement(props) {
  const { classes, title, children } = props;

  return (
    <div className={classes.root}>
      <Typography variant="caption" className={classes.title}>
        <FormattedMessage id={title} />
      </Typography>
      <Typography variant="body2" className={classes.subSection}>
        {children}
      </Typography>
    </div>
  );
}

const StyledProjectKeyElement = withStyles(projectElementStyles)(ProjectKeyElement);
