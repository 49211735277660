import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Progress from './Progress';
import Tooltip from '@material-ui/core/Tooltip';
import { injectIntl } from 'react-intl';

const styles = theme => ({
  button: {
    marginTop: theme.spacing.unit * 3,
  },
  busyButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'black',
    '&:hover': {
      color: 'black',
    },
    '&:disabled': {
      color: 'black',
    },
    '&:hover:disabled': {
      color: 'black',
    },
  },
  enabledColours: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:hover:disabled': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  progress: {
    // marginLeft: theme.spacing.unit * 2,
  },
});

function ProgressButton(props) {
  const { classes, buttonBusy, disabled, submitting, children, className, ...rest } = props;
  const title = submitting ? 'progress.button.processing' : buttonBusy ? 'progress.button.validating' : '';

  return (
    <Tooltip title={title ? props.intl.formatMessage({ id: title }) : ''}>
      {/* <div> */}
      <Button
        className={classNames(classes.button, className, {
          [classes.busyButton]: buttonBusy,
          [classes.enabledColours]: !disabled || submitting,
        })}
        fullWidth
        variant="contained"
        disabled={buttonBusy || disabled}
        {...rest}
      >
        {buttonBusy ? <Progress className={classes.progress} /> : children}
      </Button>
      {/* </div> */}
    </Tooltip>
  );
}

ProgressButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

ProgressButton.defaultProps = {
  buttonBusy: false,
  disabled: false,
  submitting: false,
};

export function useProgressButton(initialValue) {
  const [buttonBusy, setButtonBusy] = useState(initialValue);
  return [buttonBusy, setButtonBusy];
}

export default withStyles(styles)(injectIntl(ProgressButton));
