import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as DeleteIcon } from '../assets/Icon-Delete.svg';
import { useState } from 'react';
import ProgressDeleteButton, { useProgressDeleteButton } from '../FormFields/ProgressDeleteButton';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { withTheme } from '@material-ui/core/styles';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 85,
    height: theme.spacing.unit * 85,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  dialogContent: {
    display: 'flex',
    padding: theme.spacing.unit * 3,
    alignItems: 'center',
    flexDirection: 'column',
  },
  deleteIcon: {
    marginTop: theme.spacing.unit * 4,
  },
  header: {
    display: 'flex',
    marginTop: theme.spacing.unit * 4,
    textAlign: 'center',
  },
  blurb: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 63,
    },
    marginTop: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit * 3,
  },
  deleteButton: {
    display: 'flex',
    backgroundColor: '#B00020',
    marginBottom: theme.spacing.unit * 2,
    color: 'white',
    '&:hover': {
      backgroundColor: '#B00020',
    },
  },
});

function DeletionModal(props) {
  const { classes, modalOpen, onDelete, onCancel, itemDeletionLabel, itemDeletionProse, theme } = props;
  const [buttonBusy, setButtonBusy] = useProgressDeleteButton(false);
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(
    () => {
      if (modalOpen === false) {
        setButtonBusy(false);
      }
    },
    [modalOpen],
  );

  return (
    <Dialog
      fullScreen={smallScreen}
      maxWidth={'md'}
      open={modalOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <DialogContent className={classes.dialogContent}>
        <DeleteIcon className={classes.deleteIcon} />
        <Typography variant="h5" className={classes.header}>
          <FormattedMessage id="dialog.delete.header" values={{ itemDeletionLabel: itemDeletionLabel }} />
        </Typography>
        <Typography variant="body2" className={classes.blurb}>
          <FormattedMessage id={itemDeletionProse} />
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ProgressDeleteButton
          onClick={e => {
            setButtonBusy(true);
            onDelete(e);
          }}
          buttonBusy={buttonBusy}
        >
          <FormattedMessage
            id="dialog.delete.delete"
            values={{ itemDeletionLabel: smallScreen ? '' : itemDeletionLabel }}
          />
        </ProgressDeleteButton>
        <Button onClick={onCancel} fullWidth variant={'outlined'}>
          <FormattedMessage id="cancel" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function useModal(initialValue) {
  const [modalOpen, setModalOpen] = useState(initialValue);
  return [modalOpen, setModalOpen];
}

DeletionModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

DeletionModal.defaultProps = {
  onDelete: () => {},
  modalOpen: false,
  itemDeletionLabel: '',
  itemDeletionProse: 'dialog.delete.prose',
};
export default withTheme()(withStyles(styles)(DeletionModal));
