import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import Availability from './Components/Availability';
import RemoteAvailability from './Components/RemoteAvailability';
import SkillsAndRates from './Components/SkillsAndRates';
import Rate from './Components/Rate';
import Languages from './Components/Languages';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import BookmarkFilledIcon from '@material-ui/icons/Bookmark';
import DeleteIcon from '@material-ui/icons/Delete';
import { ShortlistContext } from '../Shortlist/ShortlistContext';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import { NormalAvatar } from '../SupplierProfile/SupplierAvatar';
import { Link } from 'react-router-dom';
import { SearchResultsContext } from './SearchResultsContext';
import isEmpty from 'lodash/isEmpty';
import ShortlistModal, { useModal } from '../Shortlist/ShortlistModal';

const styles = (theme) => ({
  listItemRoot: {
    paddingRight: '0px',
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 2 * theme.spacing.unit,
    flexWrap: 'wrap',
    color: theme.palette.primary.main,
    alignItems: 'flex-start',
  },

  itemLink: {
    textDecoration: 'none',
  },
  bio: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '75ch',
  },
  midRow: {
    display: 'flex',
    '& > div': {
      width: '32%',
    },
  },
  grow: {
    display: 'flex',
    flexGrow: 1,
  },
  shortListButton: {
    color: theme.palette.primary.main,
    padding: 0,
  },
});

function SearchListItem(props) {
  const { classes, item, enqueueSnackbar, action, forceScreen } = props;
  const [processingShortlist, setProcessingShortlist] = useState(false);
  const bio = item.bio.value.length > 250 ? `${item.bio.value.substring(0, 250)}...` : item.bio.value;
  const { shortlist, delist } = useContext(ShortlistContext);
  const { searchItem } = useContext(SearchResultsContext);
  const [modalOpen, setModalOpen, suppliersToAdd, setSuppliersToAdd] = useModal(false);

  function toggleShortlistedSupplier() {
    setProcessingShortlist(true);
    (item.shortlisted ? delist(item.id) : shortlist(item.id))
      .then((r) => {
        if (item.shortlisted) {
          enqueueSnackbar(
            props.intl.formatMessage({
              id: 'Supplier was removed from your shortlist',
            }),
          );
        }
      })
      .catch((msg) => {
        if (msg === 'client.shortlist.too-many') {
          setModalOpen(true);
          setSuppliersToAdd([item]);
        } else {
          enqueueSnackbar(
            props.intl.formatMessage({
              id: msg,
            }),
          );
        }
      })
      .finally(() => {
        setProcessingShortlist(false);
      });
  }

  return (
    <>
      <ShortlistModal
        modalOpen={modalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
        suppliersToAdd={suppliersToAdd}
        forceScreen={forceScreen}
      />
      <ListItem disableGutters>
        <Link className={classes.itemLink} to={`/supplier/${item.id}`}>
          <ListItemAvatar>
            <NormalAvatar item={item} />
          </ListItemAvatar>
        </Link>
        <ListItemText
          className={classes.listItemRoot}
          primary={
            <div className={classes.itemRow}>
              <Link className={classes.itemLink} to={`/supplier/${item.id}`}>
                <Typography variant="h5" color="textPrimary">
                  {item.name}
                </Typography>
                <Typography variant="caption" color="textPrimary">
                  ID: {item.id}
                </Typography>
              </Link>
              <Availability item={item} />
              <div className={classes.grow} />
              <IconButton
                className={classes.shortListButton}
                disabled={processingShortlist}
                onClick={toggleShortlistedSupplier}
              >
                {action === 'trash' ? (
                  <DeleteIcon />
                ) : item.shortlisted ? (
                  <BookmarkFilledIcon />
                ) : (
                  <BookmarkOutlinedIcon />
                )}
              </IconButton>
            </div>
          }
          secondaryTypographyProps={{ component: 'div' }}
          secondary={
            <div>
              <div className={classNames(classes.itemRow)}>
                <Typography variant="body2" color="textPrimary">
                  {bio}
                </Typography>
              </div>
              <div className={classNames(classes.itemRow, classes.midRow)}>
                {!isEmpty(searchItem) ? (
                  <Rate
                    currency={item.currency.symbol}
                    skill={item.skills_search.find((s) => s.id === searchItem.id)}
                  />
                ) : null}
                <RemoteAvailability item={item} />
                {item.languages.length > 1 && <Languages item={item} />}
              </div>
              <div className={classes.itemRow}>
                <SkillsAndRates
                  skills={!isEmpty(searchItem) ? item.skills_search : item.skills}
                  currencySymbol={item.currency.symbol}
                />
              </div>
            </div>
          }
        />
      </ListItem>
    </>
  );
}

export default withStyles(styles)(withSnackbar(injectIntl(SearchListItem)));
