import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import uniqBy from 'lodash/uniqBy';
import PopoverDisplay from '../Popover/PopoverDisplay';
import NotificationsList from './Components/NotificationsList';
import { Link } from 'react-router-dom';
import NotificationsTabs from './Components/NotificationsTabs';
import { NotificationContext } from './NotificationContext';
import Progress from '../FormFields/Progress';

const styles = theme => ({
  root: {
    width: theme.spacing.unit * 62,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 10px)',
    },
  },
  notificationsList: {
    maxHeight: theme.spacing.unit * 55,
    overflowY: 'auto',
  },
  header: {
    display: 'block',
    width: '100%',
    marginBottom: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  menuChip: {
    marginBottom: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  icon: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    cursor: 'pointer',
  },
  paddedItem: {
    padding: theme.spacing.unit * 2,
  },
  unread: {
    backgroundColor: '#FFFDE9',
  },
});
function NotificationsPopover(props) {
  const { classes } = props;
  const [projects, setProjects] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [value, setValue] = useState('all');
  const { notifications, unreadNotifications, notificationsLoading } = useContext(NotificationContext);

  useEffect(() => {
    setFilteredNotifications(filterResults(value, notifications.slice(0, 25)));
    setProjects(uniqBy(notifications, 'project.id').map(n => n.project));
  }, [notifications]);

  function filterResults(filterValue, values) {
    return filterValue === 'all' ? values : values.filter(n => n.project.id === filterValue);
  }

  function handleChange(event, newValue) {
    setValue(newValue);
    setFilteredNotifications(filterResults(newValue, notifications));
  }

  function whichComponent() {
    if (notificationsLoading) {
      return <Progress />;
    } else if (notifications.length === 0) {
      return (
        <Typography variant="h6" style={{ padding: 8 }}>
          <FormattedMessage id="communications.notifications.none" />
        </Typography>
      );
    } else {
      return <NotificationsList items={filteredNotifications} className={classes.notificationsList} />;
    }
  }

  return (
    <PopoverDisplay
      clickableItem={(setOpen, open) => (
        <Badge className={classes.icon} invisible={unreadNotifications === 0} color="secondary" variant="dot">
          <NotificationsIcon
            onClick={e => {
              setOpen(!open);
            }}
          />
        </Badge>
      )}
      className={classes.root}
    >
      <div className={classes.paddedItem}>
        <FormattedMessage id="communications.notifications" />
      </div>
      <NotificationsTabs items={projects} value={value} onChange={handleChange} />
      {whichComponent()}
      <Divider />
      <div style={{ padding: 8 }}>
        <Button fullWidth variant="contained" component={Link} to="/notifications" color="primary">
          <FormattedMessage id="communications.notifications.view-all" />
        </Button>
      </div>
    </PopoverDisplay>
  );
}

export default withStyles(styles)(NotificationsPopover);
