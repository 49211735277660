import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { withTheme } from '@material-ui/core/styles';
import SkillsAndRates from '../../ClientSearch/Components/SkillsAndRates';
import imageIcon from 'assets/Icon-Image.svg';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 85,
    height: theme.spacing.unit * 85,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  dialogContent: {
    padding: theme.spacing.unit * 3,
    flexDirection: 'column',
  },
  deleteIcon: {
    marginTop: theme.spacing.unit * 4,
  },
  header: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit,
  },
  blurb: {
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 80,
    },
    marginTop: theme.spacing.unit * 2,
  },
  dialogActions: {
    padding: theme.spacing.unit * 3,
  },
  deleteButton: {
    backgroundColor: '#B00020',
    marginBottom: theme.spacing.unit * 2,
    color: 'white',
    '&:hover': {
      backgroundColor: '#B00020',
    },
  },
  projectLink: {
    marginTop: theme.spacing.unit * 2,
    color: theme.palette.primary.main,
  },
});

function SupplierProjectModal(props) {
  const { classes, theme, item, modalOpen, onCancel, skills, forceScreen } = props;
  const smallScreen = forceScreen ? false : useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={smallScreen}
      maxWidth={'md'}
      open={modalOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h4">{item.title}</Typography>
        <Typography variant="body2" className={classes.blurb}>
          {item.bio}
        </Typography>
        <p>
          <a href={item.url} className={classes.projectLink}>
            {item.url}
          </a>
        </p>
        <Typography variant="h5" className={classes.header}>
          Files
        </Typography>
        <StyledProjectFiles files={item.files} />
        <Typography variant="h5" className={classes.header}>
          Skills
        </Typography>
        <SkillsAndRates skills={skills} currencySymbol="£" className={classes.blurb} />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onCancel} fullWidth variant={'outlined'}>
          <FormattedMessage id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const projectFilesStyles = theme => ({
  projectFiles: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  projectFileLink: {
    width: '50%',
    display: 'flex',
    color: 'black',
    textDecoration: 'none',
  },
  projectFile: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    '&>img': {
      marginRight: theme.spacing.unit * 2,
    },
  },
  projectFileText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
function ProjectFiles(props) {
  const { classes, files } = props;

  return (
    <div className={classes.projectFiles}>
      {files.map(f => {
        return (
          <a
            key={f.originalFileName}
            className={classes.projectFileLink}
            href={f.fullUrl}
            target="_blank"
            rel="noreferrer noopener"
          >
            <div className={classes.projectFile}>
              <img src={imageIcon} alt="" />
              <div className={classes.projectFileText}>{f.originalFileName}</div>
            </div>
          </a>
        );
      })}
    </div>
  );
}

const StyledProjectFiles = withStyles(projectFilesStyles)(ProjectFiles);

export function useModal(initialValue) {
  const [modalOpen, setModalOpen] = useState(initialValue);
  return [modalOpen, setModalOpen];
}

SupplierProjectModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

SupplierProjectModal.defaultProps = {
  modalOpen: false,
};

export default withTheme()(withStyles(styles)(SupplierProjectModal));
