import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import * as Yup from 'yup';
import SowEditForm from './SowEditForm';
import { Formik } from 'formik';
import HeaderPageLinkBack from '../Pages/HeaderPageLinkBack';
import { Paper } from '@material-ui/core';
import { getSow, putSow } from '../Api/SOW';
import get from 'lodash/get';
import { fetchCurrencies } from '../Api/FetchAndCache';
import Progress from '../FormFields/Progress';
import { withSnackbar } from 'notistack';
import { injectIntl } from 'react-intl';

const styles = theme => ({});

export function convertSowToValues(sow) {
  const values = {
    title: sow.title,
    description: sow.background,
    startdate: sow.startDate || '',
    deadline: sow.deadline || '',
    billingType: sow.quoteType ? sow.quoteType.toString() : '1',
    deliverables: sow.deliverables.map(d => {
      return { label: d.value, value: d.value };
    }),
    quoteCurrency: sow.currency,
    hasDayCapp: sow.hasDayCapp,
    dayCapp: sow.dayCapp,
    quote: sow.quote,
    sowLegal: sow.tcAccepted,
  };

  if (values.billingType === '1') {
    values.milestones = sow.milestones.map(m => {
      return { milestone: m.value, cost: m.cost, deadline: m.deadline };
    });
  } else {
    values.quote = sow.cost;
    values.milestones = sow.milestones.map(m => {
      return { milestone: m.value, days: m.days };
    });
  }
  return values;
}

export function convertValuesToSow(values) {
  const sow = {
    title: values.title,
    description: values.description,
    startDate: values.startdate,
    deadline: values.deadline,
    quoteType: values.billingType,
    deliverables: values.deliverables.map(d => d.value),
    quoteCurrency: values.quoteCurrency,
    hasDayCapp: values.hasDayCapp,
    dayCapp: values.dayCapp,
    tcAccepted: values.sowLegal,
  };
  if (sow.quoteType === '1') {
    sow.milestones = values.milestones.map(m => {
      return { value: m.milestone, cost: m.cost, deadline: m.deadline };
    });
  } else {
    sow.cost = values.quote;
    sow.milestones = values.milestones.map(m => {
      return { value: m.milestone, days: m.days };
    });
  }

  return sow;
}

export const validationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('project-brief.title.required')
    .min(1),
  description: Yup.string()
    .trim()
    .required('project-brief.description.required')
    .min(1),
  startdate: Yup.string()
    .trim()
    .required('project-brief.startdate.required')
    .min(1),
  deadline: Yup.string()
    .trim()
    .required('project-brief.deadline.required')
    .min(1),
  billingType: Yup.string()
    .trim()
    .oneOf(['1', '2'])
    .required('project-brief.budget-type.required')
    .min(1),
  deliverables: Yup.array()
    .required('project-brief.deliverables.required')
    .ensure()
    .min(1)
    .test(
      'deliverablesLength',
      'project-brief.deliverables.required',
      values => values.filter(v => v.label.trim().length).length,
    ),
  milestones: Yup.array()
    .required('project-brief.milestones.required')
    .ensure()
    .min(1)
    .test('milestonesLength', 'project-brief.milestones.required', function(values) {
      if (this.parent.billingType === '1') {
        return values.filter(m => m.milestone.trim().length > 0 && m.cost > 0 && m.deadline.trim().length > 0).length;
      } else {
        return values.filter(m => m.milestone.trim().length > 0 && m.days > 0).length;
      }
    }),
  sowLegal: Yup.bool()
    .required('project-brief.sow-legal.required')
    .test('msaRead', 'project-brief.sow-legal.required', value => value === true),
});

export function SowEditPage(props) {
  const { classes, match, enqueueSnackbar, intl, history } = props;
  const [values, setValues] = useState({
    title: '',
    description: '',
    startdate: '',
    deadline: '',
    billingType: '1',
    quote: 0,
    quoteCurrency: 1,
    hasDayCapp: false,
    dayCapp: '0',
    sowLegal: false,
    deliverables: [],
    milestones: [],
  });
  const commId = Number(match.params.id);
  const form = useRef(null);
  const [currencies, setCurrencies] = useState([]);
  const [sow, setSow] = useState(false);

  const fetchData = () => {
    getSow(commId).then(resp => {
      const lclSow = get(resp, 'data.data', {});
      setSow(lclSow);
      if (lclSow.final) {
        history.push(`/sow/${commId}`);
      }
      setValues(prevVals => {
        return { ...prevVals, ...convertSowToValues(get(resp, 'data.data', {})) };
      });
    });
  };

  useEffect(() => {
    fetchData();
  }, [match.params.id]);

  useEffect(() => {
    fetchCurrencies().then(result => {
      setCurrencies(result.map(r => ({ key: r.id, label: r.symbol })));
    });
  }, []);

  if (currencies.length === 0 || !sow) {
    return (
      <HeaderPageLinkBack>
        <Paper style={{ padding: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Progress />
        </Paper>
      </HeaderPageLinkBack>
    );
  }
  return (
    <HeaderPageLinkBack>
      <Paper className={classes.root}>
        <Formik
          ref={form}
          render={props => <SowEditForm {...props} currencies={currencies} />}
          initialValues={values}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            putSow(commId, convertValuesToSow(values))
              .then(resp => {
                enqueueSnackbar(
                  intl.formatMessage({
                    id: 'projects.sow.saved',
                  }),
                );
                history.push({
                  pathname: `/sow/${commId}`,
                });
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
        />
      </Paper>
    </HeaderPageLinkBack>
  );
}

export default withStyles(styles)(withRouter(withSnackbar(injectIntl(SowEditPage))));
