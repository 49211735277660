import React from 'react';
import Chip from 'FormFields/Chip';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SupplierProjectModal, { useModal } from './SupplierProjectModal';
import ButtonLink from '../../FormFields/ButtonLink';

const projectStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  portfolioRow: {
    marginBottom: 2 * theme.spacing.unit,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
  },
  portfolioChip: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  buttonLink: {
    // textDecoration: 'underline',
    padding: 0,
    margin: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});
function SupplierProject(props) {
  const { classes, item, skills, forceScreen } = props;
  const [modalOpen, setModalOpen] = useModal(false);

  return (
    <div className={classes.root}>
      <SupplierProjectModal
        item={item}
        skills={skills}
        forceScreen={forceScreen}
        modalOpen={modalOpen}
        onCancel={() => {
          setModalOpen(false);
        }}
      />
      <Typography variant="h6" color="textPrimary" component="div">
        <ButtonLink
          onClick={() => {
            setModalOpen(true);
          }}
        >
          <div>{item.title}</div>
        </ButtonLink>
      </Typography>
      <Typography variant="body1" color="textPrimary" component="div">
        <div className={classes.portfolioRow}>{item.bio}</div>
      </Typography>
      <div className={classes.chips}>
        {item.fullSkills.map(s => {
          return <Chip key={s.id} label={s.name} className={classes.portfolioChip} />;
        })}
      </div>
    </div>
  );
}
export default withStyles(projectStyles)(SupplierProject);
