import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import { injectIntl, FormattedMessage } from 'react-intl';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing.unit,
  },
  chip: {
    marginRight: theme.spacing.unit,
    minWidth: theme.spacing.unit * 15,
  },
});

function ChipRadioGroup(props) {
  const { classes, onClick, disabled, options, name, value } = props;

  function handleClick(key) {
    onClick(name, key, true);
  }

  return (
    <div className={classes.root}>
      {options.map(chipGroup => (
        <Chip
          name={name}
          label={<FormattedMessage id={chipGroup.label} />}
          clickable
          className={classes.chip}
          color={value === chipGroup.key ? 'primary' : 'default'}
          onClick={() => {
            disabled || handleClick(chipGroup.key);
          }}
          variant={value === chipGroup.key ? 'default' : 'outlined'}
          key={chipGroup.key}
          disabled={disabled}
        />
      ))}
    </div>
  );
}
ChipRadioGroup.propTypes = {
  onClick: PropTypes.func,
};
ChipRadioGroup.defaultProps = {
  onClick: () => {},
  key: uniqueId(),
};
export default withStyles(styles)(injectIntl(ChipRadioGroup));
