import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  root: {
    display: 'flex',
    flex: '1',
    width: '100%',
    alignItems: 'center',
  },
  headerText: {
    marginTop: theme.spacing.unit * 5,
  },
  subtitleText: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 5,
  },
  card: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'flex-start',

    [theme.breakpoints.up('md')]: {
      width: theme.spacing.unit * 128,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing.unit * 2,
    [theme.breakpoints.up('md')]: {
      width: theme.spacing.unit * 90,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing.unit * 4}px)`,
    },
  },
});

function HeaderPage(props) {
  const { classes, headerText, subtitleText, children } = props;
  return (
    <React.Fragment>
      <Paper className={classes.root} elevation={1}>
        <Card className={classes.card} elevation={0}>
          <CardContent>
            {headerText && (typeof headerText !== 'string' || headerText.length > 0) ? (
              <Typography gutterBottom variant="h4" className={classes.headerText}>
                {typeof headerText === 'string' && headerText.length > 0 ? (
                  <FormattedMessage id={headerText} />
                ) : (
                  headerText
                )}
              </Typography>
            ) : null}
            <Typography gutterBottom variant="body1" className={classes.subtitleText}>
              {typeof subtitleText === 'string' && subtitleText.length > 0 ? (
                <FormattedMessage id={subtitleText} />
              ) : (
                subtitleText
              )}
            </Typography>
          </CardContent>
        </Card>
      </Paper>
      <div className={classes.contentContainer}>{children}</div>
    </React.Fragment>
  );
}

HeaderPage.propTypes = {
  subtitleText: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderPage);
