import React from 'react';
import TextInput from '../../FormFields/TextInput';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import ButtonLink from '../../FormFields/ButtonLink';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { FieldArray } from 'formik';

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit,
  },
  buttonLink: {
    color: theme.palette.primary.dark,
  },
  circleIcon: {
    marginRight: theme.spacing.unit * 2,
  },
  taskRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  trashButton: {
    width: theme.spacing.unit * 5,
    height: theme.spacing.unit * 5,
  },
});

function InvoicingMilestones(props) {
  const { milestones, name, classes, ...rest } = props;
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <div className={classes.root}>
          {milestones.map((t, index) => (
            <MilestoneRow
              name={`${name}.${index}`}
              key={index}
              onDelete={() => {
                arrayHelpers.remove(index);
              }}
              classes={classes}
              {...rest}
            />
          ))}

          <ButtonLink
            onClick={() => {
              if (milestones.filter(t => t.milestone.trim().length === 0 || t.days === 0).length) {
                return;
              }
              arrayHelpers.push({ milestone: '', days: 0 });
            }}
            className={classes.buttonLink}
          >
            <AddCircleOutline className={classes.circleIcon} />
            <FormattedMessage id="projects.sow.milestones.milestone.add" />
          </ButtonLink>
        </div>
      )}
    />
  );
}

function MilestoneRow(props) {
  const { classes, name, onDelete, ...rest } = props;
  return (
    <div className={classes.taskRow}>
      <TextInput
        name={`${name}.milestone`}
        fullWidth
        margin="normal"
        placeholder="projects.sow.milestones.milestone.placeholder"
        label="projects.sow.milestones.milestone.label"
        {...rest}
      />
      <TextInput
        name={`${name}.days`}
        margin="normal"
        type="number"
        placeholder="projects.sow.milestones.days.placeholder"
        label="projects.sow.milestones.days.label"
        {...rest}
      />

      <IconButton className={classes.trashButton} onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </div>
  );
}
export default withStyles(styles)(injectIntl(InvoicingMilestones));
