import React from 'react';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import HeaderPage from '../Pages/HeaderPage';
import { fetchClientProfile } from '../Api/FetchAndCache';
import { UnheadedRecommendedSuppliersPage } from '../ClientSearch/RecommendedSuppliersPage';
import ProjectsListWidget from '../Projects/ProjectsListWidget';

const styles = theme => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing.unit * 5,
  },
});

function ClientDashboard(props) {
  const [clientName, setClientName] = useState('');

  useEffect(() => {
    fetchClientProfile().then(client => {
      setClientName(client.name);
    });
  }, []);

  return (
    <HeaderPage
      headerText={<FormattedMessage id="dashboard.header" values={{ fullname: clientName }} />}
      subtitleText="dashboard.subtitle"
    >
      <ProjectsListWidget />
      <UnheadedRecommendedSuppliersPage />
    </HeaderPage>
  );
}
export default withStyles(styles)(ClientDashboard);
