import axios from 'axios';

export function addSupplierToGeneralShortlist(supplierId) {
  return axios.post(`/api/shortlists/supplier/${supplierId}`, {});
}

export function removeSupplierFromGeneralShortlist(supplierId) {
  return axios.delete(`/api/shortlists/supplier/${supplierId}`);
}

export function removeSupplierFromSpecificShortlist(supplierId, shortlistId) {
  return axios.delete(`/${shortlistId}/supplier/${supplierId}`);
}

export function getGeneralShortlist() {
  return axios.get('/api/shortlists/open');
}
