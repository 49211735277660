import React, { useContext, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MessageBox from './Components/MessageBox';
import { ProfileContext } from '../Profile/ProfileContext';
import HeaderPageLinkBack from '../Pages/HeaderPageLinkBack';
import ProjectCommsHeader from './Components/ProjectCommsHeader';
import get from 'lodash/get';
import { Paper, Divider, Typography } from '@material-ui/core';
import MessageList from './Components/MessageList';
import { withRouter } from 'react-router-dom';
import Progress from '../FormFields/Progress';
import { FormattedMessage } from 'react-intl';
import { CommsContext } from './CommsContext';

const styles = theme => ({
  paperMessages: {
    flexDirection: 'column',
    marginTop: theme.spacing.unit * 5,
  },
});

function CommsPage(props) {
  const { classes, match } = props;
  const { userProfile, userType } = useContext(ProfileContext);
  const { comms, setComms, retrieveComms } = useContext(CommsContext);
  const [theirProfile, setTheirProfile] = useState(false);
  const [showNewMessage, setShowNewMessage] = useState(false);

  useEffect(() => {
    if (userProfile && get(comms, 'id', false) !== Number(match.params.id)) {
      setTheirProfile(false);
      retrieveComms(Number(match.params.id));
    }
  }, [userProfile, match.params.id]);

  useEffect(() => {
    if (userProfile && get(comms, 'id', false) === Number(match.params.id)) {
      setTheirProfile(userProfile.id === comms.supplier.id ? comms.client : comms.supplier);
    }
  }, [comms]);

  function whichComponent() {
    if (userProfile && theirProfile && comms) {
      return (
        <>
          {comms.deleted_at ? null : (
            <MessageBox
              userProfile={userProfile}
              userType={userType}
              setMessages={comms => {
                setComms(comms);
                setShowNewMessage(true);
              }}
            />
          )}

          <Paper className={classes.paperMessages} elevation={1}>
            <ProjectCommsHeader userProfile={theirProfile} comms={comms} />
            <Divider />
            {comms.messages.length === 0 ? (
              <Typography variant="h6" style={{ padding: 8 }}>
                <FormattedMessage id="communications.comms.none" />
              </Typography>
            ) : (
              <MessageList
                userProfile={userProfile}
                theirProfile={theirProfile}
                userType={userType}
                comms={comms}
                showNewMessage={showNewMessage}
                setShowNewMessage={setShowNewMessage}
              />
            )}
          </Paper>
        </>
      );
    } else {
      return (
        <Paper className={classes.paperMessages} style={{ padding: 8 }} elevation={1}>
          <Progress />
        </Paper>
      );
    }
  }

  return <HeaderPageLinkBack>{whichComponent()}</HeaderPageLinkBack>;
}

export default withStyles(styles)(withRouter(CommsPage));
