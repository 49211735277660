import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as PreparingDashboardImage } from '../assets/Preparing-Dashboard.svg';

import { FormattedMessage } from 'react-intl';
import CardContent from '@material-ui/core/CardContent';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 6,
    width: theme.spacing.unit * 85,
    border: `1px solid ${theme.palette.grey[400]}`,
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    flexGrow: 1,
    display: 'flex',
    paddingTop: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 6,
  },
  image: {
    marginBottom: '0.75em',
  },
  cardContent: {
    maxWidth: theme.spacing.unit * 70,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    flexGrow: 1,
    '& > *': {
      marginBottom: '0.75em',
    },
  },
});

function PreparingDashboard(props) {
  const { classes } = props;
  return (
    <Paper className={classes.paper} elevation={0}>
      <Card className={classes.card}>
        <PreparingDashboardImage className={classes.image} />
        <CardContent className={classes.cardContent}>
          <Typography variant="h1">
            <FormattedMessage id="preparing-dashboard.welcome.header" />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="preparing-dashboard.welcome.body" />
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default withStyles(styles)(PreparingDashboard);
