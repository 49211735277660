import React from 'react';
import classNames from 'classnames';
import MuiChip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = theme => ({
  chip: {
    color: theme.palette.primary.dark,
  },
  chipRoot: {
    maxWidth: 70 * theme.spacing.unit,
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
  },
  chipLabel: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.spacing.unit * 22,
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
});

function Chip(props) {
  const { classes, coloured, className, ...rest } = props;

  return (
    <MuiChip
      variant="outlined"
      color={coloured ? 'primary' : 'default'}
      className={classNames({ [classes.chip]: coloured }, className)}
      classes={{ label: classes.chipLabel, root: classes.chipRoot }}
      {...rest}
    />
  );
}

Chip.propTypes = {
  classes: PropTypes.object.isRequired,
  coloured: PropTypes.bool.isRequired,
};

Chip.defaultProps = {
  coloured: true,
};

export default withStyles(styles)(Chip);
