import React from 'react';
import SupplierProfile from './SupplierProfile';
import { getUserType } from '../Api/LocalStorage';
import ClientProfile from './ClientProfile';
import NotFoundPage from '../Pages/NotFoundPage';

function Profile(props) {
  const userType = getUserType();
  if (userType === 'supplier') {
    return <SupplierProfile />;
  } else if (userType === 'client') {
    return <ClientProfile />;
  } else {
    return <NotFoundPage />;
  }
}

export default Profile;
