import React from 'react';
import { useState, useEffect } from 'react';

import AdditionalLanguageSelector from './AdditionalLanguageSelector';
import Button from '@material-ui/core/Button';
import uniqueId from 'lodash/uniqueId';
import { fetchLanguages } from '../Api/FetchAndCache';

function AdditionalLanguageManager(props) {
  const { selectedLanguages, onClick } = props;
  const [languages, setLanguages] = useState([]);
  const [availableLanguages, setAvailableLanguages] = useState(
    languages.map(l => {
      return { ...l, key: uniqueId() };
    }),
  );

  const fetchData = async () => {
    fetchLanguages().then(result => {
      setLanguages(
        result.map(l => {
          return { ...l, key: uniqueId() };
        }),
      );
    });
  };

  useEffect(() => {
    if (selectedLanguages.length === 0) {
      onClick([{ selected: 0, level: 0, name: '', key: uniqueId() }]);
    }
    fetchData();
  }, []);

  useEffect(
    () => {
      const reducedLangs = selectedLanguages.map(sl => sl.selected);
      setAvailableLanguages(
        languages
          .filter(l => l.id !== 1)
          .filter(l => !reducedLangs.includes(l.id))
          .map(l => {
            return { ...l, key: uniqueId() };
          }),
      );
    },
    [selectedLanguages, languages],
  );

  function handleClick() {
    onClick([...selectedLanguages, { selected: 0, level: 0, name: '', key: uniqueId() }]);
  }

  return (
    <div data-testid="additional-language-manager">
      {selectedLanguages.map((orig, idx) => (
        <AdditionalLanguageSelector
          language={orig}
          languages={availableLanguages}
          handleRemoveClick={() => {
            const updatedFormValues = selectedLanguages.filter(orig2 => {
              return orig2.selected !== orig.selected;
            });
            onClick(updatedFormValues);
          }}
          onUpdate={(name, value) => {
            orig[name] = value;
            const selectedLanguage = languages.find(l => l.id === orig.selected);
            const updatedFormValues = selectedLanguages.map((orig2, index) => {
              if (index === idx) {
                const updVals = {
                  ...orig2,
                  [name]: value,
                  name: selectedLanguage ? selectedLanguage.name : orig2.name,
                };
                return updVals;
              }
              return orig2;
            });
            onClick(updatedFormValues);

            // if (name === 'selected') {
            //   const nuLangs = updatedFormValues.map(orig2 => {
            //     return Number(orig2.selected);
            //   });
            //   setAvailableLanguages(languages.filter(l => !nuLangs.includes(l.selected)));
            // }
          }}
          key={orig.key}
        />
      ))}
      {selectedLanguages.length + 1 < languages.length && <Button onClick={handleClick}>Add another language</Button>}
    </div>
  );
}
export default AdditionalLanguageManager;
