import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { withTheme } from '@material-ui/core/styles';
import ProgressButton from '../../FormFields/ProgressButton';
import TextInput from '../../FormFields/TextInput';
import { InputAdornment, FormControl, Select, OutlinedInput } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { sendQuote } from '../../Api/Comms';
import { CommsContext } from '../CommsContext';
import get from 'lodash/get';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 85,
    height: theme.spacing.unit * 85,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  dialogContent: {
    paddingBottom: 0,
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  header: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit,
  },
  blurb: {
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 80,
    },
    marginTop: theme.spacing.unit * 2,
  },
  dialogActions: {
    paddingRight: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
    flexDirection: 'column',
    margin: 0,
  },
  closeButton: {
    marginTop: theme.spacing.unit * 2,
  },
  quoteInput: {
    paddingRight: 0,
    paddingLeft: 0,
  },
});

const validationSchema = Yup.object().shape({
  quote: Yup.number()
    .required('communications.comms.quote.required')
    .min(1)
    .positive('communications.comms.quote.required'),
});
const defaultValues = { quote: '', quoteCurrency: '1', quoteType: '1' };

function QuoteModal(props) {
  const { classes, theme, modalOpen, onCancel, forceScreen, intl, currencies } = props;
  const smallScreen = forceScreen ? false : useMediaQuery(theme.breakpoints.down('sm'));
  const { comms, setComms } = useContext(CommsContext);

  return (
    <Dialog
      fullScreen={smallScreen}
      maxWidth={'md'}
      open={modalOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <Formik
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          sendQuote(comms.id, values)
            .then(resp => {
              setComms(get(resp, 'data.data', {}));
            })
            .finally(() => {
              onCancel();
              actions.setSubmitting(false);
            });
        }}
        render={props => {
          const { values, touched, errors, handleSubmit, handleChange, handleBlur, isSubmitting, isValid } = props;
          return (
            <>
              <form onSubmit={handleSubmit}>
                <DialogContent className={classes.dialogContent}>
                  <Typography variant="h4">
                    <FormattedMessage
                      id="communications.comms.quote.title"
                      values={{
                        projectTitle: comms.job.title,
                      }}
                    />
                  </Typography>
                  <Typography variant="body2" className={classes.blurb}>
                    <FormattedMessage id="communications.comms.quote.blurb" />
                  </Typography>
                  <TextInput
                    fullWidth
                    required
                    type="number"
                    margin="normal"
                    name="quote"
                    value={values.quote}
                    placeholder="communications.comms.quote.placeholder"
                    helperText={touched.quote && errors.quote ? errors.quote : null}
                    error={Boolean(touched.quote && errors.quote)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    InputProps={{
                      className: classes.quoteInput,
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormControl variant="outlined">
                            <Select
                              native
                              name="quoteCurrency"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={isSubmitting}
                              value={values.quoteCurrency}
                              input={<OutlinedInput labelWidth={0} name="quoteCurrency" id="currency-select" />}
                            >
                              {currencies.map(currency => (
                                <option value={currency.key} key={currency.key}>
                                  {currency.label}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <FormControl variant="outlined" style={{ minWidth: 130 }}>
                            <Select
                              native
                              name="quoteType"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={isSubmitting}
                              value={values.quoteType}
                              input={<OutlinedInput labelWidth={0} name="quoteType" id="quote-type-select" />}
                            >
                              {[
                                {
                                  id: 1,
                                  name: intl.formatMessage({ id: 'communications.comms.quote-type.fixed-cost' }),
                                },
                                { id: 2, name: intl.formatMessage({ id: 'communications.comms.quote-type.day-rate' }) },
                              ].map(quoteType => (
                                <option value={quoteType.id} key={quoteType.id}>
                                  {quoteType.name}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Typography variant="body2" className={classes.blurb}>
                    <FormattedHTMLMessage id="communications.comms.quote.waiver" />
                  </Typography>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <ProgressButton
                    type="submit"
                    fullWidth
                    disabled={!isValid || isSubmitting}
                    submitting={isSubmitting}
                    buttonBusy={isSubmitting}
                  >
                    <FormattedMessage id="communications.comms.send-quote" />
                  </ProgressButton>
                  <Button onClick={onCancel} fullWidth variant={'outlined'} className={classes.closeButton}>
                    <FormattedMessage id="close" />
                  </Button>
                </DialogActions>
              </form>
            </>
          );
        }}
      />
    </Dialog>
  );
}

export function useModal(initialValue) {
  const [modalOpen, setModalOpen] = useState(initialValue);
  return [modalOpen, setModalOpen];
}

QuoteModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

QuoteModal.defaultProps = {
  modalOpen: false,
};

export default withTheme()(withStyles(styles)(injectIntl(QuoteModal)));
