import { string, addMethod } from 'yup';

addMethod(string, 'website', function(message) {
  return this.test({
    name: 'website',
    message: message || 'Invalid website', // expect an i18n message to be passed in
    test: async function(value) {
      try {          
        
        // an empty string comes as undefined rather than a string with length of 0 😱
        if (!value || typeof value !== 'string' || value.length === 0) {
          return true;
        }
        return await string()
          .url()
          .isValid(value.startsWith('http') ? value : `http://${value}`);
      } catch (e) {
        console.log(e);
        return false;
      }
    },
  });
});
