import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

const styles = theme => ({
  brief: {
    height: theme.spacing.unit * 3,
    backgroundColor: '#EDE7F6',
    color: '#6200EA',
  },
  kickedoff: {
    height: theme.spacing.unit * 3,
    backgroundColor: '#EDE7F6',
    color: '#6200EA',
  },
  awaitingsow: {
    height: theme.spacing.unit * 3,
    backgroundColor: '#F1FBFB',
    color: '#007456',
  },
  inprogress: {
    height: theme.spacing.unit * 3,
    backgroundColor: '#FFFDE9',
    color: '#F08730',
  },
  issue: {
    height: theme.spacing.unit * 3,
    backgroundColor: '#FCE4EC',
    color: '#C2185B',
  },
  completed: {
    height: theme.spacing.unit * 3,
    backgroundColor: '#F1F8E9',
    color: '#689F38',
  },
});

function ProjectStatusChip(props) {
  const { status, classes, className, ...rest } = props;
  switch (status) {
    case 2:
    case 3:
      return (
        <Chip
          label={<FormattedMessage id="projects.list.chips.kicked-off" />}
          className={classNames(classes.kickedoff, className)}
          {...rest}
        />
      );
    case 4:
      return (
        <Chip
          label={<FormattedMessage id="projects.list.chips.awaiting-sow" />}
          className={classNames(classes.awaitingsow, className)}
          {...rest}
        />
      );
    case 5:
    case 6:
      return (
        <Chip
          label={<FormattedMessage id="projects.list.chips.in-progress" />}
          className={classNames(classes.inprogress, className)}
          {...rest}
        />
      );
    case 7:
      return (
        <Chip
          label={<FormattedMessage id="projects.list.chips.issue" />}
          className={classNames(classes.issue, className)}
          {...rest}
        />
      );
    case 8:
      return (
        <Chip
          label={<FormattedMessage id="projects.list.chips.completed" />}
          className={classNames(classes.completed, className)}
          {...rest}
        />
      );
    default:
      return (
        <Chip
          label={<FormattedMessage id="projects.list.chips.brief" />}
          className={classNames(classes.brief, className)}
          {...rest}
        />
      );
  }
}

export default withStyles(styles)(ProjectStatusChip);
