import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { SmallAvatar } from '../../SupplierProfile/SupplierAvatar';
import ProjectStatusChip from '../../Projects/Components/ProjectStatusChip';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  headerBox: {
    marginLeft: theme.spacing.unit * 2,
  },
  headerSecondRow: {
    flexDirection: 'row',
    width: '100 %',
    display: 'flex',
    alignItems: 'center',
  },
  projectTitle: {
    marginRight: theme.spacing.unit * 2,
  },
});

function ProjectCommsHeader(props) {
  const { classes, userProfile, comms } = props;

  return (
    <div className={classes.root}>
      <SmallAvatar item={userProfile} />
      <div className={classes.headerBox}>
        <Typography variant="subtitle1">{userProfile.name}</Typography>
        <div className={classes.headerSecondRow}>
          <Typography variant="body2" className={classes.projectTitle}>
            {comms.job.title}
          </Typography>
          <ProjectStatusChip status={comms.status} />
        </div>
      </div>
    </div>
  );
}

ProjectCommsHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  comms: PropTypes.object.isRequired,
};
export default withStyles(styles)(ProjectCommsHeader);
