import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { FormattedHTMLMessage } from 'react-intl';
import Divider from '../FormFields/Divider';
import Typography from '@material-ui/core/Typography';
import SupplierProfile from '../SupplierProfile/SupplierProfile';

const styles = theme => ({
  root: {
    width: theme.spacing.unit * 85,
    marginTop: theme.spacing.unit * 5,
  },
  paperForm: {
    padding: theme.spacing.unit * 3,
    display: 'flex',
    '& > *': {
      marginBottom: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing.unit * 4,
      },
    },
    flexDirection: 'column',
  },
});

function OnBoardingVariant(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Paper className={classes.paperForm} elevation={1}>
        <Typography variant="overline" color="primary">
          <FormattedHTMLMessage id="supplier-profile.step" />
        </Typography>
        <Typography variant="h4" color="inherit">
          <FormattedHTMLMessage id="supplier-profile.header" />
        </Typography>
        <Typography variant="body2" color="inherit">
          <FormattedHTMLMessage id="supplier-profile.prose" />
        </Typography>
        <Divider />
        <SupplierProfile forwardTo="/supplier/skills" />
      </Paper>
    </div>
  );
}

export default withStyles(styles)(OnBoardingVariant);
