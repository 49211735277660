import axios from 'axios';

export function updateProject(id, values) {
  return axios.put(`/api/profiles/supplier/project/${id}`, values);
}

export function saveProject(values) {
  return axios.post('/api/profiles/supplier/project', values);
}

export function getProjects() {
  return axios.get('/api/profiles/supplier/project');
}

export function deleteProject(id) {
  return axios.delete(`/api/profiles/supplier/project/${id}`);
}

export function fileUploads(values) {
  return axios.post('/api/profiles/supplier/project/files', values);
}
