import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { getNotifications } from 'Api/Notifications';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import NotificationsList from './Components/NotificationsList';
import HeaderPage from '../Pages/HeaderPage';
import NotificationsTabs from './Components/NotificationsTabs';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InfiniteLoader, { usePagination } from '../ClientSearch/Components/InfiniteLoader';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  paperForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  listClass: {
    overflow: 'visible',
  },
});

function NotificationsPage(props) {
  const { classes } = props;
  const [notifications, setNotifications] = useState([]);
  const [projects, setProjects] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [tabValue, setTabValue] = useState('all');
  const [currentPage, setCurrentPage, endPage, setEndPage] = usePagination();
  const [initialised, setInitialised] = useState(false);

  function handleChange(event, newValue) {
    setTabValue(newValue);
    setFilteredNotifications(filterResults(newValue, notifications));
  }

  function loadMore() {
    return new Promise((resolve, reject) => {
      getNotifications(25, currentPage + 1).then(response => {
        const nots = get(response, 'data.data.data', []);
        setNotifications(prevVals => {
          const newVals = [...prevVals, ...nots];
          setFilteredNotifications(filterResults(tabValue, newVals));
          return newVals;
        });
        setProjects(uniqBy(nots, 'project.id').map(n => n.project));
        setCurrentPage(get(response, 'data.data.current_page', 1));
        setEndPage(get(response, 'data.data.last_page', 1));
        setInitialised(true);
        resolve(response);
      });
    });
  }

  function filterResults(filterValue, values) {
    return filterValue === 'all' ? values : values.filter(n => n.project.id === filterValue);
  }

  return (
    <HeaderPage
      headerText={<FormattedMessage id="communications.notifications.header" />}
      subtitleText={<FormattedMessage id="communications.notifications.subheader" />}
    >
      <Paper className={classes.paperForm} elevation={1}>
        <NotificationsTabs items={projects} value={tabValue} onChange={handleChange} />
        {initialised && filteredNotifications.length === 0 && (
          <Typography variant="h6" style={{ padding: 8 }}>
            <FormattedMessage id="communications.notifications.none" />
          </Typography>
        )}
        <InfiniteLoader
          currentPage={currentPage}
          endPage={endPage}
          ListChild={NotificationsList}
          initialised={initialised}
          loadMore={loadMore}
          items={filteredNotifications}
        />
      </Paper>
    </HeaderPage>
  );
}

export default withStyles(styles)(NotificationsPage);
