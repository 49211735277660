import React from 'react';
import classNames from 'classnames';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const tabStyles = theme => ({
  tabsRoot: {
    height: '100%',
  },
  tabsFlexContainer: {
    height: '100%',
  },
  tabs: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 8,
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  tab: {
    [theme.breakpoints.up('md')]: {
      minWidth: 170,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 105,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 88,
    },
    whiteSpace: 'nowrap',
  },
  grow: {
    display: 'flex',
    flexGrow: 1,
  },
});

const TabComponent = function(props) {
  const { classes, value, tabs, className } = props;
  return (
    <Tabs
      className={classNames(classes.tabs, classes.grow, className)}
      classes={{ root: classes.tabsRoot, flexContainer: classes.tabsFlexContainer }}
      value={value}
      variant="scrollable"
      scrollButtons="off"
    >
      {tabs.map(tabInfo => {
        const label = (
          <Typography variant="body2" color="textPrimary" key={tabInfo.key}>
            <FormattedMessage id={tabInfo.label} />
          </Typography>
        );
        return (
          <Tab
            value={tabInfo.value}
            label={label}
            component={Link}
            to={tabInfo.value}
            key={tabInfo.key}
            className={classNames('no-underline', classes.tab)}
          />
        );
      })}
    </Tabs>
  );
};

export default withStyles(tabStyles)(TabComponent);
