import {
  getLocalProfile,
  updateLocalProfile,
  getLocalLanguages,
  updateLocalLanguages,
  getLocalCountries,
  updateLocalCountries,
  getLocalCategories,
  updateLocalCategories,
  getLocalRates,
  updateLocalRates,
  getLocalProjects,
  updateLocalProjects,
  getLocalCurrencies,
  updateLocalCurrencies,
  getLocalIndustries,
  updateLocalIndustries,
  getLocalRoles,
  updateLocalRoles,
  getLocalCompanySizes,
  updateLocalCompanySizes,
} from '../Api/LocalStorage';

import { getSupplierProfile } from '../Api/SupplierProfile';
import { getClientProfile } from '../Api/ClientProfile';
import { getProjects } from '../Api/Projects';
import {
  getLanguages,
  getCountries,
  getCategories,
  getRates,
  getCurrencies,
  getIndustries,
  getRoles,
  getCompanySizes,
} from '../Api/DataHelpers';

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

export function fetchClientProfile() {
  return fetchAndCache(getLocalProfile, updateLocalProfile, getClientProfile);
}

export function fetchSupplierProfile() {
  return fetchAndCache(getLocalProfile, updateLocalProfile, getSupplierProfile);
}

export function updateSupplierProfile() {
  return fetchAndCacheUpdate(getLocalProfile, updateLocalProfile, getSupplierProfile);
}

export function fetchProjects() {
  return fetchAndCache(getLocalProjects, updateLocalProjects, getProjects);
}

export function updateProjects() {
  return fetchAndCacheUpdate(getLocalProjects, updateLocalProjects, getProjects);
}

export function fetchLanguages() {
  return fetchAndCache(getLocalLanguages, updateLocalLanguages, getLanguages);
}

export function fetchCountries() {
  return fetchAndCache(getLocalCountries, updateLocalCountries, getCountries);
}

export function fetchCategories() {
  return fetchAndCache(getLocalCategories, updateLocalCategories, getCategories);
}

export function fetchRates() {
  return fetchAndCache(getLocalRates, updateLocalRates, getRates);
}

export function fetchCurrencies() {
  return fetchAndCache(getLocalCurrencies, updateLocalCurrencies, getCurrencies);
}

export function fetchIndustries() {
  return fetchAndCache(getLocalIndustries, updateLocalIndustries, getIndustries);
}

export function fetchRoles() {
  return fetchAndCache(getLocalRoles, updateLocalRoles, getRoles);
}

export function fetchCompanySizes() {
  return fetchAndCache(getLocalCompanySizes, updateLocalCompanySizes, getCompanySizes);
}

function fetchAndCache(fetchLocalFunc, updateLocalFunc, fetchRemoteFunc) {
  return new Promise((resolve, reject) => {
    const results = fetchLocalFunc();
    if (!isEmpty(results)) {
      resolve(results);
    } else {
      fetchRemoteFunc().then(result => {
        updateLocalFunc(get(result, 'data.data'));
        resolve(fetchLocalFunc());
      });
    }
  });
}

function fetchAndCacheUpdate(fetchLocalFunc, updateLocalFunc, fetchRemoteFunc) {
  return new Promise((resolve, reject) => {
    fetchRemoteFunc().then(result => {
      updateLocalFunc(get(result, 'data.data'));
      resolve(fetchLocalFunc());
    });
  });
}
