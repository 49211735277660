import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    display: 'flex',
    flex: '1',
    width: '100%',
    alignItems: 'center',
  },
  headerText: {
    marginTop: theme.spacing.unit * 5,
  },
  linkText: {
    marginTop: theme.spacing.unit,
    textDecoration: 'none',
    color: '#000',
  },
  card: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    alignItems: 'flex-start',

    [theme.breakpoints.up('md')]: {
      width: theme.spacing.unit * 128,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    [theme.breakpoints.up('md')]: {
      width: theme.spacing.unit * 90,
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing.unit * 4}px)`,
    },
  },
});

function HeaderPageLinkBack(props) {
  const { classes, children } = props;
  return (
    <React.Fragment>
      <Paper className={classes.root} elevation={1}>
        <Card className={classes.card} elevation={0}>
          <CardContent>
            <Typography gutterBottom variant="subtitle2">
              <Link to="" onClick={props.history.goBack} className={classes.linkText}>
                &lt; Go back
              </Link>
            </Typography>
          </CardContent>
        </Card>
      </Paper>
      <div className={classes.contentContainer}>{children}</div>
    </React.Fragment>
  );
}

HeaderPageLinkBack.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(HeaderPageLinkBack));
