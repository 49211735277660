import React from 'react';
import { useState, useEffect } from 'react';

import { injectIntl, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';
import { withSnackbar } from 'notistack';
import Progress from './Progress';
import Chip from './Chip';
import InputLabel from '@material-ui/core/InputLabel';

const styles = theme => ({
  filepicker: {
    textAlign: 'center',
    padding: theme.spacing.unit,
    backgroundColor: '#E1E1E1',
    borderRadius: theme.spacing.unit,
    minHeight: 8 * theme.spacing.unit,
    border: '2px dashed #C7C7C7',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
  },
  root: {
    position: 'relative',
  },
  uploadingProgress: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    height: `calc(100% - ${theme.spacing.unit * 2}px)`,
    width: '100%',
    marginTop: 2 * theme.spacing.unit,
    borderRadius: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    zIndex: '9000',
  },
  uploadedFiles: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 2 * theme.spacing.unit,
    width: '100%',
  },
  uploadingFiles: {
    display: 'flex',
    height: 9 * theme.spacing.unit,
  },
  chip: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

function Icon(props) {
  return <div data-filetype={props.filetype} className="filepicker-file-icon" />;
}

function FileUploader(props) {
  const {
    classes,
    name,
    showFiletypeIcon,
    iconFiletypes,
    uploadPromise,
    files,
    onClick,
    enqueueSnackbar,
    intl,
  } = props;
  const [filesStatus, setFilesStatus] = useState(files.map(f => ({ ...f, status: 'uploaded' })));
  const icons =
    showFiletypeIcon && !files.length
      ? iconFiletypes.map(iconFiletype => <Icon filetype={iconFiletype} key={'icon-component' + uniqueId()} />)
      : [];

  useEffect(() => {
    setFilesStatus(files.map(f => ({ ...f, status: 'uploaded' })));
  }, [files]);

  function onDrop(acceptedFiles, rejectedFiles) {
    acceptedFiles.forEach(processAcceptedFiles);
  }
  function FileUploadIndicator() {
    if (filesStatus.filter(f => f.status === 'uploading').length > 0) {
      return (
        <div className={classes.uploadingProgress}>
          <Progress />
        </div>
      );
    }
    return null;
  }

  function processAcceptedFiles(file, index) {
    const data = new FormData();
    data.append('file', file);
    setFilesStatus(prevFiles => [...prevFiles, { name: file.name, status: 'uploading' }]);

    uploadPromise(data)
      .then(function(response) {
        setFilesStatus(prevFiles => {
          const updatePrevFiles = prevFiles.map(pf => {
            if (pf.name === file.name) {
              pf.status = 'uploaded';
              pf.id = response.data.data.files[0];
              pf.originalFileName = file.name;
            }
            return pf;
          });
          return updatePrevFiles;
        });
        const newIndex = index + files.length;
        onClick(`${name}[${newIndex}]`, { name: file.name, status: 'uploaded', id: response.data.data.files[0] }, true);
      })
      .catch(e => {
        setFilesStatus(prevFiles => {
          const updatePrevFiles = prevFiles.map(pf => {
            if (pf.name === file.name) {
              pf.status = 'failed';
            }
            return pf;
          });
          return updatePrevFiles;
        });
        const errorCode = get(e, 'response.status', 500);
        switch (errorCode) {
          case 400:
            // process messages :scream:
            const messages = get(e, 'response.data.messages', [{ file: '', message: 'error' }]);
            messages.forEach(message =>
              enqueueSnackbar(
                intl.formatMessage(
                  {
                    id: message.message,
                  },
                  { file: message.file },
                ),
              ),
            );
            break;
          case 413:
          case 415:
            enqueueSnackbar(
              intl.formatMessage({
                id: `error.${errorCode}`,
              }),
            );
            break;
          default:
            enqueueSnackbar(
              intl.formatMessage({
                id: 'error',
              }),
            );
            break;
        }
      });
  }

  return (
    <div className={classes.root}>
      <FileUploadIndicator />
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div>
              <div className={classes.uploadedFiles} />
              <div
                {...getRootProps()}
                className={classNames('dropzone', classes.filepicker, {
                  'dropzone--isActive': isDragActive,
                  'dropzone--loading': filesStatus.filter(f => f.status === 'uploading').length > 0,
                })}
              >
                <div className={classes.uploadedFiles}>
                  {filesStatus
                    .filter(f => f.status === 'uploaded')
                    .map(file => (
                      <Chip
                        label={file.originalFileName ? file.originalFileName : file.name}
                        clickable
                        className={classes.chip}
                        variant={'outlined'}
                        key={file.id}
                        coloured={false}
                        onDelete={e => {
                          onClick(name, files.filter(lclFile => lclFile.id !== file.id));
                          setFilesStatus(filesStatus.filter(lclFile => lclFile.id !== file.id));
                        }}
                      />
                    ))}
                </div>
                <input {...getInputProps()} />
                <div className={classes.icons}>{icons}</div>
                {isDragActive ? (
                  <FormattedMessage id="file-uploader.prose-hover" />
                ) : (
                  <FormattedMessage id="file-uploader.prose" />
                )}
              </div>
            </div>
          );
        }}
      </Dropzone>
      <InputLabel>
        <FormattedMessage id="file-uploader.size" />
      </InputLabel>
    </div>
  );
}

FileUploader.defaultProps = {
  files: [],
  iconFiletypes: [],
  showFiletypeIcon: false,
};

export default withStyles(styles)(injectIntl(withSnackbar(FileUploader)));
