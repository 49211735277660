import React, { useState, useEffect } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import ProjectStatusChip from './ProjectStatusChip';
import { TinyAvatar } from '../../SupplierProfile/SupplierAvatar';
import { fetchCurrencies } from '../../Api/FetchAndCache';
import SowComponent from './SowComponent';

const styles = theme => ({
  expansionRoot: {
    flexDirection: 'column',
  },
  summaryRoot: {
    // width: '100%',
  },
  summaryContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: 16,
    },
  },
  detailsRoot: {
    flexDirection: 'column',
  },
  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      width: '33%',
    },
  },
  costRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

function SowsComponent(props) {
  const { comms, classes } = props;
  const [expanded, setExpanded] = useState(null);
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    fetchCurrencies().then(result => {
      setCurrencies(result.map(r => ({ key: r.id, label: r.symbol })));
    });
  }, []);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      {comms
        .filter(c => !c.deleted_at)
        .map(c => {
          return (
            <ExpansionPanel
              expanded={expanded === c.id}
              onChange={handleChange(c.id)}
              key={c.id}
              classes={{
                root: classes.expansionRoot,
              }}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{ root: classes.summaryRoot, content: classes.summaryContent }}
              >
                <TinyAvatar item={c.supplier} />
                <Typography className={classes.heading}>{c.supplier.name}</Typography>
                <ProjectStatusChip status={c.status} />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={{ root: classes.detailsRoot }}>
                <SowComponent sow={c.sow} currencies={currencies} headerLink={`/sow/${c.id}`} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
    </>
  );
}

export default withStyles(styles)(SowsComponent);
