import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
  buttonLink: {
    // textDecoration: 'underline',
    padding: 0,
    margin: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

function ButtonLink(props) {
  const { classes, children, className, ...rest } = props;
  return (
    <Button className={classNames(className, classes.buttonLink)} {...rest}>
      {children}
    </Button>
  );
}

export default withStyles(styles)(ButtonLink);
