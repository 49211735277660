import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchRates } from 'Api/FetchAndCache';
import Chip from '../../FormFields/Chip';

function SkillsAndRates(props) {
  const { skills, currencySymbol, className } = props;
  const [rates, setRates] = useState([]);

  useEffect(() => {
    fetchRates().then((result) => setRates(result));
  }, []);

  return (
    <div className={className}>
      {skills
        .filter((s) => s.rate !== 0)
        .map((s) => {
          const foundRate = rates.find((r) => r.id === s.pivot.cost_ranges_id);
          const rate = foundRate ? `(${currencySymbol}${foundRate && foundRate.interval})` : '';
          return <Chip key={s.id} label={`${s.name} ${rate}`} />;
        })}
    </div>
  );
}

SkillsAndRates.propTypes = {
  skills: PropTypes.array.isRequired,
  currencySymbol: PropTypes.string.isRequired,
};
export default SkillsAndRates;
