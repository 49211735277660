import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import emailSent from '../assets/EmailSent/Email-Sent.png';
import emailSent15 from '../assets/EmailSent/Email-Sent@1.5x.png';
import emailSent20 from '../assets/EmailSent/Email-Sent@2x.png';
import { FormattedMessage } from 'react-intl';
import { withSnackbar } from 'notistack';
import axios from 'axios';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 6,
    width: theme.spacing.unit * 75,
    border: `1px solid ${theme.palette.grey[400]}`,
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    flexGrow: 1,
    display: 'flex',
  },
  card: {
    flexDirection: 'column',
    maxWidth: '100%',
    textAlign: 'center',
    alignItems: 'center',
    flexGrow: 1,
    display: 'flex',
    paddingTop: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 6,
  },
  cardContent: {
    maxWidth: theme.spacing.unit * 50,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    flexGrow: 1,
  },
  cardMedia: {
    height: theme.spacing.unit * 17,
    [theme.breakpoints.down('md')]: {
      height: theme.spacing.unit * 12,
    },
    backgroundSize: 'contain',
    width: 'auto',
  },
  cardActions: {
    flexDirection: 'column',
  },
});

function RegistrationSuccess(props) {
  const { classes, enqueueSnackbar } = props;
  const email = props.location.state.email;
  return (
    <Paper className={classes.root} elevation={0}>
      <Card className={classes.card}>
        <CardMedia
          component="img"
          className={classes.cardMedia}
          image={emailSent}
          srcSet={`${emailSent} 1x, ${emailSent15} 1.5x, ${emailSent20} 2x`}
        />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5">
            <FormattedMessage id="registration.confirmation.got-mail" />
          </Typography>
          <Typography gutterBottom variant="body2">
            <FormattedMessage
              id="registration.confirmation.email-sent-blurb"
              values={{
                email: email,
              }}
            />
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Typography variant="body2">
            <FormattedMessage id="registration.confirmation.resend-header" />
          </Typography>
          <Typography variant="body2">
            <a
              href="/api/resend-activation"
              onClick={e => {
                e.preventDefault();
                axios.post('/api/resend-activation', { email: email }).then(response => {
                  enqueueSnackbar(<FormattedMessage id="registration.confirmation.resend-resent" />);
                });
              }}
            >
              <FormattedMessage id="registration.confirmation.resend-link" />
            </a>
          </Typography>
        </CardActions>
      </Card>
    </Paper>
  );
}

export default withStyles(styles)(withSnackbar(RegistrationSuccess));
