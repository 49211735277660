import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { getProjects } from '../Api/Jobs';
import get from 'lodash/get';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import ProjectCard from './Components/ProjectCard';
import Progress from '../FormFields/Progress';
import { ReactComponent as NoResultsFoundImage } from '../assets/Jobs-Empty.svg';
import Card from '@material-ui/core/Card';
import { ProfileContext } from '../Profile/ProfileContext';
import { getUserType } from '../Api/LocalStorage';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
  },
  paperRoot: {
    flexDirection: 'column',
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit,
  },
  noResultsCard: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing.unit * 8,
    paddingBottom: theme.spacing.unit * 10,
  },
  body: {
    maxWidth: theme.spacing.unit * 51,
    textAlign: 'center',
  },
});

function ProjectsListWidget(props) {
  const { classes } = props;
  const [projects, setProjects] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [tabValue, setTabValue] = useState('active');
  const { userType } = useContext(ProfileContext);
  const doneStatus = 7;

  useEffect(() => {
    getProjects().then(resp => {
      setProjects([]);
      setProjects(get(resp, 'data.data', []));
      setFilteredProjects(get(resp, 'data.data', []).filter(p => p.status !== doneStatus));
    });
  }, []);

  function handleChange(e, v) {
    setTabValue(v);
    if (v === 'active') {
      setFilteredProjects(projects.filter(p => p.status !== doneStatus));
    } else {
      setFilteredProjects(projects.filter(p => p.status === doneStatus));
    }
  }

  function whichComponent() {
    if (projects === false) {
      return <Progress />;
    } else if (projects.length === 0) {
      return (
        <Card data-testid="no-search-results-page" className={classes.noResultsCard} elevation={0}>
          <NoResultsFoundImage />
          <Typography gutterBottom variant="h5" className={classes.header}>
            <FormattedMessage id="projects.list.no-result.header" />
          </Typography>
          <Typography gutterBottom variant="body2" className={classes.body}>
            <FormattedHTMLMessage id={`projects.list.no-result.body-${userType}`} />
          </Typography>
        </Card>
      );
    } else if (filteredProjects.length === 0) {
      return (
        <>
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab value="active" label={<FormattedMessage id="projects.list.active" />} />
            <Tab value="archive" label={<FormattedMessage id="projects.list.archive" />} />
          </Tabs>
          <Divider />
          <List>
            <Typography variant="h6" style={{ padding: 8 }}>
              <FormattedMessage id="projects.list.chips.none" />
            </Typography>
          </List>
        </>
      );
    } else {
      return (
        <>
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab value="active" label={<FormattedMessage id="projects.list.active" />} />
            <Tab value="archive" label={<FormattedMessage id="projects.list.archive" />} />
          </Tabs>
          <Divider />
          <List>
            {filteredProjects.map(project => (
              <ProjectCard key={project.id} item={project} />
            ))}
          </List>
        </>
      );
    }
  }

  return (
    <div className={classes.root}>
      <Typography variant="h5" color="inherit">
        <FormattedMessage id="projects.list.header" />
      </Typography>
      {getUserType() === 'supplier' &&
        <Typography variant="body1">
          <FormattedMessage id="projects.list.disclaimer" />
        </Typography>
      }
      <Paper className={classes.paperRoot}>{whichComponent()}</Paper>
    </div>
  );
}

export default withStyles(styles)(ProjectsListWidget);
