import React, { useState } from 'react';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import Divider from '../FormFields/Divider';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ProgressButton from '../FormFields/ProgressButton';
import TextInput from '../FormFields/TextInput';
import ChipRadioGroup from '../FormFields/ChipRadioGroup';
import PostCodeInput from '../FormFields/PostCodeInput';
import { Field } from 'formik';
import PhoneNumberInput from '../FormFields/PhoneNumberInput';
import Prompt from '../Prompt/Prompt';

const styles = (theme) => ({
  root: {
    '& > *': {
      marginBottom: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing.unit * 4,
      },
    },
  },
  smallerMarginBottom: {
    marginBottom: theme.spacing.unit * 2,
  },
  saveForLater: { justifyContent: 'center', display: 'flex' },
});

const ynpns = [
  { key: 1, label: 'yes' },
  { key: 2, label: 'no' },
  { key: 3, label: 'client-profile.pnts' },
];

function ClientProfileForm(props) {
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    isValid,
    handleChange,
    handleBlur,
    // handleSubmit,
    // handleReset,
    setFieldValue,
    classes,
    saveForLater,
    countries,
    categories,
    industries,
    roles,
    companySizes,
    forwardTo,
    intl,
  } = props;
  const [busy, setBusy] = useState(0);
  const asyncValidationControls = {
    onStart: () => {
      setBusy((prevVals) => ++prevVals);
    },
    onSuccess: () => {
      setBusy((prevVals) => --prevVals);
    },
    onFailure: () => {
      setBusy((prevVals) => --prevVals);
    },
  };

  return (
    <form onSubmit={props.handleSubmit} className={classes.root}>
      <Prompt when={dirty} message={() => intl.formatMessage({ id: 'prompt.unsaved' })} />
      <Typography variant="h6" color="inherit">
        <FormattedHTMLMessage id="client-profile.about-your-company" />
      </Typography>
      <Typography variant="body2" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedMessage id="required-fields.prose" />
      </Typography>
      <TextInput
        required
        fullWidth
        margin="normal"
        name="companyName"
        value={values.companyName}
        placeholder="client-profile.company-name.placeholder"
        label="client-profile.company-name.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        required
        fullWidth
        margin="normal"
        name="email"
        value={values.email}
        placeholder="client-profile.email.placeholder"
        label="client-profile.email.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <FormControl required variant="outlined" fullWidth>
        <InputLabel htmlFor="industry-select">
          <FormattedHTMLMessage id="client-profile.industry.label" />
        </InputLabel>
        <Select
          native
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
          value={values.industry}
          input={<OutlinedInput labelWidth={90} name="industry" id="industry-select" />}
        >
          <option value="" />
          {industries.map((industry) => (
            <option value={industry.id} key={industry.id}>
              {industry.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl required variant="outlined" fullWidth>
        <InputLabel htmlFor="roles-select">
          <FormattedHTMLMessage id="client-profile.roles.label" />
        </InputLabel>
        <Select
          native
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
          value={values.role}
          input={<OutlinedInput labelWidth={130} name="role" id="roles-select" />}
        >
          <option value="" />
          {roles.map((roles) => (
            <option value={roles.id} key={roles.id}>
              {roles.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl required variant="outlined" fullWidth>
        <InputLabel htmlFor="companySize-select">
          <FormattedHTMLMessage id="client-profile.company-size.label" />
        </InputLabel>
        <Select
          native
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
          value={values.companySize}
          input={<OutlinedInput labelWidth={85} name="companySize" id="companySize-select" />}
        >
          <option value="" />
          {companySizes.map((companySize) => (
            <option value={companySize.id} key={companySize.id}>
              {companySize.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <Divider />
      <Typography variant="h6" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedHTMLMessage id="client-profile.existing-lms-authoring-tools" />
      </Typography>
      <Typography variant="subtitle2" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedHTMLMessage id="client-profile.existing-lms" />*
      </Typography>
      <ChipRadioGroup
        required
        options={ynpns}
        name="platformsChoice"
        value={values.platformsChoice}
        onClick={setFieldValue}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      {values.platformsChoice === 1 && (
        <>
          <FormControl required variant="outlined" fullWidth>
            <InputLabel htmlFor="lms-select">
              <FormattedHTMLMessage id="client-profile.lms.label" />
            </InputLabel>
            <Select
              native
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              value={values.lms}
              input={<OutlinedInput labelWidth={250} name="lms" id="lms-select" />}
            >
              <option value="" />
              {categories
                .filter((c) => c.isPlatform)
                .map((lms) => (
                  <option value={lms.id} key={lms.id}>
                    {lms.name}
                  </option>
                ))}
            </Select>
          </FormControl>
          <FormControl required variant="outlined" fullWidth>
            <InputLabel htmlFor="lms-size-select">
              <FormattedHTMLMessage id="client-profile.learning-team.label" />
            </InputLabel>
            <Select
              native
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting}
              value={values.lmsSize}
              input={<OutlinedInput labelWidth={120} name="lmsSize" id="lms-size-select" />}
            >
              <option value="" />
              {companySizes.map((companySize) => (
                <option value={companySize.id} key={companySize.id}>
                  {companySize.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </>
      )}
      <Typography variant="subtitle2" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedHTMLMessage id="client-profile.existing-authoring-tools" />*
      </Typography>
      <ChipRadioGroup
        options={ynpns}
        name="toolsChoice"
        value={values.toolsChoice}
        onClick={setFieldValue}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      {values.toolsChoice === 1 && (
        <FormControl required variant="outlined" fullWidth>
          <InputLabel htmlFor="tool-select">
            <FormattedHTMLMessage id="client-profile.tool.label" />
          </InputLabel>
          <Select
            native
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
            value={values.tool}
            input={<OutlinedInput labelWidth={210} name="tool" id="tool-select" />}
          >
            <option value="" />
            {categories
              .filter((c) => c.isTool)
              .map((tool) => (
                <option value={tool.id} key={tool.id}>
                  {tool.name}
                </option>
              ))}
          </Select>
        </FormControl>
      )}
      <Divider />
      <Typography variant="h6" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedHTMLMessage id="supplier-profile.location" />
      </Typography>
      <TextInput
        required
        fullWidth
        margin="normal"
        name="address1"
        value={values.address1}
        placeholder="supplier-profile.address1.placeholder"
        label="supplier-profile.address1.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        fullWidth
        margin="normal"
        name="address2"
        value={values.address2}
        placeholder="supplier-profile.address2.placeholder"
        label="supplier-profile.address2.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        required
        fullWidth
        margin="normal"
        name="city"
        value={values.city}
        placeholder="supplier-profile.city.placeholder"
        label="supplier-profile.city.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <Field
        required
        component={PostCodeInput}
        country={values.country}
        fullWidth
        margin="normal"
        name="postalCode"
        value={values.postalCode}
        placeholder="supplier-profile.postcode.placeholder"
        label="supplier-profile.postcode.label"
        helperText={touched.postalCode && errors.postalCode && values.postalCode.length > 0 ? errors.postalCode : null}
        error={Boolean(touched.postalCode && errors.postalCode && values.postalCode.length > 0)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
        {...asyncValidationControls}
      />
      <Field
        required
        component={PhoneNumberInput}
        country={values.country}
        fullWidth
        margin="normal"
        name="phone"
        value={values.phone}
        placeholder="supplier-profile.telephone.placeholder"
        label="supplier-profile.telephone.label"
        helperText={touched.phone && errors.phone && values.phone.length > 0 ? errors.phone : null}
        error={Boolean(touched.phone && errors.phone && values.phone.length > 0)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
        {...asyncValidationControls}
      />
      <FormControl required variant="outlined" fullWidth>
        <InputLabel htmlFor="country-select">
          <FormattedHTMLMessage id="supplier-profile.country.label" />
        </InputLabel>
        <Select
          native
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
          value={values.country}
          input={<OutlinedInput labelWidth={50} name="country" id="country-select" />}
        >
          <option value="" />
          {countries.map((country) => (
            <option value={country.id} key={country.id}>
              {country.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <Divider />
      <Typography variant="h6" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedHTMLMessage id="client-profile.procurement.title" />
      </Typography>
      <Typography variant="body2" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedMessage id="client-profile.procurement.prose" />
      </Typography>
      <TextInput
        required
        fullWidth
        margin="normal"
        name="procurementName"
        value={values.procurementName}
        placeholder="client-profile.procurement-name.placeholder"
        label="client-profile.procurement-name.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        required
        fullWidth
        margin="normal"
        name="procurementEmail"
        value={values.procurementEmail}
        placeholder="client-profile.procurement-email.placeholder"
        label="client-profile.procurement-email.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        required
        fullWidth
        margin="normal"
        name="procurementJobTitle"
        value={values.procurementJobTitle}
        placeholder="client-profile.procurement-job-title.placeholder"
        label="client-profile.procurement-job-title.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />

      <Divider />

      <ProgressButton
        type="submit"
        buttonBusy={!!busy || isSubmitting}
        disabled={!!busy || !isValid}
        submitting={isSubmitting}
      >
        {props.intl.formatMessage({
          id: forwardTo ? 'client-profile.submit.text' : 'save',
        })}
      </ProgressButton>

      {forwardTo && (
        <a
          href="/"
          className={classes.saveForLater}
          onClick={(e) => {
            e.preventDefault();
            saveForLater(values);
          }}
        >
          <Typography variant="body2" color="inherit">
            <FormattedMessage id="supplier-profile.save-for-later" className={classes.saveForLater} />
          </Typography>
        </a>
      )}
    </form>
  );
}

export default withStyles(styles)(injectIntl(ClientProfileForm));
