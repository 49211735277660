import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const largeStyles = {
  root: {
    width: 112,
    height: 112,
  },
  text: {
    color: '#FFFFFF',
  },
};

const normalStyles = {
  root: {
    width: 80,
    height: 80,
  },
  text: {
    color: '#FFFFFF',
  },
};

const smallStyles = {
  root: {
    width: 48,
    height: 48,
  },
  text: {
    color: '#FFFFFF',
  },
};

const tinyStyles = {
  root: {
    width: 24,
    height: 24,
  },
  text: {
    color: '#FFFFFF',
  },
};

function ItemAvatar(props) {
  const { classes, item, size, className } = props;

  if (!get(item, `profilePic['${size}'].url`, false)) {
    return (
      <Avatar
        alt={item.name}
        style={{ backgroundColor: item.profileColor }}
        className={classNames(classes.root, className)}
      >
        <Typography variant="h4" className={classes.text}>
          {item.name.charAt(0)}
        </Typography>
      </Avatar>
    );
  } else {
    return <Avatar alt={item.name} src={item.profilePic[size].url} className={classNames(classes.root, className)} />;
  }
}

ItemAvatar.defaultProps = {
  size: '80x80',
};

ItemAvatar.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  size: PropTypes.string.isRequired,
};

function UnstyledLargeAvatar(props) {
  return <ItemAvatar size="112x112" {...props} />;
}

function UnstyledNormalAvatar(props) {
  return <ItemAvatar size="80x80" {...props} />;
}

function UnstyledSmallAvatar(props) {
  return <ItemAvatar size="48x48" {...props} />;
}

function UnstyledTinyAvatar(props) {
  return <ItemAvatar size="48x48" {...props} />;
}

export const TinyAvatar = withStyles(tinyStyles)(UnstyledTinyAvatar);
export const SmallAvatar = withStyles(smallStyles)(UnstyledSmallAvatar);
export const NormalAvatar = withStyles(normalStyles)(UnstyledNormalAvatar);
export const LargeAvatar = withStyles(largeStyles)(UnstyledLargeAvatar);
