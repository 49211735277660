import React from 'react';
import { useState, useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { injectIntl, FormattedMessage } from 'react-intl';
import AdditionalLanguageManager from './AdditionalLanguageManager';
import ChipRadioGroup from '../FormFields/ChipRadioGroup';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  smallerMarginBottom: {
    marginBottom: theme.spacing.unit * 2,
  },
});

const fluencies = [
  {
    label: 'languages.conversational',
    key: 0,
  },
  {
    label: 'languages.fluent',
    key: 1,
  },
  {
    label: 'languages.native',
    key: 2,
  },
];

function LanguageSelector(props) {
  const { classes, selectedLanguages, onClick, onBlur, disabled, required } = props;
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  const [englishFluency, setEnglishFluency] = useState(false);
  const [english, setEnglish] = useState({});

  useEffect(() => {
    const eng = selectedLanguages.find(value => {
      return value.selected === 1;
    });
    if (eng) {
      setEnglish(eng);
      setEnglishFluency(eng.level);
    }
    // if we've more than a single selected language then we know we need to show the language selector
    setShowLanguageSelector(selectedLanguages.length > 1);
  }, [selectedLanguages]);

  return (
    <div>
      <Typography variant="subtitle2" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedMessage id="supplier-profile.languages.header" />
        {required ? '*' : ''}
      </Typography>
      <ChipRadioGroup
        options={fluencies}
        onBlur={onBlur}
        disabled={disabled}
        name="english"
        value={englishFluency}
        onClick={(name, value) => {
          const nuEng = { ...english, selected: 1, level: value };
          setEnglishFluency(value);
          setEnglish(nuEng);
          onClick('languages', [nuEng, ...selectedLanguages.filter(sl => sl.selected > 1)], true);
        }}
      />
      <Typography variant="subtitle2" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedMessage id="supplier-profile.languages.other" />
        {required ? '*' : ''}
      </Typography>
      <ChipRadioGroup
        options={[{ label: 'yes', key: true }, { label: 'no', key: false }]}
        onBlur={onBlur}
        disabled={disabled || false === englishFluency}
        value={showLanguageSelector}
        onClick={(name, value) => {
          if (!value) {
            onClick('languages', [english], true);
          }
          setShowLanguageSelector(value);
        }}
      />
      {showLanguageSelector && (
        <AdditionalLanguageManager
          onClick={selectedLanguages => {
            onClick(
              'languages',
              [
                english,
                ...selectedLanguages.map(sl => {
                  return {
                    ...sl,
                    selected: Number(sl.selected),
                  };
                }),
              ],
              true,
            );
          }}
          onBlur={onBlur}
          disabled={disabled}
          selectedLanguages={selectedLanguages.filter(l => {
            return l.selected !== 1;
          })}
        />
      )}
    </div>
  );
}

LanguageSelector.defaultProps = {
  selectedLanguages: [],
};

export default withStyles(styles)(injectIntl(LanguageSelector));
