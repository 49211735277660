import React from 'react';
import TextField from '@material-ui/core/TextField';
import { injectIntl } from 'react-intl';
import { Field } from 'formik';

function TextInput(props) {
  const { name, ...rest } = props;
  return (
    <Field
      name={name}
      render={({ field }) => {
        return (
          <TextField
            {...field}
            {...rest}
            placeholder={props.intl.formatMessage({ id: props.placeholder })}
            label={props.label ? props.intl.formatMessage({ id: props.label }) : ''}
            helperText={props.helperText ? props.intl.formatMessage({ id: props.helperText }) : ''}
            variant="outlined"
          />
        );
      }}
    />
  );
}

export default injectIntl(TextInput);
