import React from 'react';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import has from 'lodash/has';
import queryString from 'query-string';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ResetConfirmForm from './ResetConfirmForm';
import { CLIENT_ID, CLIENT_SECRET } from '../env';
import { passwordResetConfirm } from '../Api/Authentication';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .min(8, 'forgotten-password.confirm.password.invalid')
    .required('forgotten-password.confirm.password.required'),
  confirmPassword: Yup.string()
    .trim()
    .required('forgotten-password.confirm.confirm-password.required')
    .oneOf([Yup.ref('password')], 'forgotten-password.confirm.confirm-password.error-match'),
});
const formValues = { confirmPassword: '', password: '' };

const styles = theme => ({
  root: {
    maxWidth: '1024px',
    alignItems: 'center',
  },
  paperForm: {
    display: 'flex',
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing.unit * 2,
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing.unit * 5,
      width: '600px',
    },
    flexDirection: 'column',
    border: '1px solid #CCCBC8',
    alignItems: 'center',
  },
  topHeader: {
    paddingBottom: theme.spacing.unit * 2,
    textAlign: 'center',
    paddingTop: theme.spacing.unit * 5,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing.unit * 12,
    },
  },
  midHeader: {
    paddingBottom: theme.spacing.unit * 4,
    textAlign: 'center',
  },
});

function ResetConfirm(props) {
  const { classes, enqueueSnackbar } = props;
  const [confirmationQuery, setConfirmationQuery] = useState({});

  useEffect(
    () => {
      let lclConfirmationQuery = {};
      const queryValues = queryString.parse(props.location.search);
      ['email', 'expires', 'signature'].forEach(key => {
        if (has(queryValues, key)) {
          lclConfirmationQuery[key] = queryValues[key];
        }
      });
      setConfirmationQuery(lclConfirmationQuery);
    },
    [props.location.search],
  );

  return (
    <div className={classes.root}>
      <Typography variant="h2" color="inherit" className={classes.topHeader}>
        <FormattedMessage id="forgotten-password.confirm.prose.header" />
      </Typography>

      <Paper className={classes.paperForm} elevation={0}>
        <Formik
          render={props => <ResetConfirmForm {...props} />}
          initialValues={formValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            const data = {
              client_id: CLIENT_ID,
              client_secret: CLIENT_SECRET,
              email: values.email,
              password: values.password,
              confirmPassword: values.confirmPassword,
            };
            passwordResetConfirm(queryString.stringify(confirmationQuery), data)
              .then(() => {
                enqueueSnackbar(<FormattedMessage id="forgotten-password.confirm.password.success" />);
                props.history.push({ pathname: '/login' });
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          }}
        />
      </Paper>
    </div>
  );
}

export default withStyles(styles)(withRouter(withSnackbar(ResetConfirm)));
