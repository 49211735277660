import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import QuickResponses from './QuickResponses';
import SearchIcon from '@material-ui/icons/Search';
import InputIcon from '@material-ui/icons/Input';
import FileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { injectIntl } from 'react-intl';
import RemoveSupplierModal, { useModal } from './RemoveSupplierModal';
import { Link } from 'react-router-dom';
import statuses from '../../Projects/ProjectStatuses';

const styles = theme => ({
  chip: {
    backgroundColor: 'inherit',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
});

function SupplierQuickResponses(props) {
  const { classes, intl, comms, processing /*, setProcessing*/ } = props;
  const [removeSupplierModalOpen, setRemoveSupplierModalOpen] = useModal(false);

  function leaveProject() {
    setRemoveSupplierModalOpen(true);
  }

  return (
    <QuickResponses>
      <RemoveSupplierModal
        modalOpen={removeSupplierModalOpen}
        onCancel={() => {
          setRemoveSupplierModalOpen(false);
        }}
        supplierId={comms.supplier.id}
        jobId={comms.job.id}
        whosDoingRemoving="supplier"
      />
      {comms.job.status >= statuses.pending_sow ? (
        <Chip
          clickable={!processing}
          icon={<FileIcon />}
          className={classes.chip}
          label={intl.formatMessage({
            id: 'communications.comms.quick-response.create-sow',
          })}
          component={Link}
          to={`/sow/${comms.id}`}
        />
      ) : null}
      <Chip
        clickable={!processing}
        icon={<InputIcon />}
        className={classes.chip}
        label={intl.formatMessage({
          id: 'communications.comms.quick-response.leave-project',
        })}
        onClick={leaveProject}
      />
      <Chip
        clickable={!processing}
        icon={<SearchIcon />}
        className={classes.chip}
        label={intl.formatMessage({
          id: 'communications.comms.quick-response.view-brief',
        })}
        component={Link}
        to={`/project/${comms.job_id}/brief`}
      />
    </QuickResponses>
  );
}

export default withStyles(styles)(injectIntl(SupplierQuickResponses));
