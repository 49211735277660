import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SenderMessage from './SenderMessage';
import ReceiverMessage from './ReceiverMessage';
import DividerWithText from './DividerWithText';
import ButtonLink from '../../FormFields/ButtonLink';
import { Typography } from '@material-ui/core';
import QuoteModal, { useModal } from './QuoteModal';
import { injectIntl, FormattedMessage } from 'react-intl';
import { fetchCurrencies } from '../../Api/FetchAndCache';

const styles = theme => ({
  buttonLink: {
    height: 'auto',
    minWidth: 'auto',
    textDecoration: 'underline',
    color: theme.palette.primary.dark,
    // fontSize: 'inherit',
    // fontWeight: 300,
  },
  buttonLinkText: {
    color: theme.palette.primary.dark,
  },
});

function MessageList(props) {
  const { classes, theirProfile, userProfile, userType, comms, showNewMessage, setShowNewMessage, intl } = props;
  const [quoteModalOpen, setQuoteModalOpen] = useModal();
  const [currencies, setCurrencies] = useState([]);

  const fetchData = () => {
    fetchCurrencies().then(result => {
      setCurrencies(result.map(r => ({ key: r.id, label: r.symbol })));
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <QuoteModal
        modalOpen={quoteModalOpen}
        onCancel={() => {
          setQuoteModalOpen(false);
        }}
        comms={comms}
        currencies={currencies}
      />
      {comms.messages.map((m, k) => {
        switch (m.type) {
          case 1:
            if (m.sender === userType) {
              return (
                <SenderMessage
                  key={m.id}
                  message={m}
                  senderProfile={userProfile}
                  newMessage={k === 0 && showNewMessage}
                  setNewMessageNeedsReading={setShowNewMessage}
                />
              );
            } else {
              return <ReceiverMessage key={m.id} message={m} senderProfile={theirProfile} />;
            }
          case 2:
            if (m.sender === userType) {
              return (
                <SenderMessage
                  key={m.id}
                  message={m}
                  senderProfile={userProfile}
                  newMessage={k === 0 && showNewMessage}
                  setNewMessageNeedsReading={setShowNewMessage}
                />
              );
            } else {
              return <ReceiverMessage key={m.id} message={m} senderProfile={theirProfile} />;
            }
          case 3:
            if (m.sender === userType) {
              return (
                <SenderMessage
                  key={m.id}
                  message={m}
                  senderProfile={userProfile}
                  newMessage={k === 0 && showNewMessage}
                  setNewMessageNeedsReading={setShowNewMessage}
                />
              );
            } else {
              return <ReceiverMessage key={m.id} message={m} senderProfile={theirProfile} />;
            }
          case 4:
            if (userType === 'client') {
              return (
                <DividerWithText
                  key={m.id}
                  message={intl.formatMessage({ id: 'communications.comms.quote.you-requested' })}
                  date={m.created_at}
                />
              );
            } else {
              const msg = (
                <>
                  <FormattedMessage
                    id="communications.comms.quote.they-requested"
                    values={{ name: theirProfile.name }}
                  />
                  &nbsp;
                  {comms.deleted_at ? null : (
                    <ButtonLink
                      className={classes.buttonLink}
                      onClick={() => {
                        setQuoteModalOpen(true);
                      }}
                    >
                      <Typography variant="body2" className={classes.buttonLinkText}>
                        <FormattedMessage id="communications.comms.quote.send" />
                      </Typography>
                    </ButtonLink>
                  )}
                </>
              );
              return <ReceiverMessage key={m.id} message={{ ...m, message: msg }} senderProfile={theirProfile} />;
            }
          case 5:
            if (userType === 'client') {
              const msg = (
                <FormattedMessage
                  id="communications.comms.quote.they-responded"
                  values={{
                    name: theirProfile.name,
                    quote: m.message,
                  }}
                />
              );
              return <ReceiverMessage key={m.id} message={{ ...m, message: msg }} senderProfile={theirProfile} />;
            } else {
              return (
                <DividerWithText
                  key={m.id}
                  message={intl.formatMessage({ id: 'communications.comms.quote.you-responded' })}
                  date={m.created_at}
                />
              );
            }

          case 6:
            let message = '';
            if (m.sender === 'client') {
              if (userType === 'client') {
                message = 'communications.comms.removed.by-client-you';
              } else {
                message = 'communications.comms.removed.by-supplier-them';
              }
            } else {
              if (userType === 'client') {
                message = 'communications.comms.removed.by-client-them';
              } else {
                message = 'communications.comms.removed.by-supplier-you';
              }
            }

            return (
              <DividerWithText
                key={m.id}
                message={intl.formatMessage({ id: message }, { reason: comms.reason })}
                date={m.created_at}
              />
            );
          default:
            return '';
        }
      })}
    </>
  );
}

MessageList.defaultProps = {
  newMessage: false,
};

export default withStyles(styles)(injectIntl(MessageList));
