import React from 'react';
import { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import { fetchCategories } from 'Api/FetchAndCache';
import ChipMenuList from './ChipMenuList';
import { SearchResultsContext } from '../SearchResultsContext';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1',
    width: '100%',
    alignItems: 'center',
  },
  card: {
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 128,
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      paddingBottom: theme.spacing.unit * 2,
    },
  },
  cardContent: {
    display: 'flex',
    flex: '1',
    height: theme.spacing.unit * 4,
  },
  select: {
    maxWidth: theme.spacing.unit * 64,
    position: 'absolute!important',
    [theme.breakpoints.down('xs')]: {
      width: `calc(100% - ${theme.spacing.unit * 4}px)`,
    },

    [theme.breakpoints.up('sm')]: {
      width: '30%',
    },
  },
});

function SearchResultsPageHeader(props) {
  const { classes, onChange, loading, ...rest } = props;
  const [availableSkills, setAvailableSkills] = useState([]);
  const { searchItem } = useContext(SearchResultsContext);

  const fetchData = () => {
    fetchCategories().then((result) => {
      setAvailableSkills(
        result
          .filter((category) => {
            return category.parent_id > 0 && !category.isTool && !category.isPlatform;
          })
          .map((category) => {
            return { value: category.id, label: category.name };
          }),
      );
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Paper className={classes.root} elevation={1}>
      <Card className={classes.card} elevation={0}>
        <CardContent className={classes.cardContent}>
          <Select
            className={classes.select}
            options={availableSkills}
            value={availableSkills.find((s) => s.value === searchItem.id)}
            name="searchbox"
            placeholder={<FormattedMessage id="client.find.search.box.label" />}
            onChange={(option) => {
              onChange(Number(option.value));
            }}
            isDisabled={loading}
          />
        </CardContent>
        <ChipMenuList {...rest} />
      </Card>
    </Paper>
  );
}

SearchResultsPageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default withStyles(styles)(SearchResultsPageHeader);
