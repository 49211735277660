import React from 'react';
import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import CompanyInformationForm from './CompanyInformationForm';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { updateCompany as updateSupplierCompany } from '../Api/SupplierProfile';
import { updateCompany as updateClientCompany } from '../Api/ClientProfile';
import { withRouter } from 'react-router';
import { withSnackbar } from 'notistack';
import get from 'lodash/get';
import { fetchSupplierProfile, fetchClientProfile } from '../Api/FetchAndCache';
import { getUserType, updateLocalProfile } from '../Api/LocalStorage';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  paperForm: {
    display: 'flex',
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing.unit * 2,
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing.unit * 5,
      width: '600px',
    },
    flexDirection: 'column',
    border: '1px solid #CCCBC8',
    alignItems: 'center',
  },
  topHeader: {
    paddingBottom: theme.spacing.unit * 2,
    textAlign: 'center',
    paddingTop: theme.spacing.unit * 5,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing.unit * 12,
    },
  },
  midHeader: {
    paddingBottom: theme.spacing.unit * 4,
    textAlign: 'center',
  },
});

function CompanyInformation(props) {
  const { classes, enqueueSnackbar } = props;
  const [values, setValues] = useState({
    companyName: '',
    companyNumber: '',
    vatNumber: '',
  });

  const fetchData = () => {
    (getUserType() === 'supplier' ? fetchSupplierProfile() : fetchClientProfile()).then(supplier => {
      setValues({
        companyName: get(supplier, 'company.name', ''),
        companyNumber: get(supplier, 'company.number', ''),
        vatNumber: get(supplier, 'company.vat', ''),
      });
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      <Formik
        render={props => <CompanyInformationForm {...props} />}
        initialValues={values}
        enableReinitialize
        onSubmit={(values, actions) => {
          const data = {
            name: values.companyName,
            number: values.companyNumber,
            vat: values.vatNumber,
          };
          (getUserType() === 'supplier' ? updateSupplierCompany(data) : updateClientCompany(data))
            .then(result => {
              updateLocalProfile(get(result, 'data.data'));
              enqueueSnackbar(<FormattedMessage id="company.success" />);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      />
    </div>
  );
}

export default withStyles(styles)(withRouter(withSnackbar(CompanyInformation)));
