import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import CreatableSelect from 'react-select/lib/Creatable';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import NoSsr from '@material-ui/core/NoSsr';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: '12px 10px 12px 10px',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chipRoot: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
  },
  chipLabel: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: theme.spacing.unit * 22,
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  paper: {
    position: 'absolute',
    zIndex: 9000,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    '&>div': {
      flex: 1,
    },
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      <FormattedMessage
        id={props.selectProps.maxSelected ? 'fields.auto-suggest.max-selected' : 'fields.auto-suggest.no-options'}
      />
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames({ [props.selectProps.classes.chipFocused]: props.isFocused })}
      classes={{ label: props.selectProps.classes.chipLabel, root: props.selectProps.classes.chipRoot }}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon data-testid="chip-delete-button" {...props.removeProps} />}
      color="primary"
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

function ChipSelect(props) {
  const { classes, theme, name, dataSource, storedValues, onChange, placeholder, selectTextFieldProps } = props;

  const handleChange = values => {
    onChange(name, values, true);
  };

  const selectStyles = {
    input: base => ({
      ...base,
      menuList: {
        flex: 1,
      },
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <div className={classes.divider} />
        <CreatableSelect
          classes={classes}
          styles={selectStyles}
          textFieldProps={selectTextFieldProps}
          options={dataSource}
          components={components}
          value={storedValues}
          onChange={handleChange}
          name={name}
          placeholder={<FormattedMessage id={placeholder} />}
          isMulti
          isClearable={false}
        />
      </NoSsr>
    </div>
  );
}

ChipSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

ChipSelect.defaultProps = {
  values: [],
  dataSource: [],
  onChange: () => {},
  placeholder: 'skills.select',
  selectTextFieldProps: {
    label: 'skills.select',
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  },
};
export default withStyles(styles, { withTheme: true })(ChipSelect);
