import React from 'react';
import { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import LoginForm from './LoginForm';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Hidden from '@material-ui/core/Hidden';
import { CLIENT_ID, CLIENT_SECRET } from '../env';
import has from 'lodash/has';
import queryString from 'query-string';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { withSnackbar } from 'notistack';
import { AuthenticationConsumer } from '../Authentication/AuthenticationContext';
import { authenticate } from '../Api/Authentication';
import { setUserType } from '../Api/LocalStorage';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('login.email.invalid')
    .required('login.email.required')
    .min(1),
  password: Yup.string()
    .trim()
    .min(8, 'login.password.invalid')
    .required('login.password.required'),
});
const defaultValues = { email: '', password: '', analyticsCookie: false };

const styles = theme => ({
  root: {
    maxWidth: '1024px',
    alignItems: 'center',
  },
  paperForm: {
    display: 'flex',
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing.unit * 2,
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing.unit * 5,
      width: '600px',
    },
    flexDirection: 'column',
    border: '1px solid #CCCBC8',
    alignItems: 'center',
  },
  topHeader: {
    paddingBottom: theme.spacing.unit * 2,
    textAlign: 'center',
    paddingTop: theme.spacing.unit * 5,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing.unit * 12,
    },
  },
  midHeader: {
    paddingBottom: theme.spacing.unit * 4,
    textAlign: 'center',
  },
});

function Login(props) {
  const { classes, enqueueSnackbar } = props;
  const [confirmationQuery, setConfirmationQuery] = useState({});

  useEffect(
    () => {
      let lclConfirmationQuery = {};
      const queryValues = queryString.parse(props.location.search);
      ['id', 'expires', 'signature'].forEach(key => {
        if (has(queryValues, key)) {
          lclConfirmationQuery[key] = queryValues[key];
        }
      });
      if (Object.values(lclConfirmationQuery).length > 0) {
        enqueueSnackbar(<FormattedMessage id="login.verify.helper" />);
      }
      setConfirmationQuery(lclConfirmationQuery);
    },
    [props.location.search],
  );

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <Typography variant="h2" color="inherit" className={classes.topHeader}>
          <FormattedMessage id="login.prose.header" />
        </Typography>
      </Hidden>
      <Hidden mdUp>
        <Typography variant="h4" color="inherit" className={classes.topHeader}>
          <FormattedMessage id="login.prose.header" />
        </Typography>
      </Hidden>
      <Paper className={classes.paperForm} elevation={0}>
        <AuthenticationConsumer>
          {({ login }) => (
            <Formik
              render={props => <LoginForm {...props} />}
              initialValues={defaultValues}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                const data = {
                  client_id: CLIENT_ID,
                  client_secret: CLIENT_SECRET,
                  email: values.email,
                  password: values.password,
                };
                authenticate(queryString.stringify(confirmationQuery), data)
                  .then(response => {
                    setUserType(
                      response.data.data.isClient ? 'client' : response.data.data.isSupplier ? 'supplier' : '',
                    );
                    login(response.data.data.access_token, data.email);
                  })
                  .finally(() => {
                    actions.setSubmitting(false);
                  });
              }}
            />
          )}
        </AuthenticationConsumer>
        <Typography variant="body2" color="inherit">
          <FormattedMessage
            id="login.noexisting-account.register"
            values={{
              link: (
                <Link to="/register">
                  <FormattedMessage id="signup" />
                </Link>
              ),
            }}
          />
        </Typography>
      </Paper>
    </div>
  );
}

Login.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
};

export default withStyles(styles)(withSnackbar(withCookies(Login)));
