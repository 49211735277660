import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SmallAvatar } from '../../SupplierProfile/SupplierAvatar';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ChatIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
import DeletedIcon from '@material-ui/icons/NotInterestedOutlined';
import IconButton from '@material-ui/core/IconButton';
import { Link } from 'react-router-dom';
import RemoveSupplierModal, { useModal } from '../../Comms/Components/RemoveSupplierModal';
import { Tooltip, Typography } from '@material-ui/core';

const styles = (theme) => ({
  supplierRow: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  avatar: {
    marginRight: theme.spacing.unit * 2,
  },
});

function ShortlistSupplierRow(props) {
  const { supplier, comm, classes, updateComms } = props;
  const [removeSupplierModalOpen, setRemoveSupplierModalOpen] = useModal(false);
  const [localComm] = useModal(comm);

  function removeSupplier() {
    setRemoveSupplierModalOpen(true);
  }

  return (
    <div className={classes.supplierRow} key={supplier.id}>
      <RemoveSupplierModal
        modalOpen={removeSupplierModalOpen}
        onCancel={() => {
          setRemoveSupplierModalOpen(false);
        }}
        onSubmit={(comm) => updateComms(comm)}
        supplierId={supplier.id}
        jobId={comm.job_id}
      />

      <div className={classes.titleSection}>
        <SmallAvatar item={supplier} className={classes.avatar} />
        <Link to={`/supplier/${supplier.id}`} style={{ textDecoration: 'none' }}>
          <Typography variant="body2">{supplier.name}</Typography>
        </Link>
      </div>
      <div className={classes.icons}>
        <IconButton aria-label="chat" component={Link} to={`/comms/${comm.id}`}>
          <ChatIcon />
        </IconButton>
        {localComm.deleted_at ? (
          <Tooltip title={comm.reason}>
            <DeletedIcon style={{ padding: 12, verticalAlign: 'middle', color: 'rgba(0, 0, 0, 0.54)' }} />
          </Tooltip>
        ) : (
          <IconButton aria-label="delete" onClick={removeSupplier}>
            <DeleteIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(ShortlistSupplierRow);
