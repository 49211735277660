import React, { useState } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';

import { Formik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import { withRouter } from 'react-router';

import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as PreparingDashboardImage } from '../assets/Preparing-Dashboard.svg';
import MSAValidationForm from './MSAValidationForm';
import { withSnackbar } from 'notistack';

import { updateSupplierProfile } from '../Api/SupplierProfile';
import { updateLocalProfile } from '../Api/LocalStorage';
import { transformProfileToValues } from '../SupplierProfile/SupplierProfile';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing.unit * 6,
    width: theme.spacing.unit * 85,
    border: `1px solid ${theme.palette.grey[400]}`,
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    flexGrow: 1,
    display: 'flex',
    paddingTop: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 6,
  },
  cardContent: {
    maxWidth: theme.spacing.unit * 70,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    flexGrow: 1,
    '& > *': {
      marginBottom: '0.75em',
    },
  },
  formContainer: {
    maxWidth: '1024px',
    '& > *': {
      marginBottom: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing.unit * 4,
      },
    },
  },
});

function MSAValidationPromptPage(props) {
  const { classes, enqueueSnackbar, name, file } = props;

  const [values, setValues] = useState({
    msaAgreed: false,
  });
  const validationSchema = Yup.object().shape({
    msaRead: Yup.bool().required('msa-validation.msa-agreed.label'),
    msaAgreed: Yup.bool().required('msa-validation.msa-agreed.label'),
  });

  return (
    <Paper className={classes.paper} elevation={0}>
      <Card className={classes.card}>
        <PreparingDashboardImage />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5">
            <FormattedMessage id="msa-validation.prompt.header" />
          </Typography>
          <Typography gutterBottom variant="body2">
            <FormattedMessage id="msa-validation.prompt.greeting" values={{ user: name ? name : 'user' }} />
          </Typography>
          <Typography gutterBottom variant="body2">
            <FormattedMessage id="msa-validation.prompt.update-message" />
          </Typography>

          <div className={classes.formContainer}>
            <Formik
              render={(props) => <MSAValidationForm {...props} file={file} />}
              initialValues={values}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values, actions) => {
                updateSupplierProfile({ msaAgreed: values.msaAgreed })
                  .then((response) => {
                    updateLocalProfile(get(response, 'data.data'));
                    setValues(transformProfileToValues(get(response, 'data.data')));
                    enqueueSnackbar(<FormattedMessage id="msa-validation.snack-bar.saved" />);
                    props.history.push('/');
                  })
                  .finally(() => {
                    actions.setSubmitting(false);
                  });
              }}
            />
          </div>

          <Typography gutterBottom variant="body2">
            <FormattedHTMLMessage id="msa-validation.prompt.more-info-blurb" values={{ mail: 'hello@jam-pan.com' }} />
          </Typography>
          <Typography gutterBottom variant="body2">
            <FormattedHTMLMessage id="msa-validation.prompt.sign-off" />
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default withStyles(styles)(withSnackbar(withRouter(MSAValidationPromptPage)));
