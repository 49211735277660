import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../assets/JP-logo.svg';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: theme.spacing.unit * 11,
  },
  toolbar: {
    maxWidth: theme.spacing.unit * 128,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  grow: {
    display: 'flex',
    flexGrow: 1,
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing.unit * 12,
    },
  },
});

function Header(props) {
  const { classes } = props;

  return (
    <AppBar position="static" color="inherit" elevation={1} className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Logo className={classes.logo} />
        <Hidden smDown>
          <div className={classes.grow} />
        </Hidden>
        {/* <div><Link to="/register/supplier">Click to register</Link></div> */}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
