import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
});

function QuickResponses(props) {
  const { classes, children } = props;

  return <div className={classes.root}>{children}</div>;
}

export default withStyles(styles)(QuickResponses);
