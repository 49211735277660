import axios from 'axios';
import queryString from 'query-string';

export function getNotifications(resultsPerPage = 5, page = 1) {
  return axios.get(
    `/api/notifications?${queryString.stringify({ page: page, perPage: resultsPerPage }, { arrayFormat: 'bracket' })}`,
  );
}

export function getUnreadNotifications() {
  return axios.get('/api/notifications/unread');
}
