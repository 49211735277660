import { isWeekend, isSaturday, addDays } from 'date-fns';

export const getDayAfterWeekend = (day) => {
  // It will retrieve the first day after a given day (weekends doesn't count)
  let firstMondayAfterWeekend = null;
  if (isWeekend(day)) {
    if (isSaturday(day)) {
      firstMondayAfterWeekend = addDays(day, 2);
    } else {
      firstMondayAfterWeekend = addDays(day, 1);
    }
  }
  return firstMondayAfterWeekend || day;
};
