import React from 'react';
import { useState, useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import ChipRadioGroup from '../FormFields/ChipRadioGroup';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  languageSelector: {
    width: '300px',
    marginBottom: theme.spacing.unit * 2,
  },
});

const fluencies = [
  {
    label: 'languages.conversational',
    key: 0,
  },
  {
    label: 'languages.fluent',
    key: 1,
  },
  {
    label: 'languages.native',
    key: 2,
  },
];

function AdditonalLanguageSelector(props) {
  const { classes, languages, language, onUpdate, handleRemoveClick, children } = props;
  const [optionedLanguages, setOptionedLanguages] = useState(languages);
  useEffect(
    () => {
      setOptionedLanguages([...languages, { id: language.selected, name: language.name }]);
    },
    [languages],
  );

  return (
    <div>
      <FormControl variant="outlined">
        <Select
          native
          value={language.selected}
          onChange={e => {
            onUpdate('selected', Number(e.target.value));
          }}
          inputProps={{
            name: 'language',
            id: 'language-select',
          }}
          className={classes.languageSelector}
        >
          {optionedLanguages.map(language => (
            <option value={language.id} key={language.id}>
              {language.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <Button onClick={handleRemoveClick} data-testid="language-selector-remove-button">
        Remove
      </Button>

      <ChipRadioGroup
        options={fluencies}
        value={language.level}
        onClick={(name, value) => {
          onUpdate('level', value);
        }}
      />
      {children}
    </div>
  );
}
export default withStyles(styles)(injectIntl(AdditonalLanguageSelector));
