import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ShortlistSupplierRow from './ShortlistSupplierRow';
import Typography from '@material-ui/core/Typography';
import Divider from '../../FormFields/Divider';
import ProgressButton from '../../FormFields/ProgressButton';
import { FormattedMessage } from 'react-intl';
import { assignJobToSuppliers } from '../../Api/Jobs';
import { statuses } from '../ProjectStatuses';
import { withSnackbar } from 'notistack';
import { injectIntl } from 'react-intl';

const styles = (theme) => ({
  divider: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
  shortlistButton: {
    marginBottom: theme.spacing.unit * 2,
  },
});
function ShortlistComponent(props) {
  const { classes, project, comms, setComms, enqueueSnackbar, intl } = props;
  const [processing, setProcessing] = useState(false);
  const [unavailableToAssign, setUnavailableToAssign] = useState(project.status >= statuses.pending_sow);

  function updateComms(newComm) {
    const newComms = comms.map((comm) => {
      if (comm.id === newComm.id) {
        return newComm;
      }
      return comm;
    });

    setComms(newComms);
  }

  return (
    <>
      <Typography variant="body2" color="textPrimary">
        <FormattedMessage id="projects.management-page.shortlist.prose" />
      </Typography>
      <Divider className={classes.divider} />
      {project.shortlist.suppliers
        .filter((supplier) => !comms.find((c) => c.supplier_id === supplier.id).deleted_at)
        .map((supplier) => {
          const comm = comms.find((c) => c.supplier_id === supplier.id);
          return <ShortlistSupplierRow supplier={supplier} comm={comm} updateComms={updateComms} key={supplier.id} />;
        })}
      <Divider />
      <ProgressButton
        className={classes.shortlistButton}
        submitting={processing}
        buttonBusy={processing}
        disabled={unavailableToAssign}
        onClick={() => {
          setProcessing(true);
          assignJobToSuppliers(project.id, {
            suppliers: project.shortlist.suppliers
              .filter((s) => !comms.find((c) => c.supplier_id === s.id).deleted_at)
              .map((s) => s.id),
          })
            .then((resp) => {
              setUnavailableToAssign(true);
              enqueueSnackbar(
                intl.formatMessage({
                  id: 'projects.management-page.shortlist.success',
                }),
              );
            })
            .finally(() => {
              setProcessing(false);
            });
        }}
      >
        <FormattedMessage id="projects.management-page.shortlist.button" />
      </ProgressButton>
    </>
  );
}

export default withStyles(styles)(withSnackbar(injectIntl(ShortlistComponent)));
