import React from 'react';
import classNames from 'classnames';
import { SmallAvatar, TinyAvatar } from '../../SupplierProfile/SupplierAvatar';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirecton: 'row',
    alignContent: 'center',
  },
  grid: {
    flexShrink: '1',
    marginLeft: theme.spacing.unit * 3,
    '& > *': {
      marginLeft: `-${theme.spacing.unit * 3}px`,
      border: '2px solid white',
    },
  },
  tinyGrid: {
    marginLeft: theme.spacing.unit * 2,
    '& > *': {
      marginLeft: `-${theme.spacing.unit * 2}px`,
    },
  },
});

function AvatarStack(props) {
  const { items, classes, size } = props;
  return (
    <div className={classes.root}>
      <Grid
        className={classNames(classes.grid, { [classes.tinyGrid]: size === 'tiny' })}
        container
        justify="flex-end"
        alignItems="center"
        direction="row-reverse"
      >
        {items.map(s => (size === 'tiny' ? <TinyAvatar item={s} key={s.id} /> : <SmallAvatar item={s} key={s.id} />))}
      </Grid>
      {/* ({items.length}) */}
    </div>
  );
}

AvatarStack.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['small', 'tiny']),
  items: PropTypes.array.isRequired,
};

AvatarStack.defaultProps = {
  items: [],
  size: 'small',
};

export default withStyles(styles)(AvatarStack);
