import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { format, endOfMonth, addMonths } from 'date-fns';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

function SupplierAvailabilityCalendar(props) {
  const { availability, setFieldValue } = props;

  const [isBlindMode, setIsBlindMode] = useState(false);

  const toggleEventAvailability = (data) => {
    const dateStr = data.event ? format(data.event.start, 'YYYY-MM-DD') : data.dateStr;
    setFieldValue(
      'availability',
      availability.map((event) =>
        event.start === dateStr
          ? {
              ...event,
              type: event.type === 'busy' ? 'available' : 'busy',
              color: event.type === 'busy' ? 'green' : '#ff0000cf',
              title: isBlindMode
                ? event.type === 'busy'
                  ? props.intl.formatMessage({ id: 'supplier-profile.available' })
                  : props.intl.formatMessage({ id: 'supplier-profile.busy' })
                : '',
            }
          : event,
      ),
    );
  };

  const changeEventsAvailability = (type) => {
    setFieldValue(
      'availability',
      availability.map((event) => ({
        ...event,
        type,
        color: type === 'busy' ? '#ff0000cf' : 'green',
        title: isBlindMode
          ? type === 'busy'
            ? props.intl.formatMessage({ id: 'supplier-profile.busy' })
            : props.intl.formatMessage({ id: 'supplier-profile.available' })
          : '',
      })),
    );
  };

  const changeEventsViewMode = () => {
    setIsBlindMode((prevBlindModeVal) => {
      setFieldValue(
        'availability',
        availability.map((event) => ({
          ...event,
          display: !prevBlindModeVal ? 'auto' : 'background',
          title: !prevBlindModeVal
            ? event.type === 'busy'
              ? props.intl.formatMessage({ id: 'supplier-profile.busy' })
              : props.intl.formatMessage({ id: 'supplier-profile.available' })
            : '',
        })),
      );
      return !isBlindMode;
    });
  };

  return (
    <FullCalendar
      plugins={[interactionPlugin, dayGridPlugin]}
      initialView="dayGridMonth"
      weekends={false}
      validRange={(nowDate) => {
        return { start: nowDate, end: endOfMonth(addMonths(nowDate, 3)) };
      }}
      events={availability}
      eventClick={isBlindMode ? toggleEventAvailability : undefined}
      dateClick={toggleEventAvailability}
      headerToolbar={{
        left: 'prev,next toggleBusy toggleAvailable toggleBlindMode',
        center: '',
        right: 'title',
      }}
      customButtons={{
        toggleBusy: {
          text: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FiberManualRecordIcon
                style={{
                  color: '#ff0000cf',
                }}
              />
              <span style={{ marginLeft: 4 }}>
                <FormattedMessage id="supplier-profile.availability.mark-busy" />
              </span>
            </div>
          ),
          click: () => changeEventsAvailability('busy'),
        },
        toggleAvailable: {
          text: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FiberManualRecordIcon
                style={{
                  color: 'green',
                }}
              />
              <span style={{ marginLeft: 4 }}>
                <FormattedMessage id="supplier-profile.availability.mark-available" />
              </span>
            </div>
          ),
          click: () => changeEventsAvailability('available'),
        },
        toggleBlindMode: {
          text: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginLeft: 4 }}>
                {isBlindMode
                  ? props.intl.formatMessage({ id: 'supplier-profile.availability.blind-mode.deactivate' })
                  : props.intl.formatMessage({ id: 'supplier-profile.availability.blind-mode.activate' })}
              </span>
            </div>
          ),
          click: () => {
            changeEventsViewMode();
          },
        },
      }}
    />
  );
}

export default injectIntl(SupplierAvailabilityCalendar);
