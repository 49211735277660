import axios from 'axios';

export function phoneNumberValidation(phoneNumber, countryCode) {
  const instance = axios.create();
  return instance.post('/api/validations/phone-number', { phone: phoneNumber, phone_country: countryCode });
}

export function postCodeValidation(postCode, countryCode) {
  const instance = axios.create();
  return instance.post('/api/validations/postal-code', { postalCode: postCode, postalCode_country: countryCode });
}
