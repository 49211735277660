import React, { useState, useEffect, useContext } from 'react';
import get from 'lodash/get';
import { getNotifications, getUnreadNotifications } from 'Api/Notifications';
import useInterval from '../Hooks/useInterval';
import { AuthenticationContext } from '../Authentication/AuthenticationContext';

function NotificationProvider(props) {
  const { children } = props;
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const { session } = useContext(AuthenticationContext);

  useEffect(() => {
    if (session.token) {
      setNotificationsLoading(true);
      intRetrieveNotifications();
    }
  }, [session.token]);

  const useNotifications = () => {
    useInterval(intRetrieveNotifications, notificationsLoading ? null : 60000);
  };

  const intRetrieveNotifications = async function () {
    const [unreadNotificationsResponse, notificationsResponse] = await Promise.all([
      getUnreadNotifications(),
      getNotifications(),
    ]);
    setUnreadNotifications(get(unreadNotificationsResponse, 'data.data.total', 0));
    setNotifications(get(notificationsResponse, 'data.data.data', []));

    const apiVersion = notificationsResponse.headers['x-api-version'];
    let userVersion = localStorage.getItem('jp-version');

    if (!userVersion) {
      localStorage.setItem('jp-version', apiVersion);
      userVersion = localStorage.getItem('jp-version');
    }

    if (userVersion && apiVersion !== userVersion) {
      window.location.replace('/logout');
    }

    setNotificationsLoading(false);
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications: notifications,
        unreadNotifications: unreadNotifications,
        useNotifications: useNotifications,
        notificationsLoading: notificationsLoading,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
}

NotificationProvider.defaultProps = {};

export const NotificationContext = React.createContext();
export const NotificationConsumer = NotificationContext.Consumer;
export default NotificationProvider;
