import React, { useState } from 'react';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import TextInput from '../FormFields/TextInput';
import LanguageSelector from '../Languages/LanguageSelector';
import ChipRadioGroup from '../FormFields/ChipRadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import LimitedLengthTextInput from '../FormFields/LimitedLengthTextInput';
import ProgressButton from '../FormFields/ProgressButton';
import PostCodeInput from '../FormFields/PostCodeInput';
import { Field } from 'formik';
import PhoneNumberInput from '../FormFields/PhoneNumberInput';
import Prompt from '../Prompt/Prompt';
import Avatar from '../FormFields/Avatar';
import Divider from '../FormFields/Divider';
import SupplierAvailabilityCalendar from './Components/SupplierAvailabilityCalendar';
import { endOfMonth, addMonths } from 'date-fns';

const styles = (theme) => ({
  root: {
    '& > *': {
      marginBottom: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing.unit * 4,
      },
    },
  },
  avatar: {
    marginRight: 24,
  },
  smallerMarginBottom: {
    marginBottom: theme.spacing.unit * 2,
  },
  saveForLater: { justifyContent: 'center', display: 'flex' },
});

const locationAvailability = [
  {
    label: 'yes',
    key: 0,
  },
  {
    label: 'no',
    key: 1,
  },
  {
    label: 'supplier-profile.remote-only',
    key: 2,
  },
];

// const workAvailability = [
//   {
//     label: 'supplier-profile.available',
//     key: 1,
//   },
//   {
//     label: 'supplier-profile.not-available',
//     key: 2,
//   },
//   {
//     label: 'supplier-profile.busy',
//     key: 3,
//   },
// ];
function SupplierProfileForm(props) {
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    isValid,
    handleChange,
    handleBlur,
    // handleSubmit,
    // handleReset,
    setFieldValue,
    classes,
    saveForLater,
    countries,
    currencies,
    forwardTo,
    msaFile,
    intl,
  } = props;
  const [busy, setBusy] = useState(0);
  const asyncValidationControls = {
    onStart: () => {
      setBusy((prevVals) => ++prevVals);
    },
    onSuccess: () => {
      setBusy((prevVals) => --prevVals);
    },
    onFailure: () => {
      setBusy((prevVals) => --prevVals);
    },
  };

  const getBeginMonthName = () => {
    if (values.availability.length > 0) {
      const date = new Date(values.availability[0].start);
      return date.toLocaleString('en-GB', { month: 'long' });
    }
    return new Date().toLocaleString('en-GB', { month: 'long' });
  };

  const getEndMonthName = () => {
    if (values.availability.length > 0) {
      const date = new Date(values.availability[values.availability.length - 1].start);
      return date.toLocaleString('en-GB', { month: 'long' });
    }
    const date = endOfMonth(addMonths(new Date(), 3));
    return date.toLocaleString('en-GB', { month: 'long' });
  };

  return (
    <form onSubmit={props.handleSubmit} className={classes.root}>
      <Prompt when={dirty} message={() => intl.formatMessage({ id: 'prompt.unsaved' })} />
      <Typography variant="h6" color="inherit">
        <FormattedHTMLMessage id="supplier-profile.about-you" />
      </Typography>
      <Typography variant="body2" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedMessage id="required-fields.prose" />
      </Typography>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div className={classes.avatar}>
          <Avatar profilePics={values.profilePics} />
        </div>
        <div>
          <Typography variant="subtitle2" color="inherit">
            <FormattedHTMLMessage id="supplier-profile.avatar.upload" />
          </Typography>
          <Typography variant="caption" color="inherit">
            <FormattedHTMLMessage id="supplier-profile.avatar.prose" />
          </Typography>
        </div>
      </div>
      <Typography variant="caption">
        <FormattedMessage id="supplier-profile.avatar.files" />
      </Typography>
      <TextInput
        fullWidth
        margin="normal"
        name="name"
        value={values.name}
        placeholder="supplier-profile.name.placeholder"
        label="supplier-profile.name.label"
        helperText={touched.name && errors.name && values.name.length > 0 ? errors.name : null}
        error={Boolean(touched.name && errors.name && values.name.length > 0)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting || !!values.name_old}
      />

      <TextInput
        fullWidth
        margin="normal"
        name="email"
        value={values.email}
        placeholder="supplier-profile.email.placeholder"
        label="supplier-profile.email.label"
        helperText={touched.email && errors.email && values.email.length > 0 ? errors.email : null}
        error={Boolean(touched.email && errors.email && values.email.length > 0)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />

      <LimitedLengthTextInput
        required
        name="bio"
        value={values.bio}
        placeholder="supplier-profile.bio.placeholder"
        label="supplier-profile.bio.label"
        helperText={'supplier-profile.bio.helper'}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
        multiline
        rows="6"
        maxLength={10000}
      />
      <TextInput
        fullWidth
        margin="normal"
        name="website"
        value={values.website}
        placeholder="supplier-profile.website.placeholder"
        label="supplier-profile.website.label"
        helperText={touched.website && errors.website && values.website.length > 0 ? errors.website : null}
        error={Boolean(touched.website && errors.website && values.website.length > 0)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <Typography variant="subtitle2" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedHTMLMessage id="supplier-profile.currency" />*
      </Typography>
      <ChipRadioGroup
        required
        options={currencies}
        name="currency"
        value={values.currency}
        onClick={setFieldValue}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <Divider />
      <Typography variant="h6" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedHTMLMessage id="supplier-profile.languages" />
      </Typography>
      <LanguageSelector
        required
        selectedLanguages={values.languages}
        onClick={setFieldValue}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <Divider />
      <Typography variant="h6" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedHTMLMessage id="supplier-profile.location" />
      </Typography>
      <TextInput
        required
        fullWidth
        margin="normal"
        name="address1"
        value={values.address1}
        placeholder="supplier-profile.address1.placeholder"
        label="supplier-profile.address1.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        fullWidth
        margin="normal"
        name="address2"
        value={values.address2}
        placeholder="supplier-profile.address2.placeholder"
        label="supplier-profile.address2.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        required
        fullWidth
        margin="normal"
        name="city"
        value={values.city}
        placeholder="supplier-profile.city.placeholder"
        label="supplier-profile.city.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <Field
        required
        component={PostCodeInput}
        country={values.country}
        fullWidth
        margin="normal"
        name="postalCode"
        value={values.postalCode}
        placeholder="supplier-profile.postcode.placeholder"
        label="supplier-profile.postcode.label"
        helperText={touched.postalCode && errors.postalCode && values.postalCode.length > 0 ? errors.postalCode : null}
        error={Boolean(touched.postalCode && errors.postalCode && values.postalCode.length > 0)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
        {...asyncValidationControls}
      />
      <Field
        required
        component={PhoneNumberInput}
        country={values.country}
        fullWidth
        margin="normal"
        name="phone"
        value={values.phone}
        placeholder="supplier-profile.telephone.placeholder"
        label="supplier-profile.telephone.label"
        helperText={touched.phone && errors.phone && values.phone.length > 0 ? errors.phone : null}
        error={Boolean(touched.phone && errors.phone && values.phone.length > 0)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
        {...asyncValidationControls}
      />
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="country-select">
          <FormattedHTMLMessage id="supplier-profile.country.label" />*
        </InputLabel>
        <Select
          required
          native
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
          value={values.country}
          input={<OutlinedInput labelWidth={70} name="country" id="country-select" />}
          className={classes.countrySelector}
        >
          <option value="" />
          {countries.map((country) => (
            <option value={country.id} key={country.id}>
              {country.name}
            </option>
          ))}
        </Select>
      </FormControl>
      <Typography variant="subtitle2" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedHTMLMessage id="supplier-profile.remote.header" />*
      </Typography>
      <ChipRadioGroup
        required
        options={locationAvailability}
        name="remote"
        value={values.remote}
        onClick={setFieldValue}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <Typography variant="caption" color="inherit">
        <FormattedHTMLMessage id="supplier-profile.remote.helper" />
      </Typography>
      <Divider />
      <Typography variant="h6" color="inherit">
        <FormattedHTMLMessage id="supplier-profile.availability" />
      </Typography>
      {values.availability.length && (
        <Typography variant="caption" color="inherit">
          <FormattedHTMLMessage
            id="supplier-profile.availability.hint"
            values={{
              beginMonth: getBeginMonthName(),
              endMonth: getEndMonthName(),
            }}
          />
        </Typography>
      )}
      <SupplierAvailabilityCalendar availability={values.availability} setFieldValue={setFieldValue} />
      <Typography variant="caption" color="inherit">
        <FormattedHTMLMessage id="supplier-profile.availability.helper" />
      </Typography>
      <Divider />
      <Typography variant="h6" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedHTMLMessage id="supplier-profile.msa" />
      </Typography>
      <Typography variant="caption" color="inherit">
        <FormattedMessage
          id="supplier-profile.msa.helper"
          values={{
            link: (
              <a href={msaFile} target="_blank" rel="noreferrer noopener">
                <FormattedMessage id="download" />
              </a>
            ),
          }}
        />
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!values.msaRead}
            name="msaRead"
            onChange={handleChange}
            onBlur={handleBlur}
            color="primary"
            variant="outlined"
          />
        }
        label={`${intl.formatMessage({ id: 'supplier-profile.msa-read.label' })}*`}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={!!values.msaAgreed}
            name="msaAgreed"
            onChange={handleChange}
            onBlur={handleBlur}
            color="primary"
            variant="outlined"
            disabled={!values.msaRead}
          />
        }
        label={`${intl.formatMessage({ id: 'supplier-profile.msa-agreed.label' })}*`}
      />
      <Divider />
      <ProgressButton
        type="submit"
        buttonBusy={!!busy || isSubmitting}
        disabled={!!busy || !isValid}
        submitting={isSubmitting}
        data-testid="submit-button"
      >
        {props.intl.formatMessage({
          id: forwardTo ? 'supplier-profile.submit.text' : 'save',
        })}
      </ProgressButton>

      {forwardTo && (
        <a
          href="/"
          className={classes.saveForLater}
          onClick={(e) => {
            e.preventDefault();
            saveForLater(values);
          }}
        >
          <Typography variant="body2" color="inherit">
            <FormattedMessage id="supplier-profile.save-for-later" className={classes.saveForLater} />
          </Typography>
        </a>
      )}
    </form>
  );
}

export default withStyles(styles)(injectIntl(SupplierProfileForm));
