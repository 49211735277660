import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as NotFoundPageImage } from '../assets/NotFoundPageImage.svg';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
  },
  paper: {
    marginTop: theme.spacing.unit * 6,
    width: theme.spacing.unit * 85,
    border: `1px solid ${theme.palette.grey[400]}`,
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    flexGrow: 1,
    display: 'flex',
  },
  card: {
    flexDirection: 'column',
    maxWidth: '100%',
    textAlign: 'center',
    alignItems: 'center',
    flexGrow: 1,
    display: 'flex',
    paddingTop: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 6,
  },
  image: {
    marginBottom: '0.75em',
  },
  cardContent: {
    maxWidth: theme.spacing.unit * 70,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    flexGrow: 1,
    '& > *': {
      marginBottom: '0.75em',
    },
  },
});

function NotFoundPage(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0}>
        <Card className={classes.card}>
          <NotFoundPageImage className={classes.image} />
          <CardContent className={classes.cardContent}>
            <Typography variant="h4">
              <FormattedMessage id="notfound.header" />
            </Typography>
            <Typography variant="body2">
              <FormattedHTMLMessage id="notfound.prose" values={{ mail: 'hello@jam-pan.com' }} />
              <a className={classNames('brand', 'no-underline')} href="mailto:hello@jam-pan.com">
                hello@jam-pan.com
              </a>
            </Typography>
            <Typography variant="body2">
              <a className={classNames('brand')} href="/dashboard">
                <FormattedMessage id="notfound.back-to-dashboard" />
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Paper>
    </div>
  );
}

export default withStyles(styles)(NotFoundPage);
