import React from 'react';
import { useState, useRef } from 'react';
import Chip from '@material-ui/core/Chip';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import RootRef from '@material-ui/core/RootRef';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    alignContent: 'space-around',
    paddingLeft: theme.spacing.unit * 2,
  },
  chipperChip: {
    marginRight: theme.spacing.unit * 2,
  },
  paper: {
    marginTop: theme.spacing.unit,
    paddingTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    maxWidth: theme.spacing.unit * 30,
  },
  popper: {
    zIndex: 9000,
  },
  menuList: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
  header: {
    display: 'block',
    width: '100%',
    marginBottom: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  menuChip: {
    marginBottom: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
});
function ChipMenu(props) {
  const { classes, chip, menuOptions, selectedFilters, onClick } = props;
  const [open, setOpen] = useState(false);
  const chipEl = useRef(null);

  return (
    <>
      <RootRef rootRef={chipEl}>
        <Chip
          className={classes.chipperChip}
          label={<FormattedMessage id={chip.label} />}
          variant={open ? 'default' : 'outlined'}
          onClick={e => {
            setOpen(!open);
          }}
          color="primary"
        />
      </RootRef>

      <Popper
        className={classes.popper}
        anchorEl={chipEl.current}
        placement="bottom-start"
        open={open}
        disablePortal
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} id="menu-list-grow">
            <ClickAwayListener
              onClickAway={e => {
                if (chipEl.current.contains(e.target)) {
                  return;
                }
                if (open) {
                  setOpen(false);
                }
              }}
            >
              <Paper className={classes.paper}>
                <MenuList className={classes.menuList}>
                  {menuOptions.map(m => (
                    <div key={m.label}>
                      <Typography variant="h6" color="inherit" className={classes.header}>
                        <FormattedMessage id={m.label} />
                      </Typography>
                      {m.options.map(o => (
                        <Chip
                          label={o.label}
                          key={o.key}
                          className={classes.menuChip}
                          variant={
                            selectedFilters[m.key] && selectedFilters[m.key].includes(o.key) ? 'default' : 'outlined'
                          }
                          color="primary"
                          onClick={() => {
                            onClick(m.key, o.key);
                          }}
                        />
                      ))}
                    </div>
                  ))}
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default withStyles(styles)(ChipMenu);
