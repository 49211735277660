import React, { useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { FieldArray } from 'formik';

import { withStyles } from '@material-ui/core/styles';
import Divider from '../FormFields/Divider';
import Typography from '@material-ui/core/Typography';
import TextInput from '../FormFields/TextInput';
import ChipAutoSuggest from '../FormFields/ChipAutoSuggest';
import ChipCheckboxGroup from '../FormFields/ChipCheckboxGroup';
import FileUploader from '../FormFields/FileUploader';
import { fileUploads } from '../Api/SupplierProfile';
import ProgressButton from '../FormFields/ProgressButton';

import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = (theme) => ({
  root: {
    '& > *': {
      marginBottom: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing.unit * 4,
      },
    },
  },
  smallerMarginBottom: {
    marginBottom: theme.spacing.unit * 2,
  },
  saveForLater: { justifyContent: 'center', display: 'flex' },

  rootPaper: {
    marginTop: theme.spacing.unit * 5,
  },
  skillsPaper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
  },
  listRootSkillsPaperHeader: {
    marginTop: '40px',
  },
  rateHeader: {
    marginBottom: theme.spacing.unit,
  },
  selectFormControl: {
    marginTop: theme.spacing.unit * 2,
  },
  divider: {
    margin: `-${theme.spacing.unit * 3}px`,
    marginTop: theme.spacing.unit * 2,
    marginBottom: 0,
  },
});

function SkillsAndProjectsForm(props) {
  const {
    values,
    touched,
    errors,
    // dirty,
    isSubmitting,
    isValid,
    handleChange,
    handleBlur,
    // handleSubmit,
    // handleReset,
    setFieldValue,
    classes,
    saveForLater,
    availableSkills,
    rates,
    profileCurrency,
    intl,
  } = props;

  const [skillsWithRates, setSkillsWithRates] = useState([]);

  useEffect(() => {
    setSkillsWithRates(values.skills.filter((skill) => skill.rate && skill.rate !== 0));
  }, [values.skills]);

  return (
    <form onSubmit={props.handleSubmit} className={classes.root}>
      <Typography variant="overline" color="primary" className={classes.topHeader}>
        <FormattedMessage id="supplier-profile.step2.step" />
      </Typography>
      <Typography variant="h4" color="inherit" className={classes.topHeader}>
        <FormattedMessage id="supplier-profile.step2.header" />
      </Typography>
      <Typography variant="body2" color="inherit" className={classes.midHeader}>
        <FormattedMessage id="supplier-profile.step2.prose" />
      </Typography>
      <Typography variant="body2" color="inherit" className={classes.smallerMarginBottom}>
        <FormattedMessage id="required-fields.prose" />
      </Typography>
      <Divider />
      <Typography variant="h6" color="inherit" className={classes.topHeader}>
        <FormattedMessage id="supplier-profile.step2.choose-skills" />
      </Typography>
      <Typography variant="body2" color="inherit" className={classes.midHeader}>
        <FormattedMessage id="supplier-profile.step2.choose-skills.prose" />
      </Typography>
      <ChipAutoSuggest
        fullWidth
        fullWidthInput
        variant="outlined"
        InputProps={{ name: 'skills', color: 'primary' }}
        name="skills"
        placeholder="supplier-profile.skills.placeholder"
        label={<FormattedMessage id="supplier-profile.skills.label" />}
        // onBlur={handleBlur}
        disabled={isSubmitting}
        dataSource={availableSkills}
        storedValues={values.skills}
        maxChips={availableSkills.length}
        onChange={setFieldValue}
        selectTextFieldProps={{
          label: `${intl.formatMessage({ id: 'skills.select' })}*`,
          variant: 'outlined',
          InputLabelProps: {
            shrink: true,
          },
        }}
      />
      <FieldArray
        name="skills"
        render={(arrayHelpers) => (
          <div>
            <Typography variant="h6" color="inherit" className={classes.topHeader}>
              <FormattedMessage id="supplier-profile.step2.choose-rates" />
            </Typography>
            {values.skills.map((skill, index) => (
              <div key={index} className={styles.rootPaper}>
                <Paper className={classes.skillsPaper} elevation={1}>
                  <Typography variant="h6" color="inherit" className={classes.rateHeader}>
                    {skill.label}
                  </Typography>
                  <FormControl variant="outlined" className={classes.selectFormControl}>
                    <InputLabel htmlFor="rate">
                      <FormattedMessage id={'rates.range'} />
                    </InputLabel>
                    <Select
                      name={`skills[${index}].rate`}
                      native
                      value={skill.rate || 0}
                      fullWidth
                      input={<OutlinedInput labelWidth={145} name="rate" id="rate" />}
                      onChange={(e) => setFieldValue(`skills[${index}].rate`, e.target.value)}
                    >
                      <option value="" />
                      {rates.map((r) => {
                        return (
                          <option value={r.id} key={r.id}>
                            {profileCurrency} {r.interval}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Paper>
              </div>
            ))}
          </div>
        )}
      />
      <Divider />
      <Typography variant="h6" color="inherit" className={classes.topHeader}>
        <FormattedMessage id="supplier-profile.step2.add-project" />
      </Typography>
      <Typography variant="body2" color="inherit" className={classes.topHeader}>
        <FormattedMessage id="supplier-profile.step2.add-project.prose" />
      </Typography>
      <TextInput
        required
        fullWidth
        margin="normal"
        name="projectTitle"
        value={values.projectTitle}
        placeholder="supplier-profile.project-title.placeholder"
        label="supplier-profile.project-title.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        required
        fullWidth
        margin="normal"
        name="projectBio"
        value={values.projectBio}
        placeholder="supplier-profile.project-bio.placeholder"
        label="supplier-profile.project-bio.label"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
        multiline
        rows="4"
      />
      <TextInput
        fullWidth
        margin="normal"
        name="projectUrl"
        value={values.projectUrl}
        placeholder="supplier-profile.project-url.placeholder"
        label="supplier-profile.project-url.label"
        helperText={
          touched.projectUrl && errors.projectUrl && values.projectUrl.length > 0
            ? errors.projectUrl
            : 'supplier-profile.project-url.helper'
        }
        error={Boolean(touched.projectUrl && errors.projectUrl && values.projectUrl.length > 0)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <FileUploader
        name="files"
        showFiletypeIcon
        iconFiletypes={['jpg', 'png', 'pdf']}
        uploadPromise={fileUploads}
        files={values.files}
        onClick={setFieldValue}
      />
      <Typography variant="h6" color="inherit" className={classes.topHeader}>
        <FormattedMessage id="supplier-profile.step2.skills-used" />*
      </Typography>
      <ChipCheckboxGroup
        name="projectSkills"
        options={skillsWithRates}
        storedValues={values.projectSkills}
        onClick={setFieldValue}
      />
      <Divider />

      <ProgressButton
        type="submit"
        submitting={isSubmitting}
        buttonBusy={isSubmitting}
        disabled={!isValid}
        data-testid="submit-button"
      >
        {props.intl.formatMessage({
          id: 'supplier-profile.step2.submit.text',
        })}
      </ProgressButton>
      <a
        href="/"
        className={classes.saveForLater}
        onClick={(e) => {
          e.preventDefault();
          saveForLater(values);
        }}
      >
        <Typography variant="body2" color="inherit">
          <FormattedMessage id="supplier-profile.save-for-later" className={classes.saveForLater} />
        </Typography>
      </a>
    </form>
  );
}

export default withStyles(styles)(injectIntl(SkillsAndProjectsForm));
