import axios from 'axios';
import queryString from 'query-string';

export function getPreviousSuppliers() {
  return axios.get('/api/client/suppliers/previous');
}

export function getRecommendedSuppliers() {
  return axios.get('/api/client/recommendations');
}
export function getSearchedSuppliers(skillId, page, resultsPerPage, filters) {
  return axios.get(
    `/api/search?${queryString.stringify(
      { skill: skillId, page: page, resultsPerPage: resultsPerPage, ...filters },
      { arrayFormat: 'bracket' },
    )}`,
  );
}
