import React, { useContext, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextInput from '../../FormFields/TextInput';
import { Card, CardContent, CardActions } from '@material-ui/core';
import { Formik } from 'formik';
import { SmallAvatar } from '../../SupplierProfile/SupplierAvatar';
import ClientQuickResponses from './ClientQuickResponses';
import SupplierQuickResponses from './SupplierQuickResponses';
import { sendCommMessage } from '../../Api/Comms';
import * as Yup from 'yup';
import ProgressButton from '../../FormFields/ProgressButton';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { CommsContext } from '../CommsContext';
import MessageFileUploader from '../../FormFields/MessageFileUploader';

const styles = (theme) => ({
  root: {
    flexDirection: 'column',
    display: 'flex',
  },
  formRow: {
    flexDirection: 'row',
    display: 'flex',
  },
  textInput: {
    flexGrow: 1,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  progressButton: {
    marginTop: 0,
    alignSelf: 'end',
  },
});

const validationSchema = Yup.object().shape({
  message: Yup.string().trim().required(),
});

function MessageBox(props) {
  const { classes, userProfile, userType, setMessages } = props;
  const { comms } = useContext(CommsContext);
  const [processing, setProcessing] = useState(false);
  const [files, setFiles] = useState([]);

  const handleChange = (e) => {
    setFiles([...files, ...e.target.files]);
  };

  const handleDelete = (i) => {
    setFiles([...files.slice(0, i), ...files.slice(i + 1)]);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <Formik
          onSubmit={(values, actions) => {
            setProcessing(true);
            const formData = new FormData();
            formData.append('message', values.message);
            [...files].map((file, i) => formData.append(`file${i}`, file));
            sendCommMessage(comms.id, formData)
              .then((resp) => {
                actions.resetForm({ message: '' });
                setMessages(get(resp, 'data.data'));
                setFiles([]);
              })
              .finally(() => {
                setProcessing(false);
                actions.setSubmitting(false);
              });
          }}
          initialValues={{ message: '' }}
          validationSchema={validationSchema}
          render={(props) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <div className={classes.formRow}>
                  <SmallAvatar item={userProfile} />
                  <TextInput
                    fullWidth
                    inputProps={{ maxLength: 10000 }}
                    placeholder="communications.comms.message-box.placeholder"
                    name="message"
                    disabled={props.isSubmitting || processing}
                    value={props.values.message}
                    onChange={props.handleChange}
                    className={classes.textInput}
                    multiline
                    rows="4"
                    autoComplete="off"
                  />
                  <ProgressButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!props.isValid || processing}
                    submitting={props.isSubmitting}
                    buttonBusy={props.isSubmitting}
                    className={classes.progressButton}
                    fullWidth={false}
                  >
                    <FormattedMessage id="communications.comms.message-button.send" />
                  </ProgressButton>
                </div>
                <MessageFileUploader
                  name="files"
                  disabled={props.isSubmitting || processing}
                  files={files}
                  onChange={handleChange}
                  onDelete={handleDelete}
                />
              </form>
            );
          }}
        />
      </CardContent>
      <CardActions>
        {userType === 'client' ? (
          <ClientQuickResponses comms={comms} processing={processing} setProcessing={setProcessing} />
        ) : (
          <SupplierQuickResponses comms={comms} processing={processing} setProcessing={setProcessing} />
        )}
      </CardActions>
    </Card>
  );
}

export default withStyles(styles)(MessageBox);
