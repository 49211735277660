import React, { useContext } from 'react';
import Chip from '../../FormFields/Chip';
import { FormattedMessage } from 'react-intl';
import Divider from '../../FormFields/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { ProfileContext } from '../../Profile/ProfileContext';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    flex: 1,
    '& > *': {
      marginBottom: 16,
    },
  },
  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      width: '33%',
    },
  },
  costRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

function SowComponent(props) {
  const { sow, currencies, classes, className, headerLink } = props;
  const { userType } = useContext(ProfileContext);

  if (!sow || (userType === 'client' && sow.version === 1)) {
    return (
      <Typography variant="h6" style={{ marginTop: 16, marginBottom: 16 }}>
        <FormattedMessage id="projects.management-page.sow.no-sow-client" />
      </Typography>
    );
  } else if (!sow || (userType === 'supplier' && !sow.deadline)) {
    return (
      <Typography variant="h6" style={{ marginTop: 16, marginBottom: 16 }}>
        <FormattedMessage id="projects.management-page.sow.no-sow-supplier" />
        {headerLink ? (
          <Link to={headerLink}>
            <FormattedMessage id="projects.management-page.sow.create" />
          </Link>
        ) : null}
      </Typography>
    );
  } else {
    const cur = currencies.find(cur => cur.key === Number(sow.currency));
    const currency = cur ? cur.label : '';
    return (
      <div className={classNames(classes.root, className)}>
        {headerLink ? (
          <Link to={headerLink} style={{ textDecoration: 'none' }}>
            <Typography variant="h6">{sow.title}</Typography>
          </Link>
        ) : (
          <Typography variant="h6">{sow.title}</Typography>
        )}
        <Typography variant="body2">{sow.background}</Typography>

        <div className={classes.detailsRow}>
          <Typography variant="caption">
            <FormattedMessage id="projects.management-page.sow.startdate" />
          </Typography>
          <Typography variant="caption">
            <FormattedMessage id="projects.management-page.sow.enddate" />
          </Typography>
          {sow.quoteType === 2 ? (
            <Typography variant="caption">
              <FormattedMessage id="projects.management-page.sow.dayrate" />
            </Typography>
          ) : null}
        </div>
        <div className={classes.detailsRow}>
          <Typography variant="body2">{sow.startDate}</Typography>
          <Typography variant="body2">{sow.deadline}</Typography>
          {sow.quoteType === 2 ? (
            <Typography variant="body2">
              {currency}
              {sow.cost}
            </Typography>
          ) : null}
        </div>
        <Typography variant="caption">
          <FormattedMessage id="projects.management-page.sow.billing-schedule" />
        </Typography>
        <Typography variant="body2">
          {sow.quoteType === 1 ? (
            <FormattedMessage id="projects.management-page.sow.quote-milestone" />
          ) : (
            <FormattedMessage id="projects.management-page.sow.quote-days" />
          )}
        </Typography>
        <Typography variant="h6">
          <FormattedMessage id="projects.management-page.sow.deliverables" />
        </Typography>
        <div>
          {get(sow, 'deliverables', []).map(d => (
            <Chip key={d.id} label={d.value} />
          ))}
        </div>
        <Typography variant="h6">
          <FormattedMessage id="projects.management-page.sow.invoicing-milestones" />
        </Typography>
        <Milestone milestones={get(sow, 'milestones', [])} quoteType={sow.quoteType} currency={currency} />
        <CostSection classes={classes} sow={sow} currency={currency} />
      </div>
    );
  }
}

function CostSection(props) {
  const { classes, sow, currency } = props;
  let grossCost = 0;
  if (sow.quoteType === 1) {
    grossCost = get(sow, 'milestones', []).reduce(
      (accumulator, milestone) => Number(accumulator) + Number(milestone.cost),
      0,
    );
  } else {
    grossCost =
      Number(sow.cost) *
      get(sow, 'milestones', []).reduce((accumulator, milestone) => Number(accumulator) + Number(milestone.days), 0);
  }
  const jpFee = grossCost * 0.18;
  const netCost = grossCost - grossCost * 0.18;

  return (
    <>
      <Divider className={classes.divider} />
      <div className={classes.costRow}>
        <Typography variant="body2">
          <FormattedMessage id="projects.sow.costs.gross-cost" />
        </Typography>
        <Typography variant="body2">
          {currency}
          {grossCost}
        </Typography>
      </div>
      <div className={classes.costRow}>
        <Typography variant="body2">
          <FormattedMessage id="projects.sow.costs.jp-fee" />
        </Typography>
        <Typography variant="body2">
          {currency}
          {jpFee}
        </Typography>
      </div>
      <Typography variant="caption" style={{ textAlign: 'end' }}>
        <FormattedMessage id="projects.sow.costs.jp-fee.vat" />
      </Typography>
      <div className={classes.costRow}>
        <Typography variant="body2" style={{ display: 'flex', alignSelf: 'flex-end' }}>
          <FormattedMessage id="projects.sow.costs.net-cost" />
        </Typography>
        <Typography variant="h4">
          {currency}
          {netCost}
        </Typography>
      </div>
      <Typography variant="caption" style={{ textAlign: 'end' }}>
        <FormattedMessage id="projects.sow.costs.net-cost.vat" />
      </Typography>
    </>
  );
}

function Milestone(props) {
  const { milestones, quoteType, currency } = props;

  if (quoteType === 1) {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="body2" style={{ flexBasis: '30%' }}>
            <FormattedMessage id="projects.management-page.sow.milestone" />
          </Typography>
          <Typography variant="body2" style={{ flexBasis: '30%' }}>
            <FormattedMessage id="projects.management-page.sow.cost" />
          </Typography>
          <Typography variant="body2" style={{ flexBasis: '30%' }}>
            <FormattedMessage id="projects.management-page.sow.deadline" />
          </Typography>
        </div>
        {milestones.map(m => (
          <div key={m.id} style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="body2" style={{ flexBasis: '30%' }}>
              {m.value}
            </Typography>
            <Typography variant="body2" style={{ flexBasis: '30%' }}>
              {currency}
              {m.cost}
            </Typography>
            <Typography variant="body2" style={{ flexBasis: '30%' }}>
              {m.deadline}
            </Typography>
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant="body2" style={{ flexBasis: '50%' }}>
            <FormattedMessage id="projects.management-page.sow.milestone" />
          </Typography>
          <Typography variant="body2" style={{ flexBasis: '50%' }}>
            <FormattedMessage id="projects.management-page.sow.no-days" />
          </Typography>
        </div>
        {milestones.map(m => (
          <div key={m.id} style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography variant="body2" style={{ flexBasis: '50%' }}>
              {m.value}
            </Typography>
            <Typography variant="body2" style={{ flexBasis: '50%' }}>
              {m.days}
            </Typography>
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(SowComponent);
