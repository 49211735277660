import React from 'react';
import { useState } from 'react';
import { withCookies } from 'react-cookie';
import axios from 'axios';

function AuthenticationProvider(props) {
  const { children, cookies } = props;
  const [session, setSession] = useState({ token: cookies.get('auth-token'), email: cookies.get('auth-email') });

  if (session.token) {
    axios.defaults.headers.common['authorization'] = `Bearer ${session.token}`;
  }

  function logout() {
    cookies.remove('auth-token');
    cookies.remove('auth-email');
    setSession({ token: false, email: false });
    localStorage.removeItem('profile');
    localStorage.removeItem('languages');
    localStorage.removeItem('countries');
    localStorage.removeItem('categories');
    localStorage.removeItem('rates');
    localStorage.removeItem('projects');
    localStorage.removeItem('userType');
  }
  function login(token, email) {
    localStorage.removeItem('profile');
    localStorage.removeItem('projects');
    localStorage.removeItem('jp-version');

    cookies.set('auth-token', token, { path: '/', sameSite: 'strict' });
    cookies.set('auth-email', email, { path: '/', sameSite: 'strict' });
    setSession({ token: token, email: email });

    if (session.token) {
      axios.defaults.headers.common['authorization'] = `Bearer ${token}`;
    }
  }

  return (
    <AuthenticationContext.Provider value={{ session: session, login: login, logout: logout }}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export const AuthenticationContext = React.createContext();
export const AuthenticationConsumer = AuthenticationContext.Consumer;
export default withCookies(AuthenticationProvider);
