import React from 'react';
import { injectIntl } from 'react-intl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ProgressButton from '../../FormFields/ProgressButton';

function FinishProjectForm(props) {
  const { intl, values, handleSubmit, handleChange, handleBlur, isSubmitting, isValid } = props;
  return (
    <form onSubmit={handleSubmit}>
      <FormControlLabel
        control={
          <Checkbox
            checked={values.endProjectConfirmed}
            name="endProjectConfirmed"
            onChange={handleChange}
            onBlur={handleBlur}
            color="primary"
            variant="outlined"
          />
        }
        label={`${intl.formatMessage({ id: 'projects.management-page.finish-project.supplier.label' })}*`}
      />
      <ProgressButton
        type="submit"
        buttonBusy={isSubmitting}
        disabled={!isValid}
        submitting={isSubmitting}
        data-testid="submit-button"
      >
        {intl.formatMessage({ id: 'projects.management-page.finish-project.supplier.button' })}
      </ProgressButton>
    </form>
  );
}

export default injectIntl(FinishProjectForm);
