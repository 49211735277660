import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  progress: {
    color: 'inherit',
  },
});

function Progress(props) {
  const { classes, className } = props;

  return (
    <div className={classes.root} data-testid="progress-loader">
      <CircularProgress className={classnames(classes.progress, className)} />
    </div>
  );
}

export default withStyles(styles)(Progress);
