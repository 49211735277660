import React, { useState, useEffect } from 'react';
import { getUserType } from '../Api/LocalStorage';
import { fetchClientProfile, fetchSupplierProfile } from '../Api/FetchAndCache';

function ProfileProvider(props) {
  const { children, profileType } = props;
  const [userType, setUserType] = useState(profileType);
  const [userProfile, setUserProfile] = useState(false);

  const retrieveProfile = function() {
    return new Promise((resolve, reject) => {
      setUserType(getUserType());
    });
  };

  useEffect(() => {
    if (userType === 'supplier') {
      fetchSupplierProfile().then(profile => {
        setUserProfile(profile);
      });
    } else if (userType === 'client') {
      fetchClientProfile().then(profile => {
        setUserProfile(profile);
      });
    }
  }, [userType]);

  return (
    <ProfileContext.Provider
      value={{
        retrieveProfile: retrieveProfile,
        userProfile: userProfile,
        userType: userType,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}

ProfileProvider.defaultProps = {
  profileType: false,
};

export const ProfileContext = React.createContext();
export const ProfileConsumer = ProfileContext.Consumer;
export default ProfileProvider;
