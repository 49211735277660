import React, { useContext, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import ProgressButton from 'FormFields/ProgressButton';
import ProgressDeleteButton from 'FormFields/ProgressDeleteButton';
import { ShortlistContext } from 'Shortlist/ShortlistContext';
import ShortlistModal, { useModal } from '../../Shortlist/ShortlistModal';

const shortListToolbarStyles = theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  toolBar: {
    maxWidth: theme.spacing.unit * 128,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  toolBarText: {
    display: 'flex',
    flexDirection: 'column',
  },
  shortlistButton: {
    margin: theme.spacing.unit * 2,
    width: 300,
  },
  grow: {
    display: 'flex',
    flexGrow: 1,
  },
});

function ShortlistToolbar(props) {
  const { item, classes, enqueueSnackbar, forceScreen } = props;
  const { shortlist, delist } = useContext(ShortlistContext);
  const [processing, setProcessing] = useState(false);
  const [modalOpen, setModalOpen, suppliersToAdd, setSuppliersToAdd] = useModal(false);

  function shortlistSupplier() {
    setProcessing(true);
    shortlist(item.id)
      .catch(msg => {
        if (msg === 'client.shortlist.too-many') {
          setModalOpen(true);
          setSuppliersToAdd([item]);
        } else {
          enqueueSnackbar(
            props.intl.formatMessage({
              id: msg,
            }),
          );
        }
      })
      .finally(() => setProcessing(false));
  }

  function delistSupplier() {
    setProcessing(true);
    delist(item.id).finally(() => setProcessing(false));
  }

  if (item.shortlisted === true) {
    return (
      <AppBar color="default" position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.toolBarText}>
            <Typography variant="h5">
              <FormattedMessage
                id="supplier-profile-client-view.shortlist.prose-text"
                values={{
                  name: item.name,
                }}
              />
            </Typography>
          </div>
          <ProgressDeleteButton className={classes.shortlistButton} buttonBusy={processing} onClick={delistSupplier}>
            <FormattedMessage id="supplier-profile-client-view.shortlist-remove.text" />
          </ProgressDeleteButton>
        </Toolbar>
      </AppBar>
    );
  } else if (item.shortlisted === false) {
    return (
      <>
        <ShortlistModal
          modalOpen={modalOpen}
          onCancel={() => {
            setModalOpen(false);
          }}
          suppliersToAdd={suppliersToAdd}
          forceScreen={forceScreen}
        />
        <AppBar color="default" position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <div className={classes.toolBarText}>
              <Typography variant="h5">
                <FormattedMessage id="supplier-profile-client-view.shortlist.header-text" />
              </Typography>

              <Typography variant="body2">
                <FormattedMessage
                  id="supplier-profile-client-view.shortlist.header-prose"
                  values={{
                    name: item.name,
                  }}
                />
              </Typography>
            </div>
            <ProgressButton
              className={classes.shortlistButton}
              submitting={processing}
              buttonBusy={processing}
              onClick={shortlistSupplier}
            >
              <FormattedMessage id="supplier-profile-client-view.shortlist-add.text" />
            </ProgressButton>
          </Toolbar>
        </AppBar>
      </>
    );
  } else {
    return null;
  }
}

export default withStyles(shortListToolbarStyles)(withSnackbar(injectIntl(ShortlistToolbar)));
