import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import TextInput from '../FormFields/TextInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import ChipRadioGroup from '../FormFields/ChipRadioGroup';
import ProgressButton from '../FormFields/ProgressButton';

const styles = (theme) => ({
  root: {
    width: '90%',
    '& > *': {
      marginBottom: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing.unit * 4,
      },
    },
  },
});

const supplierOrClient = [
  {
    label: 'supplier',
    key: 0,
  },
  {
    label: 'client',
    key: 1,
  },
];

function RegistrationForm(props) {
  const {
    values,
    touched,
    errors,
    // dirty,
    isSubmitting,
    isValid,
    handleChange,
    handleBlur,
    // handleSubmit,
    // handleReset,
    setFieldValue,
    classes,
  } = props;

  return (
    <form onSubmit={props.handleSubmit} className={classes.root}>
      <TextInput
        required
        fullWidth
        margin="normal"
        name="givenName"
        value={values.givenName}
        placeholder="supplier-registration.givenName.placeholder"
        label="supplier-registration.givenName.label"
        helperText={touched.givenName && errors.givenName ? errors.givenName : 'supplier-registration.givenName.helper'}
        error={Boolean(touched.givenName && errors.givenName)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        required
        fullWidth
        margin="normal"
        name="email"
        value={values.email}
        placeholder="supplier-registration.email.placeholder"
        label="supplier-registration.email.label"
        helperText={touched.email && errors.email ? errors.email : 'supplier-registration.email.helper'}
        error={Boolean(touched.email && errors.email)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        required
        fullWidth
        type="password"
        margin="normal"
        name="password"
        value={values.password}
        placeholder="supplier-registration.password.placeholder"
        label="supplier-registration.password.label"
        helperText={touched.password && errors.password ? errors.password : 'supplier-registration.password.helper'}
        error={Boolean(touched.password && errors.password)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        required
        fullWidth
        type="password"
        margin="normal"
        name="confirmPassword"
        value={values.confirmPassword}
        placeholder="supplier-registration.confirm-password.placeholder"
        label="supplier-registration.confirm-password.label"
        helperText={
          touched.confirmPassword && errors.confirmPassword
            ? errors.confirmPassword
            : 'supplier-registration.confirm-password.helper'
        }
        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <ChipRadioGroup
        options={supplierOrClient}
        name="supplierOrClient"
        value={values.supplierOrClient}
        onClick={setFieldValue}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <FormControlLabel
        control={
          <Checkbox
            data-testid="t-and-c"
            checked={values.agreeToTerms}
            name="agreeToTerms"
            onChange={handleChange}
            onBlur={handleBlur}
            color="primary"
          />
        }
        label={
          <FormattedMessage
            id="supplier-registration.terms.label"
            values={{
              pp: (
                <a href="https://www.jam-pan.com/privacy-notice/" target="_blank" rel="noreferrer noopener">
                  <FormattedMessage id="supplier-registration.terms.privacy-notice" />
                </a>
              ),
            }}
          />
        }
      />

      <ProgressButton
        type="submit"
        submitting={isSubmitting}
        buttonBusy={isSubmitting}
        disabled={!isValid}
        data-testid="submit-button"
      >
        {props.intl.formatMessage({
          id: 'supplier-registration.submit.text',
        })}
      </ProgressButton>
    </form>
  );
}

export default withStyles(styles)(injectIntl(RegistrationForm));
