import React from 'react';
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { fetchCategories } from '../Api/FetchAndCache';
import Select from 'react-select';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: theme.spacing.unit * 3,
  },
});
function SearchBox(props) {
  const { classes } = props;
  const [availableSkills, setAvailableSkills] = useState([]);
  const [searchedValue, setSearchedValue] = useState('');

  const fetchData = () => {
    fetchCategories().then(result => {
      setAvailableSkills(
        result
          .filter(category => {
            return category.parent_id > 0 && !category.isTool && !category.isPlatform;
          })
          .map(category => {
            return { value: category.id, label: category.name };
          }),
      );
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  function handleOnClick() {
    props.history.push({ pathname: `/search/${searchedValue}` });
  }

  return (
    <Paper className={classes.root} elevation={1}>
      <Select
        options={availableSkills}
        value={availableSkills.find(s => s.value === searchedValue)}
        name="searchbox"
        placeholder={<FormattedMessage id="client.find.search.box.label" />}
        onChange={option => {
          setSearchedValue(option.value);
        }}
      />

      <Button
        className={classes.button}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={searchedValue.length === 0}
        onClick={handleOnClick}
      >
        {props.intl.formatMessage({
          id: 'client.find.search.button.label',
        })}
      </Button>
    </Paper>
  );
}

export default withStyles(styles)(withRouter(injectIntl(SearchBox)));
