import axios from 'axios';

export function saveProject(values) {
  return axios.post('/api/jobs', values);
}

export function updateProject(id, values) {
  return axios.put(`/api/jobs/${id}`, values);
}

export function getProject(id) {
  return axios.get(`/api/jobs/${id}`);
}

export function getProjects() {
  return axios.get('/api/jobs');
}

export function fileUploads(values) {
  return axios.post('/api/jobs/files', values);
}

export function removeSupplierFromSpecificJob(supplierId, jobId, body) {
  return axios.post(`/api/jobs/${jobId}/remove-supplier/${supplierId}`, body);
}

export function assignJobToSuppliers(jobId, body) {
  return axios.post(`/api/jobs/assign/${jobId}`, body);
}

export function endProject(id, values) {
  return axios.put(`/api/jobs/request-end/${id}`, values);
}
