import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js/fn/promise/finally';
import 'core-js/fn/array/find';
import 'core-js/fn/array/includes';
import 'core-js/fn/object/values';
import 'core-js/fn/string/starts-with';
import './index.css';
import ErrorBoundary from 'react-error-boundary';

import { CookiesProvider } from 'react-cookie';

import './fonts/Avenir-Heavy/Avenir-Heavy.css';
import './fonts/AvenirLTStd-Roman/AvenirLTStd-Roman.css';
import './Validations/WebsiteValidation';

import App from './App';

import { IntlProvider, addLocaleData } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import messages_en from './translations/en.json';
import messages_pl from './translations/pl.json';
import { SnackbarProvider } from 'notistack';
import AuthenticationProvider from './Authentication/AuthenticationContext';
import SearchResultsProvider from './ClientSearch/SearchResultsContext';
import * as en from 'react-intl/locale-data/en';
import * as pl from 'react-intl/locale-data/pl';

import { theme } from './muiTheme';
import { MuiThemeProvider } from '@material-ui/core/styles';
import GenericErrorPage from './Pages/GenericErrorPage';
import ShortlistProvider from './Shortlist/ShortlistContext';
import NotificationProvider from './Notifications/NotificationContext';
import ProfileProvider from './Profile/ProfileContext';
import CommsProvider from './Comms/CommsContext';
const messages = {
  'en-gb': messages_en,
  en: messages_en,
  'pl-pl': messages_pl,
  pl: messages_pl,
};
addLocaleData([...en, ...pl]);
const lowerCaseLocale = navigator.language.toLowerCase();
const language = lowerCaseLocale in messages ? lowerCaseLocale : 'en-gb';
ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <ErrorBoundary FallbackComponent={GenericErrorPage}>
      <IntlProvider locale={language} messages={messages[language]}>
        <CookiesProvider>
          <AuthenticationProvider>
            <ProfileProvider>
              <ShortlistProvider>
                <NotificationProvider>
                  <CommsProvider>
                    <BrowserRouter>
                      <SearchResultsProvider>
                        <SnackbarProvider
                          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                          autoHideDuration={3000}
                        >
                          <App color="dark" />
                        </SnackbarProvider>
                      </SearchResultsProvider>
                    </BrowserRouter>
                  </CommsProvider>
                </NotificationProvider>
              </ShortlistProvider>
            </ProfileProvider>
          </AuthenticationProvider>
        </CookiesProvider>
      </IntlProvider>
    </ErrorBoundary>
  </MuiThemeProvider>,
  document.getElementById('root'),
);
