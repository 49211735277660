import React, { useEffect } from 'react';
import { Prompt as ReactRouterPrompt } from 'react-router-dom';

function Prompt(props) {
  const { when } = props;

  useEffect(() => {
    if (when) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    return () => {
      window.onbeforeunload = undefined;
    };
  });

  return <ReactRouterPrompt {...props} />;
}

export default Prompt;
