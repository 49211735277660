import React from 'react';
import { useState, useEffect } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import SkillsAndProjectsForm from './SkillsAndProjectsForm';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import {
  fetchCategories,
  fetchSupplierProfile,
  updateProjects,
  updateSupplierProfile as updateLocalSupplierProfile,
} from '../Api/FetchAndCache';
import { fetchRates } from '../Api/FetchAndCache';
import { getProjects, saveProject, updateProject } from '../Api/Projects';
import { updateSupplierProfile } from '../Api/SupplierProfile';
import { withSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router';

export const validationSchema = Yup.object().shape({
  projectTitle: Yup.string().trim().required('supplier-profile.project-title.required').min(1),
  projectBio: Yup.string().trim().required('supplier-profile.project-bio.required').min(1),
  projectUrl: Yup.string().trim().website('supplier-profile.website.invalid'),
  skills: Yup.array().required('supplier-profile.skills.required').min(1),
  projectSkills: Yup.array().required('supplier-profile.skills.required').min(1),
  files: Yup.array().required('supplier-profile.files.required').min(1),
});

const styles = (theme) => ({
  root: {
    maxWidth: '1024px',
    alignItems: 'center',
  },
  paperForm: {
    display: 'flex',
    padding: theme.spacing.unit * 2,
    marginTop: '100px',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing.unit * 2,
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing.unit * 3,
      width: '700px',
    },
    flexDirection: 'column',
    border: '1px solid #CCCBC8',
    alignItems: 'center',
  },
  topHeader: {
    paddingBottom: theme.spacing.unit * 2,
    textAlign: 'center',
    paddingTop: theme.spacing.unit * 5,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing.unit * 12,
    },
  },
  midHeader: {
    paddingBottom: theme.spacing.unit * 4,
    textAlign: 'center',
  },
});

export function transformValuesToProject(values) {
  const data = {
    name: values.projectTitle,
    bio: values.projectBio,
    website: values.projectUrl,
    projectFiles: values.files.map((f) => {
      return f.id;
    }),
    skills: values.projectSkills.map((s) => {
      return s.key;
    }),
  };
  return data;
}

export function transformProjectToValues(availableSkills, project) {
  const data = {
    projectTitle: project.title || '',
    projectBio: project.bio || '',
    projectUrl: project.url || '',
    files: project.files,
    projectSkills: availableSkills.filter((as) => {
      return project.skills.filter((sid) => sid === as.key).length;
    }),
  };
  return data;
}

function SkillsAndProjects(props) {
  const { classes, enqueueSnackbar } = props;
  const [availableSkills, setAvailableSkills] = useState([]);
  const [rates, setRates] = useState([]);
  const [profileCurrency, setProfileCurrency] = useState('');
  const [project, setProject] = useState({ id: -1 });
  const [values, setValues] = useState({
    skills: [],
    projectTitle: '',
    projectBio: '',
    projectUrl: '',
    files: [],
    projectSkills: [],
  });
  function updateProjectValue(project) {
    setValues((prevValues) => {
      return {
        ...prevValues,
        ...transformProjectToValues(availableSkills, project),
      };
    });
  }

  const fetchData = () => {
    fetchSupplierProfile().then((supplier) => {
      setValues((prevVals) => ({
        ...prevVals,
        skills: supplier.skills.map((s) => ({
          key: s.id,
          value: s.id,
          label: s.name,
          rate: s.pivot.cost_ranges_id,
        })),
      }));
      setProfileCurrency(get(supplier, 'currency.symbol', ''));
    });
    fetchRates().then((result) => {
      setRates(result);
    });
    getProjects().then((projects) => {
      if (projects.data.data.length > 0) {
        setProject(projects.data.data[0]);
      }
    });
    fetchCategories().then((result) => {
      setAvailableSkills(
        result
          .filter((category) => {
            return category.parent_id > 0 && !category.isTool && !category.isPlatform;
          })
          .map((category) => {
            return { value: category.id, key: category.id, label: category.name };
          }),
      );
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (project.id > 0 && availableSkills.length) {
      updateProjectValue(project);
    }
  }, [availableSkills, project]);

  function saveForLater(values) {
    updateSupplierProfile({
      skills: values.skills.map((skill) => {
        return { id: skill.key, rate: skill.rate };
      }),
    }).then((result) => {
      (project.id >= 0
        ? updateProject(project.id, transformValuesToProject(values))
        : saveProject(transformValuesToProject(values)).then((response) => {
            setProject(response.data.data);
            updateProjectValue(response.data.data);
          })
      ).then(() => {
        updateLocalSupplierProfile();
        enqueueSnackbar(<FormattedMessage id="supplier-profile.saved" />);
      });
    });
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paperForm} elevation={0}>
        <Formik
          render={(props) => (
            <SkillsAndProjectsForm
              {...props}
              saveForLater={saveForLater}
              availableSkills={availableSkills}
              rates={rates}
              profileCurrency={profileCurrency}
            />
          )}
          initialValues={values}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            updateSupplierProfile({
              skills: values.skills.map((skill) => {
                return { id: skill.key, rate: skill.rate };
              }),
            })
              .then(() => {
                (project.id >= 0
                  ? updateProject(project.id, transformValuesToProject(values))
                  : saveProject(transformValuesToProject(values))
                ).then(() => {
                  return Promise.all([updateLocalSupplierProfile(), updateProjects()]).then(() => {
                    enqueueSnackbar(<FormattedMessage id="supplier-profile.saved" />);
                    props.history.push({
                      pathname: '/dashboard',
                    });
                  });
                });
              })
              .catch((e) => {
                actions.setSubmitting(false);
              });
          }}
        />
      </Paper>
    </div>
  );
}

export default withStyles(styles)(withSnackbar(withRouter(SkillsAndProjects)));
