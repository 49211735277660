import React from 'react';
import { useState, useRef } from 'react';
import Chip from '@material-ui/core/Chip';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Typography from '@material-ui/core/Typography';
import RootRef from '@material-ui/core/RootRef';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import { endOfMonth, addMonths, eachDay, format, isWeekend } from 'date-fns';
import { getDayAfterWeekend } from '../../SupplierProfile/getDayAfterWeekend';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    alignContent: 'space-around',
    paddingLeft: theme.spacing.unit * 2,
  },
  chipContainer: {
    width: '100%',
  },
  chipperChip: {
    marginRight: theme.spacing.unit * 2,
  },
  paper: {
    marginTop: theme.spacing.unit,
    paddingTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
    maxWidth: theme.spacing.unit * 64,
  },
  menuList: {
    display: 'flex',
    flexGrow: 1,
    flexWrap: 'wrap',
  },
  header: {
    display: 'block',
    width: '100%',
    marginBottom: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  menuChip: {
    marginBottom: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 1,
  },
  clearButton: {
    marginTop: theme.spacing.unit,
  },
});

function AvailabilityMenu(props) {
  const {
    classes,
    chip,
    menuOptions,
    resetSelectedFilters,
    availabilityFilters,
    setAvailabilityFilters,
    resetAvailabilityFilters,
    onClick,
  } = props;
  const [open, setOpen] = useState(false);
  const chipEl = useRef(null);

  const [months, setMonths] = useState('');
  const [days, setDays] = useState('');
  const firstEventDay = getDayAfterWeekend(new Date());
  // Array w/ "busy" event objects from first day (current day
  // or monday if weekend) until 3 months (excluding weekends / end of that month)
  const initialBusyDays = eachDay(firstEventDay, endOfMonth(addMonths(firstEventDay, 3)))
    .map((day) => ({
      start: format(day, 'YYYY-MM-DD'),
      end: format(day, 'YYYY-MM-DD'),
      display: 'background',
      title: '',
      color: '#ff0000cf',
      type: 'busy',
    }))
    .filter((day) => !isWeekend(day.start));
  const [calendarDays, setCalendarDays] = useState(initialBusyDays);
  const [isBlindMode, setIsBlindMode] = useState(false);

  let values = null;
  const onSearch = () => {
    switch (availabilityFilters) {
      case '2':
        values = {
          months,
        };
        break;
      case '3':
        values = {
          days,
          months,
        };
        break;
      case '4':
        values = {
          months,
        };
        break;
      case '5':
        values = {
          days_calendar: calendarDays.filter((day) => day.type === 'available').map((day) => day.start),
        };
        break;
      case '7':
        values = {
          days_calendar: calendarDays.filter((day) => day.type === 'available').map((day) => day.start),
        };
        break;
      default:
        values = null;
        break;
    }
    onClick('availability', availabilityFilters, values, true);
    setOpen(false);
  };

  const onClear = () => {
    resetAvailabilityFilters();
    resetSelectedFilters();
    setMonths('');
    setDays('');
    setCalendarDays(initialBusyDays);
    setIsBlindMode(false);
    setOpen(false);
  };

  return (
    <>
      <RootRef rootRef={chipEl}>
        <Chip
          className={classes.chipperChip}
          label={<FormattedMessage id={chip.label} />}
          variant={open ? 'default' : 'outlined'}
          onClick={(e) => {
            setOpen(!open);
          }}
          color="primary"
        />
      </RootRef>

      <Popper anchorEl={chipEl.current} placement="top" open={open}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} id="menu-list-grow">
            <ClickAwayListener
              onClickAway={(e) => {
                if (chipEl.current.contains(e.target)) {
                  return;
                }
                if (open) {
                  setOpen(false);
                }
              }}
            >
              <Paper className={classes.paper}>
                <MenuList className={classes.menuList}>
                  {menuOptions.map((m) => (
                    <div key={m.label}>
                      <Typography variant="h6" color="inherit" className={classes.header}>
                        <FormattedMessage id={m.label} />
                      </Typography>
                      {m.options.map((o) => {
                        return (
                          <div className={classes.chipContainer} key={o.key}>
                            <Chip
                              label={o.label}
                              key={o.key}
                              className={classes.menuChip}
                              variant={availabilityFilters === o.key ? 'default' : 'outlined'}
                              color="primary"
                              onClick={() => {
                                if (availabilityFilters === o.key) {
                                  setAvailabilityFilters(null);
                                } else {
                                  setAvailabilityFilters(o.key);
                                }
                              }}
                            />

                            <Tooltip disableFocusListener title={o.tooltip}>
                              <IconButton
                                aria-label={o.tooltip}
                                className={classes.menuChip}
                                style={{
                                  padding: 0,
                                }}
                              >
                                <HelpOutlineIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>

                            {availabilityFilters === o.key && o.component && (
                              <o.component
                                months={months}
                                days={days}
                                availability={calendarDays}
                                onChangeMonths={setMonths}
                                onChangeDays={setDays}
                                onChangeAvailability={setCalendarDays}
                                isBlindMode={isBlindMode}
                                setIsBlindMode={setIsBlindMode}
                              />
                            )}
                          </div>
                        );
                      })}
                      <Button
                        disabled={availabilityFilters === null}
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={onSearch}
                      >
                        <FormattedMessage id="filter.menu.availability.search-actions.apply" />
                      </Button>
                      <Button fullWidth variant="contained" className={classes.clearButton} onClick={onClear}>
                        <FormattedMessage id="filter.menu.availability.search-actions.clear" />
                      </Button>
                    </div>
                  ))}
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default withStyles(styles)(AvailabilityMenu);
