import React from 'react';
import { useState, useEffect } from 'react';
import PreparingDashboard from '../Pages/PreparingDashboard';

import SupplierDashboard from './SupplierDashboard';
import ClientDashboard from './ClientDashboard';
import ProfileUpdatePromptPage from './ProfileUpdatePromptPage';
import AdminValidationPromptPage from './AdminValidationPromptPage';
import MSAValidationPromptPage from './MSAValidationPromptPage';
import { getUserType } from '../Api/LocalStorage';
import NotFoundPage from '../Pages/NotFoundPage';

import {
  fetchSupplierProfile,
  fetchClientProfile,
  fetchProjects,
  fetchLanguages,
  fetchCountries,
  fetchCategories,
  fetchRates,
  fetchCurrencies,
  fetchIndustries,
  fetchRoles,
  fetchCompanySizes,
} from '../Api/FetchAndCache';

function DashboardManager() {
  const [dataReceived, setDataReceived] = useState(false);
  const [profile, setProfile] = useState({});
  const userType = getUserType();
  const fetchData = () => {
    if (userType === 'supplier') {
      Promise.all([
        fetchSupplierProfile(),
        fetchProjects(),
        fetchLanguages(),
        fetchCountries(),
        fetchCategories(),
        fetchRates(),
        fetchCurrencies(),
      ]).then((values) => {
        setProfile(values[0]);
        setDataReceived(true);
      });
    } else if (userType === 'client') {
      Promise.all([fetchClientProfile(), fetchCategories(), fetchIndustries(), fetchRoles(), fetchCompanySizes()]).then(
        (values) => {
          setProfile(values[0]);
          setDataReceived(true);
        },
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!dataReceived) {
    return <PreparingDashboard />;
  }

  if (userType === 'supplier') {
    if (profile.profileStep2Complete && profile.is_admin_active && profile.last_msa_agreed)
      return <SupplierDashboard />;
    if (profile.profileStep2Complete && !profile.is_admin_active) return <AdminValidationPromptPage />;
    if (profile.profileStep1Complete && !profile.profileStep2Complete)
      return <ProfileUpdatePromptPage linkTo="/supplier/skills" />;
    if (!profile.profileStep1Complete) return <ProfileUpdatePromptPage linkTo="/supplier/profile" />;
    return <MSAValidationPromptPage name={profile.name} file={profile.last_msa_file} />;
  }

  if (userType === 'client') {
    if (!profile.profileStepsComplete) return <ProfileUpdatePromptPage linkTo="/client/profile" />;
    if (!profile.is_admin_active) return <AdminValidationPromptPage />;
    return <ClientDashboard />;
  }

  return <NotFoundPage />;
}

export default DashboardManager;
