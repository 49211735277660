import React, { Fragment } from 'react';
import FinishProjectSupplierRow from './FinishProjectSupplierRow';
import Divider from '@material-ui/core/Divider';

function FinishProjectSuppliersComponent(props) {
  const { project, onSubmit } = props;

  return (
    <>
      {project.suppliers.map((supplier) => {
        return (
          <Fragment key={supplier.id}>
            <FinishProjectSupplierRow supplier={supplier} onSubmit={onSubmit} />
            <Divider />
          </Fragment>
        );
      })}
    </>
  );
}

export default FinishProjectSuppliersComponent;
