import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as LangIcon } from 'assets/Icon-Lang.svg';

const fluencies = [
  {
    label: 'languages.conversational',
    key: 0,
  },
  {
    label: 'languages.fluent',
    key: 1,
  },
  {
    label: 'languages.native',
    key: 2,
  },
];

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  languageList: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 2 * theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  langIcon: {
    height: 24,
    width: 24,
  },
});
function Languages(props) {
  const { item, classes, excludeEnglish } = props;
  return (
    <div className={classes.root}>
      <LangIcon className={classes.langIcon} />
      <div className={classes.languageList}>
        {item.languages
          .filter(l => l.id !== (excludeEnglish ? 1 : null))
          .map(l => (
            <div key={l.id}>
              <Typography variant="body2" color="textPrimary">
                {l.name} (<FormattedMessage id={fluencies.find(f => f.key === l.pivot.level).label} />)
              </Typography>
            </div>
          ))}
      </div>
    </div>
  );
}
Languages.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  excludeEnglish: PropTypes.bool.isRequired,
};
Languages.defaultProps = {
  excludeEnglish: true,
};
export default withStyles(styles)(Languages);
