import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/LockOutlined';
import TextInput from '../../FormFields/TextInput';
import { Formik } from 'formik';
import ChipRadioGroup from '../../FormFields/ChipRadioGroup';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { sendBillingDetails } from '../../Api/Comms';
import ProgressButton, { useProgressButton } from '../../FormFields/ProgressButton';

const values = { supplierPaymentType: 1, name: '', code: '', account: '' };

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required('required-fields.invalid')
    .min(1),
  code: Yup.string()
    .trim()
    .required('required-fields.invalid')
    .min(1),
  account: Yup.string()
    .trim()
    .required('required-fields.invalid')
    .min(1),
  legal: Yup.bool()
    .required('required-fields.invalid')    
});

function parseValuesToPayment(values) {
  return values.supplierPaymentType === 1
    ? {
        bankAccount: values.account,
        sortCode: values.code,
        paymentName: values.name,
        vatRegistered: values.vat,
        tcAccepted: values.legal,
      }
    : {
        swift: values.account,
        iban: values.code,
        paymentName: values.name,
        vatRegistered: values.vat,
        tcAccepted: values.legal,
      };
}

function SupplierPaymentDetails(props) {
  const { classes, intl, onSubmit, commId } = props;
  const [buttonBusy, setButtonBusy] = useProgressButton(false);
  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        setButtonBusy(true);
        sendBillingDetails(commId, parseValuesToPayment(values)).then(resp => {
          setButtonBusy(false);
          actions.setSubmitting(false);
          onSubmit(values, actions);
        });
      }}
      render={props => {
        const {
          values,
          touched,
          errors,
          handleSubmit,
          setFieldValue,
          handleChange,
          handleBlur,
          isSubmitting,
          isValid,
        } = props;
        return (
          <form className={classes.root} onSubmit={handleSubmit}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 16 }}>
              <LockIcon /> <FormattedMessage id="projects.sow.supplier-payment.details" />
            </div>
            <Typography variant="body2">
              <FormattedMessage id="projects.sow.supplier-payment.header" />
            </Typography>
            <ChipRadioGroup
              options={[
                { key: 1, label: 'projects.sow.supplier-payment.uk' },
                { key: 2, label: 'projects.sow.supplier-payment.not-uk' },
              ]}
              name="supplierPaymentType"
              value={values.supplierPaymentType}
              onClick={setFieldValue}
              onBlur={handleBlur}
              disabled={isSubmitting}
            />
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              <TextInput
                required
                placeholder="projects.sow.supplier-payment.name"
                label="projects.sow.supplier-payment.name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                helperText={touched.name && errors.name ? errors.name : null}
                error={Boolean(touched.name && errors.name)}
                style={{ width: '45%', marginBottom: 16, marginRight: 16 }}
              />
              <TextInput
                required
                placeholder={
                  values.supplierPaymentType === 2
                    ? 'projects.sow.supplier-payment.swift'
                    : 'projects.sow.supplier-payment.sort-code'
                }
                label={
                  values.supplierPaymentType === 2
                    ? 'projects.sow.supplier-payment.swift'
                    : 'projects.sow.supplier-payment.sort-code'
                }
                name="code"
                value={values.code}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                helperText={touched.code && errors.code ? errors.code : null}
                error={Boolean(touched.code && errors.code)}
                style={{ width: '45%', marginBottom: 16, marginRight: 16 }}
              />
              <TextInput
                required
                placeholder={
                  values.supplierPaymentType === 2
                    ? 'projects.sow.supplier-payment.iban'
                    : 'projects.sow.supplier-payment.account'
                }
                label={
                  values.supplierPaymentType === 2
                    ? 'projects.sow.supplier-payment.iban'
                    : 'projects.sow.supplier-payment.account'
                }
                name="account"
                value={values.account}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={isSubmitting}
                helperText={touched.account && errors.account ? errors.account : null}
                error={Boolean(touched.account && errors.account)}
                style={{ width: '45%', marginBottom: 16, marginRight: 16 }}
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox                  
                  checked={!!values.vat}
                  name="vat"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color="primary"
                  variant="outlined"
                />
              }
              label={
                <Typography variant="body2" style={{ color: 'black' }}>
                  {intl.formatMessage({
                    id: 'projects.sow.supplier-payment.vat',
                  })}                  
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  required
                  checked={!!values.legal}
                  name="legal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color="primary"
                  variant="outlined"
                />
              }
              label={
                <Typography variant="body2" style={{ color: 'black' }}>
                  {intl.formatMessage({
                    id: 'projects.sow.legal.label',
                  })}
                  *
                </Typography>
              }
            />
            <ProgressButton type="submit" buttonBusy={buttonBusy} disabled={!isValid || isSubmitting}>
              <FormattedMessage id="projects.sow.sign.button" />
            </ProgressButton>
          </form>
        );
      }}
    />
  );
}

const paymentDetailsStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& > *': {
      marginBottom: 16,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > *': {
      width: '45%',
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
  },
});

export default withStyles(paymentDetailsStyles)(injectIntl(SupplierPaymentDetails));
