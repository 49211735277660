import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { SearchResultsContext } from '../SearchResultsContext';
import { withStyles } from '@material-ui/core/styles';
import { fetchClientProfile, fetchRates, fetchCategories } from 'Api/FetchAndCache';
import { FormattedMessage } from 'react-intl';
import AvailabilityMenu from './AvailabilityMenu';
import ChipMenu from './ChipMenu';
import MonthsFilter from './MonthsFilter';
import DaysAndMonthsFilter from './DaysAndMonthsFilter';
import AvailabilityCalendar from './AvailabilityCalendar';
import get from 'lodash/get';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    alignContent: 'space-around',
    paddingLeft: theme.spacing.unit * 2,
  },
});
function ChipMenuList(props) {
  const { classes } = props;
  const [rates, setRates] = useState([]);
  const [tools, setTools] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const {
    selectedFilters,
    resetSelectedFilters,
    availabilityFilters,
    setAvailabilityFilters,
    resetAvailabilityFilters,
    onChange,
  } = useContext(SearchResultsContext);

  const chips = [
    { label: 'filter.menus.availability', key: 'availability' },
    { label: 'filter.menus.remote', key: 'remote' },
    { label: 'filter.menus.day-rate', key: 'rates' },
    { label: 'filter.menus.tools-platforms', key: 'toolsandplatforms' },
  ];

  const menuOptions = {
    availability: [
      {
        label: 'filter.menus.availability',
        key: 'availability',
        options: [
          {
            label: <FormattedMessage id="filter.menu.availability.some-free-days" />,
            key: '2',
            tooltip: <FormattedMessage id="filter.menu.availability.some-free-days.tooltip" />,
            component: MonthsFilter,
          },
          {
            label: <FormattedMessage id="filter.menu.availability.x-free-days" />,
            key: '3',
            tooltip: <FormattedMessage id="filter.menu.availability.x-free-days.tooltip" />,
            component: DaysAndMonthsFilter,
          },
          {
            label: <FormattedMessage id="filter.menu.availability.all-days-free" />,
            key: '4',
            tooltip: <FormattedMessage id="filter.menu.availability.all-days-free.tooltip" />,
            component: MonthsFilter,
          },
          {
            label: <FormattedMessage id="filter.menu.availability.some-custom-free" />,
            key: '5',
            tooltip: <FormattedMessage id="filter.menu.availability.some-custom-free.tooltip" />,
            component: AvailabilityCalendar,
          },
          {
            label: <FormattedMessage id="filter.menu.availability.all-custom-free" />,
            key: '7',
            tooltip: <FormattedMessage id="filter.menu.availability.all-custom-free.tooltip" />,
            component: AvailabilityCalendar,
          },
        ],
      },
    ],
    remote: [
      {
        label: 'filter.menus.remote',
        key: 'remote',
        options: [
          {
            label: <FormattedMessage id="filter.menu.onsite" />,
            key: 'onsite',
          },
          {
            label: <FormattedMessage id="filter.menu.remote" />,
            key: 'remote',
          },
        ],
      },
    ],
    rates: [{ label: 'filter.menus.day-rate', key: 'rates', options: rates }],
    toolsandplatforms: [
      {
        label: 'filter.menus.tools',
        key: 'tools',
        options: tools,
      },
      {
        label: 'filter.menus.platforms',
        key: 'platforms',
        options: platforms,
      },
    ],
  };

  function fetchData() {
    fetchCategories().then((categories) => {
      setTools(
        categories
          .filter((c) => c.isTool)
          .map((c) => {
            return { label: c.name, key: c.id };
          }),
      );
      setPlatforms(
        categories
          .filter((c) => c.isPlatform)
          .map((c) => {
            return { label: c.name, key: c.id };
          }),
      );
    });
    fetchClientProfile().then((client) => {
      fetchRates().then((result) => {
        setRates(
          result.map((r) => {
            return { label: `${get(client, 'currency.symbol', '')} ${r.interval}`, key: r.id };
          }),
        );
      });
    });
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      {chips.map((c) => {
        if (c.key === 'availability') {
          return (
            <AvailabilityMenu
              chip={c}
              menuOptions={menuOptions[c.key]}
              key={c.key}
              selectedFilters={selectedFilters}
              resetSelectedFilters={resetSelectedFilters}
              availabilityFilters={availabilityFilters}
              setAvailabilityFilters={setAvailabilityFilters}
              resetAvailabilityFilters={resetAvailabilityFilters}
              onClick={onChange}
            />
          );
        }
        return (
          <ChipMenu
            chip={c}
            menuOptions={menuOptions[c.key]}
            key={c.key}
            selectedFilters={selectedFilters}
            onClick={onChange}
          />
        );
      })}
    </div>
  );
}

export default withStyles(styles)(ChipMenuList);
