import axios from 'axios';

export function getJobComms(id) {
  return axios.get(`/api/comms/${id}`);
}

export function getCommMessages(id) {
  return axios.get(`/api/comms/messages/${id}`);
}

export function sendCommMessage(id, data) {
  return axios.post(`/api/comms/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function requestQuote(id) {
  return axios.get(`/api/comms/request-quote/${id}`);
}

export function sendQuote(id, data) {
  return axios.post(`/api/comms/store-quote/${id}`, data);
}

export function sendBillingDetails(id, data) {
  return axios.post(`/api/comms/billing/${id}`, data);
}

export function sendInvoiceDetails(id, data) {
  return axios.post(`/api/comms/invoice-details/${id}`, data);
}
