import React from 'react';
import { useState, useEffect } from 'react';
import HeaderPage from '../Pages/HeaderPage';
import { fetchSupplierProfile } from '../Api/FetchAndCache';
import { FormattedMessage } from 'react-intl';
import ProjectsListWidget from '../Projects/ProjectsListWidget';
import TaskListComponent from './Components/TaskListComponent';

function SupplierDashboard(props) {
  const [supplier, setSupplier] = useState({});

  useEffect(() => {
    fetchSupplierProfile().then(supplier => {
      setSupplier(supplier);
    });
  }, []);

  return (
    <HeaderPage
      headerText={<FormattedMessage id="dashboard.header" values={{ fullname: supplier.name }} />}
      subtitleText="dashboard.subtitle"
    >
      <TaskListComponent supplier={supplier} />
      <ProjectsListWidget />
    </HeaderPage>
  );
}
export default SupplierDashboard;
