import axios from 'axios';

export function updateClientProfile(values) {
  return axios.put('/api/profiles/client', values);
}

export function getClientProfile() {
  return axios.get('/api/profiles/client');
}

export function updateCompany(values) {
  return axios.put('/api/profiles/client/company', values);
}
