import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SmallAvatar } from '../../SupplierProfile/SupplierAvatar';
import { distanceInWordsToNow } from 'date-fns';
import { Typography, Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'row',
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginRight: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3,
  },
  senderMessage: {
    backgroundColor: '#F5F5F5',
    borderRadius: 4,
    minHeight: theme.spacing.unit * 5,
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
  fileLink: {
    textDecoration: 'none',
  },
  messageDate: {
    alignSelf: 'flex-start',
  },
  attachments: {
    marginBottom: theme.spacing.unit * 2,
  },
});

function ReceiverMessage(props) {
  const { classes, message, senderProfile } = props;

  return (
    <div className={classes.root}>
      <SmallAvatar item={senderProfile} />
      <div className={classes.messageContent}>
        <div className={classes.senderMessage}>
          <Typography variant="body2">{message.message}</Typography>
        </div>
        {props.message.files && props.message.files.length > 0 && (
          <div className={classes.attachments}>
            <Typography variant="caption">
              <FormattedMessage id="communications.comms.message-box.attachments" />
            </Typography>
            {props.message.files.map((t) => (
              <a
                key={t.filename}
                href={t.fullUrl}
                target="_blank"
                rel="noreferrer noopener"
                className={classes.fileLink}
              >
                <Typography variant="caption">{JSON.parse(t.properties).originalName}</Typography>
              </a>
            ))}
          </div>
        )}
        <div className={classes.messageDate}>
          <Tooltip title={message.created_at}>
            <Typography variant="caption">{distanceInWordsToNow(new Date(message.created_at))}</Typography>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(ReceiverMessage);
