import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { injectIntl, FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { withTheme } from '@material-ui/core/styles';
import { Select, OutlinedInput } from '@material-ui/core';
import { Formik } from 'formik';
import ProgressDeleteButton from '../../FormFields/ProgressDeleteButton';
import { removeSupplierFromSpecificJob } from '../../Api/Jobs';
import { CommsContext } from '../CommsContext';
import get from 'lodash/get';

const styles = theme => ({
  dialogContent: {
    paddingBottom: 0,
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: theme.spacing.unit * 85,
    display: 'flex',
    '& > *': {
      width: theme.spacing.unit * 52,
    },
  },
  blurb: {
    marginTop: theme.spacing.unit * 2,
  },
  dialogActions: {
    paddingRight: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
    flexDirection: 'column',
    margin: 0,
  },
});

const defaultValues = { removeSupplierReason: '1' };

function RemoveSupplierModalComponent(props) {
  const {
    classes,
    theme,
    modalOpen,
    onCancel,
    onSubmit,
    forceScreen,
    supplierId,
    jobId,
    header,
    blurb,
    reasons,
    button,
  } = props;
  const smallScreen = forceScreen ? false : useMediaQuery(theme.breakpoints.down('sm'));
  const { setComms } = useContext(CommsContext);

  return (
    <Dialog
      fullScreen={smallScreen}
      maxWidth={'md'}
      open={modalOpen}
      onClose={onCancel}
      aria-labelledby="form-dialog-title"
      className={classes.dialog}
    >
      <Formik
        initialValues={defaultValues}
        onSubmit={(values, actions) => {
          removeSupplierFromSpecificJob(supplierId, jobId, {
            reason: reasons.find(r => r.id === values.removeSupplierReason).label,
          })
            .then(resp => {
              onSubmit(get(resp, 'data.data', {}));
              setComms(get(resp, 'data.data', {}));
            })
            .finally(() => {
              actions.setSubmitting(false);
              onCancel();
            });
        }}
        render={props => {
          const { values, handleSubmit, handleChange, handleBlur, isSubmitting } = props;
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent className={classes.dialogContent}>
                <Typography variant="h4">
                  <FormattedMessage id={header} />
                </Typography>
                <Typography variant="body2" className={classes.blurb}>
                  <FormattedMessage id={blurb} />
                </Typography>

                <Select
                  required
                  native
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isSubmitting}
                  value={values.removeSupplierReason}
                  input={<OutlinedInput labelWidth={0} name="removeSupplierReason" id="removeSupplierReason-select" />}
                  className={classes.removeSupplierReason}
                >
                  {reasons.map(r => (
                    <option value={r.id} key={r.id}>
                      {r.label}
                    </option>
                  ))}
                </Select>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <ProgressDeleteButton type="submit" fullWidth disabled={isSubmitting} buttonBusy={isSubmitting}>
                  <FormattedMessage id={button} />
                </ProgressDeleteButton>
                <Button onClick={onCancel} fullWidth variant={'outlined'} className={classes.closeButton}>
                  <FormattedMessage id="cancel" />
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
}

RemoveSupplierModalComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  blurb: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  supplierId: PropTypes.number.isRequired,
  jobId: PropTypes.number.isRequired,
  reasons: PropTypes.arrayOf(PropTypes.object).isRequired,
  modalOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

RemoveSupplierModalComponent.defaultProps = {
  onSubmit: () => {},
};

function RemoveSupplierModal(props) {
  const { whosDoingRemoving, ...rest } = props;

  const args = {
    header: 'communications.comms.remove.title',
    blurb: 'communications.comms.remove.blurb',
    button: 'communications.comms.remove.button',
    reasons: [
      { id: '1', label: 'Project no longer going ahead' },
      { id: '2', label: 'Timelines have changed' },
      { id: '3', label: 'Costings too high' },
      { id: '4', label: 'Prefer not to say' },
    ],
  };

  if (whosDoingRemoving === 'supplier') {
    args.header = 'communications.comms.leave.title';
    args.blurb = 'communications.comms.leave.blurb';
    args.button = 'communications.comms.leave.button';
    args.reasons = [
      { id: '1', label: 'No longer available' },
      { id: '2', label: 'Unable to complete work within client deadline' },
      { id: '3', label: 'Unable to complete work within client budget' },
      { id: '4', label: 'Prefer not to say' },
    ];
  }

  return <RemoveSupplierModalComponent {...rest} {...args} />;
}

export function useModal(initialValue) {
  const [modalOpen, setModalOpen] = useState(initialValue);
  return [modalOpen, setModalOpen];
}

RemoveSupplierModal.propTypes = {
  classes: PropTypes.object.isRequired,
  whosDoingRemoving: PropTypes.oneOf(['supplier', 'client']),
};

RemoveSupplierModal.defaultProps = {
  modalOpen: false,
  whosDoingRemoving: 'client',
};

export default withTheme()(withStyles(styles)(injectIntl(RemoveSupplierModal)));
