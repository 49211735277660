import React from 'react';
import { useContext, useState, useEffect } from 'react';
import HeaderPage from '../Pages/HeaderPage';
import SearchResultList from '../ClientSearch/SearchResultList';
import { ShortlistContext } from '../Shortlist/ShortlistContext';
import ProgressButton from '../FormFields/ProgressButton';
import Divider from '../FormFields/Divider';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import NoResultsFound from './NoResultsFound';
import { withRouter } from 'react-router-dom';

const styles = {
  button: {
    marginTop: 8,
  },
};

function ShortlistPage(props) {
  const { classes, intl, history } = props;
  const { shortlistedSuppliers } = useContext(ShortlistContext);
  const [shortlist, setShortlist] = useState([]);

  useEffect(() => {
    const tempShortlistedSuppliers = shortlistedSuppliers.map(r => {
      r.shortlisted = true;
      return r;
    });
    setShortlist(tempShortlistedSuppliers);
  }, [shortlistedSuppliers]);

  return (
    <HeaderPage headerText={'client.shortlist.header'} subtitleText={'client.shortlist.sub-header'}>
      <SearchResultList searchResults={shortlist} noResultsComponent={<NoResultsFound />} action="trash">
        {shortlist.length ? (
          <>
            <Divider />
            <ProgressButton
              type="submit"
              className={classes.button}
              onClick={() => {
                history.push({ pathname: '/project/brief' });
              }}
            >
              {intl.formatMessage({
                id: 'client.shortlist.prose',
              })}
            </ProgressButton>
          </>
        ) : null}
      </SearchResultList>
    </HeaderPage>
  );
}

export default withStyles(styles)(withRouter(injectIntl(ShortlistPage)));
