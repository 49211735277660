import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import HeaderPageLinkBack from '../Pages/HeaderPageLinkBack';
import { Paper, Typography, Chip, Button } from '@material-ui/core';
import { getSow } from '../Api/SOW';
import get from 'lodash/get';
import { fetchCurrencies } from '../Api/FetchAndCache';
import Progress from '../FormFields/Progress';
import SowComponent from '../Projects/Components/SowComponent';
import Divider from '../FormFields/Divider';
import { Link } from 'react-router-dom';
import { ProfileContext } from '../Profile/ProfileContext';
import { injectIntl, FormattedMessage } from 'react-intl';
import SupplierPaymentDetails from './components/SupplierPaymentDetails';
import ClientPaymentDetails from './components/ClientPaymentDetails';
import SignSOWModal, { useModal } from './components/SignSOWModal';
import { validationSchema, convertSowToValues } from './SowEditPage';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
  },
  sowComponent: {
    padding: 0,
    paddingTop: 16,
  },
});

export function SowPage(props) {
  const { classes, match, intl, forceScreen } = props;
  const commId = Number(match.params.id);
  const [currencies, setCurrencies] = useState([]);
  const [sow, setSow] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const { userType } = useContext(ProfileContext);
  const [modalOpen, setModalOpen] = useModal(false);

  const fetchData = () => {
    getSow(commId).then(resp => {
      const lclSow = get(resp, 'data.data', {});
      setSow(lclSow);
      validationSchema.isValid(convertSowToValues(lclSow)).then(setIsValid);
    });
  };

  useEffect(() => {
    fetchData();
  }, [match.params.id]);

  useEffect(() => {
    fetchCurrencies().then(result => {
      setCurrencies(result.map(r => ({ key: r.id, label: r.symbol })));
    });
  }, []);

  if (currencies.length === 0 || !sow) {
    return (
      <HeaderPageLinkBack>
        <Paper style={{ padding: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Progress />
        </Paper>
      </HeaderPageLinkBack>
    );
  }
  return (
    <HeaderPageLinkBack>
      <SignSOWModal
        commId={commId}
        modalOpen={modalOpen}
        forceScreen={forceScreen}
        onCancel={() => {
          setModalOpen(false);
        }}
      />
      <Paper className={classes.root}>
        <Typography variant="h4">
          <FormattedMessage id="projects.sow.page.header" />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage id="projects.sow.page.prose" />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage id="projects.sow.page.prose-2" />
        </Typography>
        <Chip
          style={{ margin: 16, color: '#6200EA', backgroundColor: '#EDE7F6' }}
          label={intl.formatMessage({
            id: 'projects.sow.page.chip',
          })}
        />
        <Divider />
        <SowComponent sow={sow} currencies={currencies} className={classes.sowComponent} />
        {sow.final || (userType === 'client' && sow.version === 1) ? (
          ''
        ) : (
          <>
            <Divider />
            <Button
              fullWidth
              component={Link}
              to={`/sow/${commId}/edit`}
              variant="outlined"
              style={{ marginTop: 24, marginBottom: 24 }}
            >
              <FormattedMessage id="projects.sow.page.edit" />
            </Button>
            <Divider />
            {isValid ? (
              whichPayment(userType, setModalOpen, commId)
            ) : (
              <Typography variant="h6" style={{ marginTop: 16 }}>
                <FormattedMessage id="projects.sow.page.not-ready" />
              </Typography>
            )}
          </>
        )}
      </Paper>
    </HeaderPageLinkBack>
  );
}

function whichPayment(userType, setModalOpen, commId) {
  if (userType === 'supplier') {
    return (
      <SupplierPaymentDetails
        commId={commId}
        onSubmit={(values, actions) => {
          setModalOpen(true);
        }}
      />
    );
  } else {
    return (
      <ClientPaymentDetails
        commId={commId}
        onSubmit={(values, actions) => {
          setModalOpen(true);
        }}
      />
    );
  }
}

export default withStyles(styles)(withRouter(injectIntl(SowPage)));
