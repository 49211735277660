import React, { useState, useEffect } from 'react';
import Progress from 'FormFields/Progress';
import PropTypes from 'prop-types';
import { useThrottle } from 'use-throttle';

export default function InfiniteLoader(props) {
  const { ListChild, loadMore, currentPage, endPage, initialised, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const throttledLoading = useThrottle(loading, 1000);

  useEffect(() => {
    window.addEventListener('scroll', onWindowEvent);
    window.addEventListener('resize', onWindowEvent);
    return () => {
      window.removeEventListener('scroll', onWindowEvent);
      window.removeEventListener('resize', onWindowEvent);
    };
  });

  useEffect(() => {
    if (!initialised && !loading) {
      setLoading(true);
    }
  }, [initialised]);

  useEffect(() => {
    throttledLoading &&
      loadMore()
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
  }, [throttledLoading]);

  const onWindowEvent = () => {
    if (shouldLoadMoreItems()) {
      setLoading(true);
    }
  };

  const shouldLoadMoreItems = function() {
    return currentPage < endPage && !loading && window.innerHeight + window.pageYOffset >= document.body.offsetHeight;
  };

  return (
    <ListChild {...rest} loading={loading}>
      {loading && <Progress />}
    </ListChild>
  );
}

InfiniteLoader.propTypes = {
  ListChild: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
};

export function usePagination() {
  const [currentPage, setCurrentPage] = useState(0);
  const [endPage, setEndPage] = useState(1);
  return [currentPage, setCurrentPage, endPage, setEndPage];
}
