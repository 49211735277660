const statuses = {
  pending_sow: 4,
  in_progress: 5,
  done: 8,
  end_project_request: 9,
  end_project_client_accepted: 10,
  end_project_client_rejected: 11,
};
export { statuses };
export default statuses;
