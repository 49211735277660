import React from 'react';
import Typography from '@material-ui/core/Typography';
import ratesIcon from '../../assets/Icon-Rates.svg';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing.unit,
    '&> p': {
      marginLeft: theme.spacing.unit,
    },
  },
  ratesIcon: {
    height: 24,
    width: 24,
  },
});

function Rate(props) {
  const { classes, skill, currency } = props;

  return (
    <>
      {skill.rate ? (
        <div className={classes.root}>
          <img src={ratesIcon} alt="" className={classes.ratesIcon} />
          <Typography variant="body2" color="textPrimary">
            {currency}
            {skill.rate} / day
          </Typography>
        </div>
      ) : null}
    </>
  );
}

export default withStyles(styles)(Rate);
