import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import ListItem from '@material-ui/core/ListItem';
import { FormattedMessage } from 'react-intl';
import AvatarStack from './AvatarStack';
import ProjectStatusChip from './ProjectStatusChip';
import { ProfileContext } from '../../Profile/ProfileContext';
import { Link } from 'react-router-dom';

const styles = theme => ({
  title: {},
  titleHeader: {
    marginRight: theme.spacing.unit,
  },
  card: {
    flexDirection: 'column',
  },
  cardHeaderRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  createdAtSection: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  itemLink: {
    textDecoration: 'none',
  },
});

function ProjectCard(props) {
  const { item, classes } = props;
  const description = item.description.length > 150 ? `${item.description.substring(0, 150)}...` : item.description;
  const { userProfile, userType } = useContext(ProfileContext);

  return (
    <ListItem className={classes.card}>
      <CardHeader
        classes={{ root: classes.cardHeaderRoot, content: classes.cardHeader }}
        disableTypography
        title={
          <>
            <Link className={classes.itemLink} to={`/project/${item.id}`}>
              <Typography variant="h6" className={classes.titleHeader}>
                {item.title}
              </Typography>
            </Link>
            <ProjectStatusChip status={item.status} />
          </>
        }
      />
      <CardContent>
        <Typography variant="body1">{description}</Typography>
        <Typography variant="caption" className={classes.createdAtSection}>
          <FormattedMessage id="projects.list.create" values={{ date: item.created_at }} />
        </Typography>
        <AvatarStack items={userType === 'supplier' ? [userProfile] : item.shortlist.suppliers} size="tiny" />
      </CardContent>
    </ListItem>
  );
}

export default withStyles(styles)(ProjectCard);
