import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Select,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import { injectIntl, FormattedMessage } from 'react-intl';
import TextInput from '../FormFields/TextInput';
import LimitedLengthTextInput from '../FormFields/LimitedLengthTextInput';
import Divider from '../FormFields/Divider';
import ChipSelect from '../FormFields/ChipSelect';
import InvoicingMilestones from './components/InvoicingMilestones';
import ProgressButton from '../FormFields/ProgressButton';
import InvoicingMilestonesWithDate from './components/InvoicingMilestonesWithDate';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 3,
  },
  dateInputs: {
    width: '48%',
  },
  quoteInput: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  divider: {
    marginTop: 16,
    marginBottom: 16,
  },
  costRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

function SowEditForm(props) {
  const {
    values,
    touched,
    errors,
    // dirty,
    isSubmitting,
    isValid,
    handleChange,
    handleBlur,
    // handleSubmit,
    // handleReset,
    setFieldValue,
    classes,
    intl,
    currencies,
  } = props;
  const [grossCost, setGrossCost] = useState(0);
  const [netCost, setNetCost] = useState(0);
  const [jpFee, setJpFee] = useState(0);
  const [currency, setCurrency] = useState('');

  useEffect(() => {
    if (values.billingType === '1') {
      setGrossCost(
        values.milestones.reduce((accumulator, milestone) => Number(accumulator) + Number(milestone.cost), 0),
      );
    } else {
      setGrossCost(
        Number(values.quote) *
          values.milestones.reduce((accumulator, milestone) => Number(accumulator) + Number(milestone.days), 0),
      );
    }
  }, [values.milestones, values.quote, values.billingType]);

  useEffect(() => {
    setJpFee(grossCost * 0.18);
    setNetCost(grossCost - grossCost * 0.18);
  }, [grossCost]);

  useEffect(() => {
    const cur = currencies.find(c => c.key === Number(values.quoteCurrency));
    setCurrency(cur ? cur.label : '');
  }, [values.quoteCurrency]);

  return (
    <form onSubmit={props.handleSubmit} className={classes.root}>
      <Typography variant="h4" color="inherit">
        <FormattedMessage id="projects.sow.header" />
      </Typography>
      <Typography variant="body2" color="inherit">
        <FormattedMessage id="required-fields.prose" />
      </Typography>
      <Typography variant="body2" color="inherit" className={classes.divider}>
        <FormattedMessage id="projects.sow.prose" />
      </Typography>
      <Divider />
      <Typography variant="h6" color="inherit" style={{ marginTop: 40, marginBottom: 16 }}>
        <FormattedMessage id="projects.sow.background" />
      </Typography>
      <TextInput
        fullWidth
        required
        margin="normal"
        name="title"
        value={values.title}
        placeholder="projects.sow.title.placeholder"
        label="projects.sow.title.label"
        helperText={touched.title && errors.title ? errors.title : null}
        error={Boolean(touched.title && errors.title)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <LimitedLengthTextInput
        name="description"
        required
        value={values.description}
        placeholder="projects.sow.description.placeholder"
        label="projects.sow.description.label"
        helperText={touched.description && errors.description ? errors.description : null}
        error={Boolean(touched.description && errors.description)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
        multiline
        rows="4"
        maxLength={500}
      />
      <Typography variant="h6" color="inherit" style={{ marginTop: 40, marginBottom: 16 }}>
        <FormattedMessage id="projects.sow.billing-schedule" />
      </Typography>
      <div style={{ justifyContent: 'space-between', display: 'flex', marginTop: 24, marginBottom: 24 }}>
        <TextInput
          className={classes.dateInputs}
          name="startdate"
          required
          type="date"
          value={values.startdate}
          placeholder="projects.sow.startdate.placeholder"
          label="projects.sow.startdate.label"
          helperText={touched.startdate && errors.startdate ? errors.startdate : null}
          error={Boolean(touched.startdate && errors.startdate)}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextInput
          className={classes.dateInputs}
          name="deadline"
          required
          type="date"
          value={values.deadline}
          placeholder="projects.sow.deadline.placeholder"
          label="projects.sow.deadline.label"
          helperText={touched.deadline && errors.deadline ? errors.deadline : null}
          error={Boolean(touched.deadline && errors.deadline)}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <Select
        native
        name="billingType"
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
        value={values.billingType}
        placeholder="projects.sow.billing-schedule.placeholder"
        label="projects.sow.billing-schedule.label"
        input={<OutlinedInput labelWidth={0} name="budget-type" id="budget-type-select" />}
      >
        {[
          { id: 1, name: intl.formatMessage({ id: 'projects.sow.billing-schedule.milestones' }) },
          { id: 2, name: intl.formatMessage({ id: 'projects.sow.billing-schedule.monthly' }) },
        ].map(billingType => (
          <option value={billingType.id} key={billingType.id}>
            {billingType.name}
          </option>
        ))}
      </Select>
      {values.billingType === '2' ? (
        <TextInput
          fullWidth
          required
          type="number"
          margin="normal"
          name="quote"
          value={values.quote}
          placeholder="projects.sow.day-rate.placeholder"
          label="projects.sow.day-rate.label"
          helperText={touched.quote && errors.quote ? errors.quote : null}
          error={Boolean(touched.quote && errors.quote)}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isSubmitting}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            className: classes.quoteInput,
            // endAdornment: <InputAdornment position="end">per day</InputAdornment>,
            startAdornment: (
              <InputAdornment position="start">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="quoteCurrency" shrink={true}>
                    <FormattedMessage id="projects.sow.day-rate.placeholder" />
                  </InputLabel>
                  <Select
                    native
                    name="quoteCurrency"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isSubmitting}
                    value={values.quoteCurrency}
                    label="Day rate"
                    input={<OutlinedInput labelWidth={58} name="quoteCurrency" id="currency-select" label="Day rate" />}
                  >
                    {currencies.map(currency => (
                      <option value={currency.key} key={currency.key}>
                        {currency.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </InputAdornment>
            ),
          }}
        />
      ) : null}
      <Typography variant="h6" color="inherit" style={{ marginTop: 40, marginBottom: 16 }}>
        <FormattedMessage id="projects.sow.deliverables.header" />
      </Typography>
      <Typography variant="body2" style={{ marginBottom: 16 }}>
        <FormattedMessage id="projects.sow.deliverables.prose" />
      </Typography>
      <ChipSelect
        storedValues={values.deliverables}
        onChange={setFieldValue}
        onBlur={handleBlur}
        disabled={isSubmitting}
        name="deliverables"
        InputProps={{ name: 'deliverables', color: 'primary' }}
        placeholder="projects.sow.deliverables.placeholder"
        selectTextFieldProps={{
          variant: 'outlined',
          label: intl.formatMessage({
            id: 'projects.sow.deliverables.label',
          }),
          InputLabelProps: {
            shrink: true,
          },
        }}
      />
      <Typography variant="h6" color="inherit" style={{ marginTop: 40, marginBottom: 16 }}>
        <FormattedMessage id="projects.sow.invoicing-milestones" />
      </Typography>
      {values.billingType === '2' ? (
        <InvoicingMilestones required name="milestones" milestones={values.milestones} />
      ) : (
        <>
          <Select
            native
            name="quoteCurrency"
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSubmitting}
            value={values.quoteCurrency}
            placeholder="projects.sow.day-rate.placeholder"
            label="projects.sow.day-rate.placeholder"
            input={<OutlinedInput labelWidth={0} name="budget-type" id="budget-type-select" />}
          >
            {currencies.map(currency => (
              <option value={currency.key} key={currency.key}>
                {currency.label}
              </option>
            ))}
          </Select>
          <InvoicingMilestonesWithDate
            required
            name="milestones"
            milestones={values.milestones}
            currencies={currencies}
          />
        </>
      )}
      <br />
      {values.billingType === '2' ? (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!values.hasDayCapp}
                name="hasDayCapp"
                onChange={handleChange}
                onBlur={handleBlur}
                color="primary"
                variant="outlined"
              />
            }
            label={
              <Typography variant="body2" style={{ color: 'black' }}>
                {intl.formatMessage({
                  id: 'projects.sow.has-day-capp.label',
                })}
                *
              </Typography>
            }
          />
          {!!values.hasDayCapp ? (
            <TextInput
              fullWidth
              required
              type="number"
              margin="normal"
              name="dayCapp"
              value={values.dayCapp}
              placeholder="projects.sow.day-cap.placeholder"
              label="projects.sow.day-cap.label"
              helperText={touched.dayCapp && errors.dayCapp ? errors.dayCapp : null}
              error={Boolean(touched.dayCapp && errors.dayCapp)}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSubmitting || !values.hasDayCapp}
            />
          ) : null}
        </>
      ) : null}
      <Divider className={classes.divider} />
      <div className={classes.costRow}>
        <Typography variant="body2">
          <FormattedMessage id="projects.sow.costs.gross-cost" />
        </Typography>
        <Typography variant="body2">
          {currency}
          {grossCost}
        </Typography>
      </div>
      <div className={classes.costRow}>
        <Typography variant="body2">
          <FormattedMessage id="projects.sow.costs.jp-fee" />
        </Typography>
        <Typography variant="body2">
          {currency}
          {jpFee}
        </Typography>
      </div>
      <Typography variant="caption" style={{ textAlign: 'end' }}>
        <FormattedMessage id="projects.sow.costs.jp-fee.vat" />
      </Typography>
      <div className={classes.costRow}>
        <Typography variant="body2" style={{ display: 'flex', alignSelf: 'flex-end' }}>
          <FormattedMessage id="projects.sow.costs.net-cost" />
        </Typography>
        <Typography variant="h4">
          {currency}
          {netCost}
        </Typography>
      </div>
      <Typography variant="caption" style={{ textAlign: 'end' }}>
        <FormattedMessage id="projects.sow.costs.net-cost.vat" />
      </Typography>
      <Divider className={classes.divider} />
      <FormControlLabel
        control={
          <Checkbox
            checked={!!values.sowLegal}
            name="sowLegal"
            onChange={handleChange}
            onBlur={handleBlur}
            color="primary"
            variant="outlined"
          />
        }
        label={
          <Typography variant="body2" style={{ color: 'black' }}>
            {intl.formatMessage({
              id: 'projects.sow.legal.label',
            })}
            *
          </Typography>
        }
      />
      <ProgressButton
        type="submit"
        buttonBusy={isSubmitting}
        disabled={!isValid}
        submitting={isSubmitting}
        data-testid="submit-button"
      >
        {intl.formatMessage({ id: 'projects.sow.button' })}
      </ProgressButton>
    </form>
  );
}

export default withStyles(styles)(injectIntl(SowEditForm));
