import React, { useState, useContext, useRef, useEffect } from 'react';
import { ShortlistContext } from '../Shortlist/ShortlistContext';

import { Formik } from 'formik';
import * as Yup from 'yup';
import ProjectBriefForm from './ProjectBriefForm';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import HeaderPageLinkBack from '../Pages/HeaderPageLinkBack';
import Paper from '@material-ui/core/Paper';
import { saveProject } from '../Api/Jobs';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing.unit * 2,
      paddingLeft: theme.spacing.unit * 3,
      paddingRight: theme.spacing.unit * 3,
      paddingBottom: theme.spacing.unit,
    },
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 10,
    display: 'flex',
    flexDirection: 'column',
  },
});

export function transformValuesToProjectBrief(values) {
  const data = {
    title: values.title,
    description: values.description,
    remote: values.location,
    tasks: values.tasks.map(t => t.trim()).filter(Boolean),
    deadline: values.deadline,
    duration: values.duration,
    budget: values.budget,
    quoteType: values.budgetType,
    jobFiles: values.files.map(f => {
      return f.id;
    }),
  };

  return data;
}

const validationSchema = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required('project-brief.title.required')
    .min(1),
  description: Yup.string()
    .trim()
    .required('project-brief.description.required')
    .min(1),
  location: Yup.string()
    .trim()
    .required('project-brief.location.required')
    .min(1),
  tasks: Yup.array()
    .required('project-brief.tasks.required')
    .ensure()
    .min(1)
    .test('tasksLength', 'project-brief.tasks.required', values => values.filter(v => v.trim().length).length),
  deadline: Yup.string()
    .trim()
    .required('project-brief.deadline.required')
    .min(1),
  duration: Yup.string()
    .trim()
    .required('project-brief.duration.required')
    .min(1),
  budget: Yup.number()
    .required('project-brief.budget.required')
    .positive('project-brief.budget.required')
    .min(1),
  budgetType: Yup.string()
    .trim()
    .required('project-brief.budget-type.required')
    .min(1),
  files: Yup.array()
    .required('project-brief.files.required')
    .min(1),
  shortlistId: Yup.number().min(1),
});

function ProjectBrief(props) {
  const { classes, enqueueSnackbar, history } = props;
  const { shortlistedSuppliers, shortlistId, retrieveShortlist } = useContext(ShortlistContext);
  const form = useRef(null);
  const [values, setValues] = useState({
    title: '',
    description: '',
    location: 0,
    tasks: [],
    deadline: '',
    duration: '',
    budget: '',
    budgetType: 1,
    files: [],
    shortlistId: shortlistId,
  });
  useEffect(() => {
    setValues(prevVals => {
      return { ...prevVals, shortlistId: shortlistId };
    });
  }, [shortlistId]);
  return (
    <HeaderPageLinkBack>
      <Paper className={classes.root}>
        <Formik
          ref={form}
          render={props => <ProjectBriefForm {...props} suppliers={shortlistedSuppliers} />}
          initialValues={values}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            saveProject({ ...transformValuesToProjectBrief(values), shortlist: shortlistId })
              .then(() => {
                setValues(values);
                return retrieveShortlist().then(() => {
                  enqueueSnackbar(<FormattedMessage id="project-brief.saved" />);
                  history.push('/dashboard');
                });
              })
              .finally(() => actions.setSubmitting(false));
          }}
        />
      </Paper>
    </HeaderPageLinkBack>
  );
}

export default withStyles(styles)(withSnackbar(withRouter(ProjectBrief)));
