import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import uniqueId from 'lodash/uniqueId';
import { withSnackbar } from 'notistack';

import HeaderPage from '../Pages/HeaderPage';
import { fetchSupplierProfile, fetchProjects, updateProjects } from '../Api/FetchAndCache';
import { deleteProject } from '../Api/Projects';
import DeletionModal, { useModal } from '../Modals/DeletionModal';

const styles = theme => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing.unit * 5,
  },
  list: {
    width: '100%',
  },
  itemText: {
    paddingRight: theme.spacing.unit * 10,
  },
  paper: {
    marginTop: theme.spacing.unit * 3,
  },
});
const skillsStyles = theme => ({
  chip: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

function PortfolioList(props) {
  const { classes, enqueueSnackbar } = props;
  const [storedProjects, setStoredProjects] = useState([]);
  const [modalOpen, setModalOpen] = useModal(false);
  const [deletionItem, setDeletionItem] = useState({});
  const [supplierSkills, setSupplierSkills] = useState([]);

  const fetchData = () => {
    fetchSupplierProfile().then(supplier => {
      setSupplierSkills(supplier.skills);
    });
    fetchProjects().then(projects => {
      setStoredProjects(projects);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <HeaderPage headerText="portfolio.header" subtitleText="portfolio.subtitle">
      <DeletionModal
        itemDeletionProse="portfolio.delete.prose"
        onDelete={e => {
          deleteProject(deletionItem.id).then(() => {
            updateProjects().then(projects => {
              setStoredProjects(projects);
              setModalOpen(false);
              enqueueSnackbar(<FormattedMessage id="portfolio.deleted" />);
            });
          });
        }}
        onCancel={e => {
          setModalOpen(false);
        }}
        modalOpen={modalOpen}
        itemDeletionLabel={get(deletionItem, 'title', '')}
      />
      <Typography variant="h5" color="inherit">
        <FormattedMessage id="portfolio.my" />
      </Typography>
      <Paper className={classes.paper} elevation={1}>
        <List className={classes.list}>
          {storedProjects.map((project, idx) => {
            return (
              <React.Fragment key={uniqueId()}>
                <ListItem className={classes.item} alignItems="flex-start">
                  <ListItemText
                    className={classes.itemText}
                    primary={project.title}
                    secondaryTypographyProps={{ component: 'div' }}
                    secondary={
                      <React.Fragment>
                        <p>{project.bio}</p>
                        <StyledSkillsList skillIds={project.skills} skills={supplierSkills} />
                      </React.Fragment>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Link className={classes.itemLink} to={`/portfolio/${project.id}`}>
                      <IconButton aria-label="Edit">
                        <EditIcon />
                      </IconButton>
                    </Link>
                    <IconButton
                      aria-label="delete"
                      onClick={e => {
                        setDeletionItem(project);
                        setModalOpen(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {idx < storedProjects.length - 1 && <Divider component="li" />}
              </React.Fragment>
            );
          })}
          <Divider />
          <ListItem className={classes.item} alignItems="flex-start">
            <Button fullWidth variant="contained" to="/portfolio" color="primary" component={Link}>
              {props.intl.formatMessage({
                id: 'portfolio.add',
              })}
            </Button>
          </ListItem>
        </List>
      </Paper>
    </HeaderPage>
  );
}
function SkillsList(props) {
  const { classes, skillIds, skills } = props;

  return skillIds.map(skillId => {
    const skill = skills.find(s => s.id === skillId);
    return <Chip label={skill.name} className={classes.chip} variant={'outlined'} key={skill.id} color="primary" />;
  });
}
const StyledSkillsList = withStyles(skillsStyles)(SkillsList);
export default withStyles(styles)(withSnackbar(injectIntl(PortfolioList)));
