import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SmallAvatar } from '../../SupplierProfile/SupplierAvatar';
import { distanceInWordsToNow } from 'date-fns';
import { Typography, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
  root: {
    padding: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    flexDirection: 'row',
    transition: 'background-color 1000ms',
    backgroundColor: 'inherit',
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginRight: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit * 3,
  },
  senderMessage: {
    backgroundColor: '#FAC446',
    borderRadius: 4,
    minHeight: theme.spacing.unit * 5,
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit,
  },
  fileLink: {
    textDecoration: 'none',
  },
  messageDate: {
    alignSelf: 'flex-end',
  },
  newMessage: {
    backgroundColor: '#FAC446',
  },
  newSenderMessage: {
    backgroundColor: '#f5f5f5',
  },
  attachments: {
    marginBottom: theme.spacing.unit * 2,
  },
});

function SenderMessage(props) {
  const { classes, message, senderProfile, newMessage, setNewMessageNeedsReading } = props;
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (newMessage) {
      setTimeout(() => {
        setIsNew(true);
      }, 500);
      setTimeout(() => {
        setNewMessageNeedsReading(false);
        setIsNew(false);
      }, 1000);
    }
  }, [newMessage]);

  return (
    <div
      className={classNames(classes.root, {
        [classes.newMessage]: isNew,
      })}
    >
      <div className={classes.messageContent}>
        <div
          className={classNames(classes.senderMessage, {
            [classes.newSenderMessage]: isNew,
          })}
        >
          <Typography variant="body2">{message.message}</Typography>
        </div>
        {props.message.files && props.message.files.length > 0 && (
          <div className={classes.attachments}>
            <Typography variant="caption">
              <FormattedMessage id="communications.comms.message-box.attachments" />
            </Typography>
            {props.message.files.map((t) => (
              <a
                key={t.filename}
                href={t.fullUrl}
                target="_blank"
                rel="noreferrer noopener"
                className={classes.fileLink}
              >
                <Typography variant="caption">{JSON.parse(t.properties).originalName}</Typography>
              </a>
            ))}
          </div>
        )}
        <div className={classes.messageDate}>
          <Tooltip title={message.created_at}>
            <Typography variant="caption">{distanceInWordsToNow(new Date(message.created_at))}</Typography>
          </Tooltip>
        </div>
      </div>
      <SmallAvatar item={senderProfile} />
    </div>
  );
}

export default withStyles(styles)(SenderMessage);
