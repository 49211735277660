import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const statusArray = [
  {
    label: 'supplier-profile.available',
    key: 1,
  },
  {
    label: 'supplier-profile.not-available',
    key: 2,
  },
  {
    label: 'supplier-profile.busy',
    key: 3,
  },
];

const styles = (theme) => ({
  root: {
    display: 'flex',
    padding: 4,
    paddingRight: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    borderRadius: 2,
  },
  busy: {
    color: '#670114',
    backgroundColor: '#FFE5EA',
  },
  available: {
    color: '#007456',
    backgroundColor: '#F1FBFB',
  },
});
function Availability(props) {
  const { item, classes } = props;
  return (
    <Typography
      variant="caption"
      className={classNames(classes.root, {
        [classes.busy]: item.status.id === 3,
        [classes.available]: item.status.id !== 3,
      })}
    >
      <FormattedMessage id={statusArray.find((wa) => wa.key === item.status.id).label} />
    </Typography>
  );
}
Availability.propTypes = {
  classes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};
export default withStyles(styles)(Availability);
