import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import TextInput from './TextInput';

function LimitedLengthTextInput(props) {
  const { classes, maxLength, value, className, ...rest } = props;
  const [characterCount, setCharacterCount] = useState(0);

  useEffect(() => {
    setCharacterCount(value.length);
  }, [value]);

  return (
    <div className={className}>
      <Typography variant="caption" align="right">
        {characterCount}/{maxLength}
      </Typography>
      <TextInput {...rest} value={value} fullWidth margin="normal" inputProps={{ maxLength: maxLength }} />
    </div>
  );
}
LimitedLengthTextInput.propTypes = {
  maxLength: PropTypes.number,
};
export default LimitedLengthTextInput;
