import React from 'react';
import { useState, useRef } from 'react';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import RootRef from '@material-ui/core/RootRef';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit,
    flexDirection: 'column',
  },
  popper: {
    zIndex: 9000,
  },
});
function PopoverDisplay(props) {
  const { classes, className, clickableItem, children } = props;
  const [open, setOpen] = useState(false);
  const refElement = useRef(null);

  return (
    <>
      <RootRef rootRef={refElement}>{clickableItem(setOpen, open)}</RootRef>

      <Popper
        className={classNames(classes.popper, className)}
        anchorEl={refElement.current}
        placement="bottom-end"
        open={open}
        disablePortal
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} id="menu-list-grow">
            <ClickAwayListener
              onClickAway={e => {
                if (refElement.current.contains(e.target)) {
                  return;
                }
                if (open) {
                  setOpen(false);
                }
              }}
            >
              <Paper className={classes.paper}>{children}</Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default withStyles(styles)(PopoverDisplay);
