import axios from 'axios';

export function getSow(id) {
  return axios.get(`/api/sows/${id}`);
}

export function putSow(id, body) {
  return axios.put(`/api/comms/${id}/save-sow`, body);
}

export function sendSigning(id, data) {
  return axios.post(`/api/sows/sign/${id}`, data);
}
