import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as NoResultsFoundImage } from '../assets/Jobs-Empty.svg';

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const styles = theme => ({
  root: {
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
    paddingLeft: theme.spacing.unit * 10,
    paddingRight: theme.spacing.unit * 8,
    paddingTop: theme.spacing.unit * 5,
    paddingBottom: theme.spacing.unit * 5,
  },
  header: {
    marginTop: theme.spacing.unit * 2,
  },
  body: {
    marginTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    display: 'flex',
    textAlign: 'center',
  },
});

function NoResultsFound(props) {
  const { classes } = props;
  return (
    <Card data-testid="no-search-results-page" className={classes.root} elevation={0}>
      <NoResultsFoundImage />
      <Typography gutterBottom variant="h5" className={classes.header}>
        <FormattedMessage id="client.find.search.no-result.header" />
      </Typography>
      <Typography gutterBottom variant="body2" className={classes.body}>
        <FormattedHTMLMessage id="client.find.search.no-result.body" values={{ mail: 'hello@jam-pan.com' }} />
      </Typography>
    </Card>
  );
}

export default withStyles(styles)(NoResultsFound);
