import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import HeaderPage from '../Pages/HeaderPage';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import uniqueId from 'lodash/uniqueId';
import { default as ClientProfileComponent } from '../ClientProfile/ClientProfile';
import UpdatePassword from './UpdatePassword';
import CompanyInformation from './CompanyInformation';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 5,
    display: 'flex',
  },
  paperTabBar: {
    padding: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
  },
  tabBar: {
    // width: '100%',
    display: 'flex',
    flexGrow: '1',
  },
  tab: {
    display: 'flex',
    flexGrow: '1',
  },
  paperTab: {
    display: 'flex',
    flexDirection: 'column',
  },
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}
const tabs = [
  { value: 'details', label: 'profile.personal', component: <ClientProfileComponent />, key: uniqueId() },
  { value: 'security', label: 'profile.security', component: <UpdatePassword />, key: uniqueId() },
  { value: 'company', label: 'profile.company', component: <CompanyInformation />, key: uniqueId() },
];

function Profile(props) {
  const { classes, history, match } = props;
  const value = match.params.page ? match.params.page : 'details';

  return (
    <HeaderPage headerText="profile.header" subtitleText="profile.subtitle">
      <Paper className={classes.paperTabBar} elevation={1}>
        <Tabs
          className={classes.tabBar}
          value={value}
          variant="fullWidth"
          onChange={(e, v) => {
            history.push({
              pathname: `/profile/${v}`,
            });
          }}
        >
          {tabs.map(tabInfo => {
            const label = (
              <Typography variant="body2" color="textPrimary" className={classes.tab} key={tabInfo.key}>
                <FormattedMessage id={tabInfo.label} />
              </Typography>
            );
            return <Tab value={tabInfo.value} label={label} key={tabInfo.key} />;
          })}
        </Tabs>
      </Paper>
      <Paper className={classes.paperTab} elevation={1}>
        {tabs.map(
          tabInfo => value === tabInfo.value && <TabContainer key={uniqueId()}>{tabInfo.component}</TabContainer>,
        )}
      </Paper>
    </HeaderPage>
  );
}

export default withStyles(styles)(withRouter(Profile));
