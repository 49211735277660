import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { distanceInWordsToNow } from 'date-fns';
import { SmallAvatar } from 'SupplierProfile/SupplierAvatar';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  menuList: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },
  paddedItem: {
    padding: theme.spacing.unit * 2,
  },
  unread: {
    backgroundColor: '#FFFDE9',
  },
  listItem: {
    alignItems: 'center',
  },
  listItemTextPrimary: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItemTextPrimaryName: {
    paddingRight: theme.spacing.unit * 2,
    flexGrow: 1,
  },
  listItemTextPrimaryDate: {
    paddingRight: theme.spacing.unit * 2,
    whiteSpace: 'nowrap',
  },
  listItemTextPrimaryDot: {
    backgroundColor: '#F08730',
    width: 12,
    height: 12,
    borderRadius: '50%',
  },
});

function NotificationsList(props) {
  const { items, classes, className, children } = props;
  return (
    <List className={className} component="nav">
      {items.map(item => {
        const toUrl = `/comms/${item.group_id}`;
        return (
          <ListItem
            key={item.id}
            component={Link}
            to={toUrl}
            button
            disableGutters
            className={classNames(classes.paddedItem, classes.listItem, { [classes.unread]: !item.read })}
          >
            <ListItemAvatar>
              <SmallAvatar item={item.senderProfile} />
            </ListItemAvatar>
            <ListItemText
              className={classes.listItemRoot}
              primary={
                <div className={classes.listItemTextPrimary}>
                  <Typography variant="h6" className={classes.listItemTextPrimaryName}>
                    {item.senderProfile.name}
                  </Typography>
                  <Typography variant="caption" className={classes.listItemTextPrimaryDate}>
                    {distanceInWordsToNow(new Date(item.updated_at))}
                  </Typography>
                  {!item.read ? <div className={classes.listItemTextPrimaryDot} /> : null}
                </div>
              }
              primaryTypographyProps={{ component: 'div' }}
              secondary={item.value}
              secondaryTypographyProps={{ component: 'div' }}
            />
          </ListItem>
        );
      })}
      {children}
    </List>
  );
}

export default withStyles(styles)(NotificationsList);
