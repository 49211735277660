import React from 'react';
import { useState, useEffect, useRef } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import SupplierProfileForm from './SupplierProfileForm';
import { withStyles } from '@material-ui/core/styles';
import { updateSupplierProfile } from '../Api/SupplierProfile';
import { updateLocalProfile } from '../Api/LocalStorage';
import { fetchCountries } from '../Api/FetchAndCache';
import { withSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import uniqueId from 'lodash/uniqueId';
import { withRouter } from 'react-router';
import { fetchSupplierProfile, fetchCurrencies } from '../Api/FetchAndCache';
import { endOfMonth, addMonths, eachDay, format, isWeekend } from 'date-fns';
import { uniqBy } from 'lodash';
import { getDayAfterWeekend } from './getDayAfterWeekend';

const styles = (theme) => ({
  root: {
    maxWidth: '1024px',
    '& > *': {
      marginBottom: theme.spacing.unit * 3,
      [theme.breakpoints.up('md')]: {
        marginBottom: theme.spacing.unit * 4,
      },
    },
  },
});

export function transformValuesToProfile(values) {
  const initialDay = getDayAfterWeekend(new Date());
  const lastDay = endOfMonth(addMonths(initialDay, 3));

  const data = {
    name: values.name,
    email: values.email,
    bio: values.bio,
    website: values.website,
    currency: values.currency,
    languagesValues: [
      ...values.languages.filter((l) => l.selected).map((l) => ({ level: l.level, selected: l.selected })),
    ],
    address1: values.address1,
    address2: values.address2,
    city: values.city,
    postalCode: values.postalCode,
    phone: values.phone,
    country: values.country,
    remote: values.remote,
    status: values.status,
    availability: values.availability.filter((event) => event.type === 'available').map((event) => event.start),
    last_availability_date: format(lastDay, 'YYYY-MM-DD'),
    msaAgreed: values.msaAgreed,
  };

  return data;
}

export function transformProfileToValues(profile, isBlindMode = false) {
  const firstEventDay = getDayAfterWeekend(new Date());

  // Array w/ "busy" event objects from first day (current day
  // or monday if weekend) until 3 months (excluding weekends / end of that month)
  const initialBusyDays = eachDay(firstEventDay, endOfMonth(addMonths(firstEventDay, 3)))
    .map((day) => ({
      start: format(day, 'YYYY-MM-DD'),
      end: format(day, 'YYYY-MM-DD'),
      display: isBlindMode ? 'auto' : 'background',
      title: isBlindMode ? 'Busy' : '',
      color: '#ff0000cf',
      type: 'busy',
    }))
    .filter((day) => !isWeekend(day.start));

  // Available days that come from the API source
  const availableDays = initialBusyDays
    .filter((day) => profile.availability.indexOf(day.start) !== -1)
    .map((availableDay) => ({
      ...availableDay,
      display: isBlindMode ? 'auto' : 'background',
      title: isBlindMode ? 'Available' : '',
      color: 'green',
      type: 'available',
    }));

  // Unique elements from all busy days merged w/ available days (API source)
  const fullCalendarDays = uniqBy([...availableDays, ...initialBusyDays], 'start');

  const data = {
    name: profile.name || '',
    name_old: profile.name_old || '',
    email: profile.email || '',
    bio: get(profile, 'bio.value', ''),
    website: profile.website || '',
    currency: get(profile, 'currency.id', ''),
    languages: get(profile, 'languages', []).map((language) => {
      return { selected: language.id, level: language.pivot.level, name: language.name, key: uniqueId() };
    }),
    address1: profile.address1 || '',
    address2: profile.address2 || '',
    city: profile.city || '',
    postalCode: profile.postalCode || '',
    phone: profile.phone || '',
    country: profile.country || -1,
    remote: profile.remote,
    status: profile.status ? profile.status.id : -1,
    availability: profile.availability ? fullCalendarDays : initialBusyDays,
    msaRead: profile.msaAgreed || false,
    msaAgreed: profile.msaAgreed || false,
    profilePics: profile.profilePic || [],
  };

  return data;
}

function SupplierProfile(props) {
  const { classes, enqueueSnackbar, forwardTo } = props;
  const form = useRef(null);
  const [countries, setCountries] = useState([]);
  const [currencies, setCurrencies] = useState([]);

  const [values, setValues] = useState({
    name: '',
    email: '',
    bio: '',
    website: '',
    currency: '',
    languages: [],
    fluency: '',
    address1: '',
    address2: '',
    city: '',
    postalCode: '',
    phone: '',
    country: '',
    status: '',
    availability: [],
    remote: '',
    msaAgreed: false,
    profilePics: [],
  });

  const [lastMsaFile, setLastMsaFile] = useState('');

  const validationSchema = Yup.object().shape({
    bio: Yup.string().trim().required('supplier-profile.bio.required').min(1),
    website: Yup.string().trim().website('supplier-profile.website.invalid'),
    address1: Yup.string().trim().required('supplier-profile.address1.required').min(1),
    city: Yup.string().trim().required('supplier-profile.city.required').min(1),
    postalCode: Yup.string().trim().required('supplier-profile.postcode.required').min(1),
    phone: Yup.string().trim().required('supplier-profile.telephone.required').min(1),
    country: Yup.string().trim().required('supplier-profile.country.required').min(1),
    remote: Yup.string().trim().required('supplier-profile.remote-working.required').min(1),
    status: Yup.string().trim().required('supplier-profile.availability.required').min(1),
    msaRead: Yup.bool()
      .required('supplier-profile.msa-agreed.label')
      .test('msaRead', 'supplier-profile.msa-read.label', (value) => value === true),
    msaAgreed: Yup.bool()
      .required('supplier-profile.msa-agreed.label')
      .test('msaAgreed', 'supplier-profile.msa-agreed.label', (value) => value === true),
    currency: Yup.string()
      .trim()
      .required('supplier-profile.currency.required')
      .test(
        'currency',
        'supplier-profile.currency.error-match2',
        (value) => currencies.map((c) => c.key.toString()).indexOf(value) !== -1,
      ),
  });
  const fetchData = () => {
    fetchCurrencies().then((result) => {
      setCurrencies(result.map((r) => ({ key: r.id, label: `${r.symbol} - ${r.name}` })));
    });

    fetchSupplierProfile().then((profile) => {
      if (form.current) {
        const formValues = transformProfileToValues(profile);
        form.current.initialValues = formValues;
        form.current.setValues(formValues);
      }
      setLastMsaFile(profile.last_msa_file);
    });

    fetchCountries().then((result) => {
      setCountries(result);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  function saveForLater(values) {
    updateSupplierProfile(transformValuesToProfile(values)).then((response) => {
      updateLocalProfile(get(response, 'data.data'));
      setValues(transformProfileToValues(get(response, 'data.data')));
      enqueueSnackbar(<FormattedMessage id="supplier-profile.saved" />);
    });
  }

  return (
    <div className={classes.root}>
      <Formik
        ref={form}
        render={(props) => (
          <SupplierProfileForm
            {...props}
            saveForLater={saveForLater}
            countries={countries}
            currencies={currencies}
            forwardTo={forwardTo}
            msaFile={lastMsaFile}
          />
        )}
        initialValues={values}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          updateSupplierProfile(transformValuesToProfile(values))
            .then((response) => {
              updateLocalProfile(get(response, 'data.data'));
              const isBlindMode =
                values.availability.length > 0 && values.availability[0].display === 'auto' ? true : false;
              setValues(transformProfileToValues(get(response, 'data.data'), isBlindMode));
              enqueueSnackbar(<FormattedMessage id="supplier-profile.saved" />);
              if (typeof forwardTo === 'string') {
                props.history.push({
                  pathname: forwardTo,
                  state: { email: get(response, 'data.data.email', '') },
                });
              }
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      />
    </div>
  );
}

SupplierProfile.defaultProps = {
  forwardTo: false,
};
export default withStyles(styles)(withSnackbar(withRouter(SupplierProfile)));
