import React, { useState } from 'react';
import get from 'lodash/get';
import {
  getGeneralShortlist,
  removeSupplierFromGeneralShortlist,
  addSupplierToGeneralShortlist,
} from '../Api/Shortlist';

function ShortlistProvider(props) {
  const { children, slSuppliers } = props;
  const [shortlistedSuppliers, setShortlistedSuppliers] = useState(slSuppliers);
  const [shortlistId, setShortlistId] = useState(0);
  const [tempShortlistedSuppliers, setTempShortlistedSuppliers] = useState([]);

  const retrieveShortlist = function() {
    return new Promise((resolve, reject) => {
      getGeneralShortlist()
        .then(results => {
          setShortlistId(get(results, 'data.data.id', 0));
          setShortlistedSuppliers(get(results, 'data.data.suppliers', []));
          resolve(get(results, 'data.data.suppliers', []));
        })
        .catch(e => {
          reject(e);
        });
    });
  };

  const shortlist = function(supplierId) {
    return new Promise((resolve, reject) => {
      if (shortlistedSuppliers.length >= 5) {
        return reject('client.shortlist.too-many');
      }
      if (shortlistedSuppliers.length + tempShortlistedSuppliers.length >= 5) {
        return reject('client.shortlist.saving');
      }
      setTempShortlistedSuppliers(preVals => [...preVals, supplierId]);
      addSupplierToGeneralShortlist(supplierId).then(response => {
        setShortlistId(get(response, 'data.data.id', 0));
        setTempShortlistedSuppliers(prevVals => prevVals.filter(pv => pv !== supplierId));
        setShortlistedSuppliers(get(response, 'data.data.suppliers', []));
        resolve(get(response, 'data.data.suppliers', []));
      });
    });
  };

  const delist = function(supplierId) {
    return new Promise((resolve, reject) => {
      removeSupplierFromGeneralShortlist(supplierId).then(response => {
        setShortlistedSuppliers(prevVals => prevVals.filter(pv => pv.id !== supplierId));
        resolve(response);
      });
    });
  };

  const synchlist = function(suppliersToRemove, suppliersToAdd) {
    const promises = [
      ...suppliersToAdd.map(sId => addSupplierToGeneralShortlist(sId)),
      ...suppliersToRemove.map(sId => removeSupplierFromGeneralShortlist(sId)),
    ];
    return Promise.all(promises).then(async () => {
      await retrieveShortlist();
    });
  };

  return (
    <ShortlistContext.Provider
      value={{
        shortlist: shortlist,
        delist: delist,
        shortlistedSuppliers: shortlistedSuppliers,
        shortlistId: shortlistId,
        retrieveShortlist: retrieveShortlist,
        synchlist: synchlist,
      }}
    >
      {children}
    </ShortlistContext.Provider>
  );
}

ShortlistProvider.defaultProps = {
  slSuppliers: [],
};

export const ShortlistContext = React.createContext();
export const ShortlistConsumer = ShortlistContext.Consumer;
export default ShortlistProvider;
