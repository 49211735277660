import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Formik } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import { withSnackbar } from 'notistack';
import { FormattedMessage, injectIntl } from 'react-intl';
import PortfolioForm from './PortfolioForm';
import { saveProject, updateProject } from '../Api/Projects';
import { fetchSupplierProfile, fetchProjects, updateProjects } from '../Api/FetchAndCache';
import DeletionModal, { useModal } from '../Modals/DeletionModal';
import { deleteProject } from '../Api/Projects';
import HeaderPageLinkBack from '../Pages/HeaderPageLinkBack';

export const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required('supplier-profile.project-title.required').min(1),
  bio: Yup.string().trim().required('supplier-profile.project-bio.required').min(1),
  url: Yup.string().trim().website('supplier-profile.website.invalid'),
  skills: Yup.array().required('supplier-profile.skills.required').min(1),
  files: Yup.array().required('supplier-profile.files.required').min(1),
});

const styles = (theme) => ({
  root: {
    maxWidth: '1024px',
    alignItems: 'center',
  },
  paperForm: {
    display: 'flex',
    padding: theme.spacing.unit * 2,
    // marginTop: '100px',
    [theme.breakpoints.down('md')]: {
      // margin: theme.spacing.unit * 2,
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing.unit * 3,
      width: '700px',
    },
    flexDirection: 'column',
    border: '1px solid #CCCBC8',
    alignItems: 'center',
  },
  topHeader: {
    marginTop: theme.spacing.unit * 5,
    marginBottom: theme.spacing.unit * 3,
  },
  midHeader: {
    paddingBottom: theme.spacing.unit * 4,
    textAlign: 'center',
  },
  deleteButton: {
    backgroundColor: '#B00020',
    color: 'white',
  },
});

export function transformValuesToProject(values) {
  const data = {
    name: values.title,
    bio: values.bio,
    website: values.url,
    projectFiles: values.files.map((f) => {
      return f.id;
    }),
    skills: values.skills.map((s) => {
      return s.key;
    }),
  };

  return data;
}
export function Portfolio(props) {
  const form = useRef(null);
  const [modalOpen, setModalOpen] = useModal(false);
  const { classes, enqueueSnackbar, match } = props;
  const [availableSkills, setAvailableSkills] = useState([]);
  const [unavailableSkills, setUnavailableSkills] = useState([]);
  const [portfolio, setPortfolio] = useState({ id: 0 });
  const values = {
    title: '',
    bio: '',
    url: '',
    files: [],
    skills: [],
  };

  function updateFormValues(skills, project) {
    setPortfolio(project);
    const formValues = {
      ...project,
      skills: skills.filter((as) => project.skills.filter((sid) => sid === as.key).length),
    };
    // we do both of these so that 'dirty' is calculated correctly rather than always being dirty
    form.current.initialValues = formValues;
    form.current.setValues(formValues);
  }

  const fetchData = () => {
    fetchSupplierProfile().then((supplier) => {
      setAvailableSkills(() => {
        const asks = supplier.skills.reduce((filtered, s) => {
          if (s.rate !== 0) {
            filtered.push({
              key: s.id,
              value: s.id,
              label: s.name,
            });
          }
          return filtered;
        }, []);

        const projectId = Number(get(match, 'params.id', -1));
        if (projectId >= 0) {
          fetchProjects().then((projects) => {
            const projectsMatch = projects.find((p) => p.id === projectId);
            updateFormValues(asks, projectsMatch);
          });
        }
        return asks;
      });

      setUnavailableSkills(() =>
        supplier.skills.reduce((filtered, s) => {
          if (s.rate === 0) {
            filtered.push({
              key: s.id,
              value: s.id,
              label: s.name,
            });
          }
          return filtered;
        }, []),
      );
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <HeaderPageLinkBack>
      <DeletionModal
        onDelete={(e) => {
          deleteProject(portfolio.id).then(() => {
            updateProjects().then(() => {
              setModalOpen(false);
              enqueueSnackbar(<FormattedMessage id="portfolio.deleted" />);
              props.history.push({ pathname: '/portfolios' });
            });
          });
        }}
        onCancel={(e) => {
          setModalOpen(false);
        }}
        modalOpen={modalOpen}
        itemDeletionLabel={get(portfolio, 'title', '')}
      />
      <div className={classes.root}>
        <Typography variant="h5" color="inherit" className={classes.topHeader}>
          <FormattedMessage id={portfolio.id ? 'portfolio.edit-project' : 'supplier-profile.step2.add-project'} />
        </Typography>
        <Paper className={classes.paperForm} elevation={0}>
          <Formik
            ref={form}
            render={(props) => (
              <PortfolioForm
                {...props}
                availableSkills={availableSkills}
                unavailableSkills={unavailableSkills}
                portfolioId={portfolio.id}
              />
            )}
            initialValues={values}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              (portfolio.id > 0
                ? updateProject(portfolio.id, transformValuesToProject(values))
                : saveProject(transformValuesToProject(values))
              )
                .then((response) => {
                  updateProjects().then(() => {
                    updateFormValues(availableSkills, get(response, 'data.data'));
                    enqueueSnackbar(<FormattedMessage id="portfolio.saved" />);
                    if (portfolio.id === 0) {
                      props.history.push({ pathname: `/portfolio/${response.data.data.id}` });
                    }
                  });
                })
                .finally(() => {
                  actions.setSubmitting(false);
                });
            }}
          />
          {portfolio.id !== 0 && (
            <Button
              fullWidth
              variant="contained"
              className={classes.deleteButton}
              onClick={(e) => {
                setModalOpen(true);
              }}
            >
              {props.intl.formatMessage({
                id: 'portfolio.delete',
              })}
            </Button>
          )}
        </Paper>
      </div>
    </HeaderPageLinkBack>
  );
}

export default withStyles(styles)(withSnackbar(withRouter(injectIntl(Portfolio))));
