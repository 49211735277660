import React from 'react';
import TextInput from '../../FormFields/TextInput';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import ButtonLink from '../../FormFields/ButtonLink';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { FieldArray } from 'formik';

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit,
  },
  buttonLink: {
    color: theme.palette.primary.dark,
  },
  circleIcon: {
    marginRight: theme.spacing.unit * 2,
  },
  taskRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  trashButton: {
    width: theme.spacing.unit * 5,
    height: theme.spacing.unit * 5,
  },
  dateInputs: {
    width: '48%',
  },
  quoteInput: {
    paddingRight: 0,
    paddingLeft: 0,
  },
});

function InvoicingMilestonesWithDate(props) {
  const { milestones, name, classes, ...rest } = props;
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <div className={classes.root}>
          {milestones.map((t, index) => (
            <MilestoneRow
              name={`${name}.${index}`}
              key={index}
              onDelete={() => {
                arrayHelpers.remove(index);
              }}
              classes={classes}
              {...rest}
            />
          ))}
          <p>
            <ButtonLink
              onClick={() => {
                if (
                  milestones.filter(
                    t => t.milestone.trim().length === 0 || t.cost === 0 || t.deadline.trim().length === 0,
                  ).length
                ) {
                  return;
                }
                arrayHelpers.push({ milestone: '', cost: 0, deadline: '' });
              }}
              className={classes.buttonLink}
            >
              <AddCircleOutline className={classes.circleIcon} />
              <FormattedMessage id="projects.sow.milestones.milestone.add" />
            </ButtonLink>
          </p>
        </div>
      )}
    />
  );
}

function MilestoneRow(props) {
  const { classes, name, onDelete, currencies, ...rest } = props;
  return (
    <>
      <div className={classes.taskRow}>
        <TextInput
          name={`${name}.milestone`}
          fullWidth
          margin="normal"
          placeholder="projects.sow.milestones.milestone.placeholder"
          label="projects.sow.milestones.milestone.label"
          {...rest}
        />
        <TextInput
          name={`${name}.cost`}
          margin="normal"
          type="number"
          placeholder="projects.sow.milestones.cost.placeholder"
          label="projects.sow.milestones.cost.label"
          InputLabelProps={{ shrink: true }}
          {...rest}
        />

        <IconButton className={classes.trashButton} onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </div>

      <TextInput
        name={`${name}.deadline`}
        className={classes.dateInputs}
        type="date"
        placeholder="projects.sow.deadline.placeholder"
        label="projects.sow.deadline.label"
        {...rest}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
}
export default withStyles(styles)(injectIntl(InvoicingMilestonesWithDate));
