import React from 'react';
import { injectIntl } from 'react-intl';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import { endOfMonth, addMonths, format } from 'date-fns';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    width: theme.spacing.unit * 64,
    marginBottom: theme.spacing.unit * 2,
  },
});

function AvailabilityCalendar(props) {
  const { classes, availability, onChangeAvailability, isBlindMode, setIsBlindMode } = props;

  const toggleEventAvailability = (data) => {
    const dateStr = data.event ? format(data.event.start, 'YYYY-MM-DD') : data.dateStr;

    onChangeAvailability(
      availability.map((event) =>
        event.start === dateStr
          ? {
              ...event,
              type: event.type === 'busy' ? 'available' : 'busy',
              color: event.type === 'busy' ? 'green' : '#ff0000cf',
              title: isBlindMode
                ? event.type === 'busy'
                  ? props.intl.formatMessage({ id: 'supplier-profile.available' })
                  : props.intl.formatMessage({ id: 'supplier-profile.busy' })
                : '',
            }
          : event,
      ),
    );
  };

  const changeEventsViewMode = () => {
    setIsBlindMode((prevBlindModeVal) => {
      onChangeAvailability(
        availability.map((event) => ({
          ...event,
          display: !prevBlindModeVal ? 'auto' : 'background',
          title: !prevBlindModeVal
            ? event.type === 'busy'
              ? props.intl.formatMessage({ id: 'supplier-profile.busy' })
              : props.intl.formatMessage({ id: 'supplier-profile.available' })
            : '',
        })),
      );
      return !isBlindMode;
    });
  };

  return (
    <div className={classes.container}>
      <FullCalendar
        plugins={[interactionPlugin, dayGridPlugin]}
        initialView="dayGridMonth"
        weekends={false}
        validRange={(nowDate) => {
          return { start: nowDate, end: endOfMonth(addMonths(nowDate, 3)) };
        }}
        events={availability}
        eventClick={isBlindMode ? toggleEventAvailability : undefined}
        dateClick={toggleEventAvailability}
        headerToolbar={{
          left: 'prev,next toggleBlindMode',
          center: 'title',
          right: '',
        }}
        customButtons={{
          toggleBlindMode: {
            text: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginLeft: 4 }}>
                  {isBlindMode
                    ? props.intl.formatMessage({ id: 'supplier-profile.availability.blind-mode.deactivate' })
                    : props.intl.formatMessage({ id: 'supplier-profile.availability.blind-mode.activate' })}
                </span>
              </div>
            ),
            click: () => {
              changeEventsViewMode();
            },
          },
        }}
      />
    </div>
  );
}

export default withStyles(styles)(injectIntl(AvailabilityCalendar));
