import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { LargeAvatar } from './SupplierAvatar';
import Availability from '../ClientSearch/Components/Availability';
import SkillsAndRates from '../ClientSearch/Components/SkillsAndRates';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { injectIntl, FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { getSupplierProfileById } from '../Api/SupplierProfile';
import Progress from '../FormFields/Progress';
import RemoteAvailability from '../ClientSearch/Components/RemoteAvailability';
import Languages from '../ClientSearch/Components/Languages';
import skillIcon from 'assets/Icon-Skill.svg';
import HeaderPageLinkBack from '../Pages/HeaderPageLinkBack';
import { ShortlistContext } from '../Shortlist/ShortlistContext';
import ShortlistToolbar from './Components/ShortlistToolbar';
import SupplierProject from './Components/SupplierProject';

const styles = theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing.unit * 2,
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit,
    },
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 10,
    display: 'flex',
    flexDirection: 'column',
  },
  skillCount: {
    marginLeft: theme.spacing.unit * 2,
  },
  skillIcon: {
    fill: 'grey',
    height: 24,
    width: 24,
  },
  listItemRoot: {
    paddingRight: '0px',
  },
  itemRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 2 * theme.spacing.unit,
    flexWrap: 'wrap',
    color: theme.palette.primary.main,
    alignItems: 'flex-start',
  },
  itemAbout: {
    display: 'flex',
    '& svg': {
      color: 'grey',
    },
    '& > div': {
      flexBasis: '32%',
    },
  },
});

export function SupplierProfileClientView(props) {
  const { classes, item, forceScreen } = props;

  return (
    <>
      <Paper className={classes.root} elevation={0}>
        <ListItem disableGutters>
          <ListItemAvatar>
            <LargeAvatar item={item} />
          </ListItemAvatar>
          <ListItemText
            className={classes.listItemRoot}
            primary={
              <div className={classes.itemRow}>
                <Typography variant="h5" color="textPrimary">
                  {item.name}
                </Typography>
                <Availability item={item} />
              </div>
            }
            secondaryTypographyProps={{ component: 'div' }}
            secondary={
              <div>
                <div className={classes.itemRow}>
                  <SkillsAndRates skills={item.skills} currencySymbol={item.currency.symbol} />
                </div>
              </div>
            }
          />
        </ListItem>
        <Divider />
        <ListItem disableGutters>
          <ListItemText
            className={classes.listItemRoot}
            primary={
              <Typography variant="h6" color="textPrimary">
                <p>
                  <FormattedMessage
                    id="supplier-profile-client-view.bio.header"
                    values={{
                      name: item.name,
                    }}
                  />
                </p>
              </Typography>
            }
            secondaryTypographyProps={{ component: 'div' }}
            secondary={
              <div className={classNames(classes.itemRow, classes.itemAbout)}>
                <RemoteAvailability item={item} iconStyle={'outlined'} />
                <Languages item={item} excludeEnglish={false} />
                <div className={classes.itemRow}>
                  <img src={skillIcon} alt="" className={classes.skillIcon} />
                  <div className={classes.skillCount}>
                    <Typography variant="body2" color="textPrimary">
                      <FormattedMessage
                        id="supplier-profile-client-view.skills.header"
                        values={{
                          length: item.skills.length,
                        }}
                      />
                    </Typography>
                  </div>
                </div>
              </div>
            }
          />
        </ListItem>
        <Divider />
        <ListItem disableGutters>
          <ListItemText
            className={classes.listItemRoot}
            primary={
              <Typography variant="h6" color="textPrimary">
                <p>
                  <FormattedMessage
                    id="supplier-profile-client-view.bio.header2"
                    values={{
                      name: item.name,
                    }}
                  />
                </p>
              </Typography>
            }
            secondaryTypographyProps={{ component: 'div' }}
            secondary={
              <div className={classes.itemRow}>
                <Typography variant="body2" color="textPrimary">
                  {item.bio.value}
                </Typography>
              </div>
            }
          />
        </ListItem>
      </Paper>

      <Typography variant="h5" color="textPrimary">
        <FormattedMessage
          id="supplier-profile-client-view.portfolio.header"
          values={{
            name: item.name,
          }}
        />
      </Typography>
      <Paper className={classes.root} elevation={0}>
        <List className={classes.list}>
          {item.projects.map((project, idx) => {
            return (
              <div key={idx}>
                <ListItem disableGutters>
                  <SupplierProject
                    item={project}
                    skills={item.skills.filter(s => project.skills.find(sId => sId === s.id))}
                    forceScreen={forceScreen}
                  />
                </ListItem>
                {idx < item.projects.length - 1 && <Divider component="li" />}
              </div>
            );
          })}
        </List>
      </Paper>
      <ShortlistToolbar item={item} forceScreen={forceScreen} />
    </>
  );
}

function WhatComponent(props) {
  const { match, classes, ...rest } = props;
  const [item, setItem] = useState(false);
  const [tmpItem, setTmpItem] = useState(false);
  const { shortlistedSuppliers } = useContext(ShortlistContext);

  const fetchData = () => {
    const supplierId = Number(get(match, 'params.id', -1));
    getSupplierProfileById(supplierId).then(supplier => {
      setTmpItem(get(supplier, 'data.data', {}));
    });
  };

  useEffect(() => {
    if (tmpItem) {
      const supplierIsShortlisted = shortlistedSuppliers.filter(r => r.id === tmpItem.id).length > 0;
      setItem({ ...tmpItem, shortlisted: supplierIsShortlisted });
    }
  }, [shortlistedSuppliers, tmpItem]);

  useEffect(() => {
    fetchData();
  }, []);

  if (!item) {
    return (
      <Paper className={classes.root} elevation={0}>
        <Progress />
      </Paper>
    );
  } else {
    return <SupplierProfileClientView item={item} classes={classes} {...rest} />;
  }
}

export function SupplierProfile(props) {
  return (
    <HeaderPageLinkBack>
      <WhatComponent {...props} />
    </HeaderPageLinkBack>
  );
}

export default withStyles(styles)(withSnackbar(withRouter(injectIntl(SupplierProfile))));
