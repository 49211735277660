import React, { useState, useEffect } from 'react';
import { postCodeValidation } from '../Api/Validation';
import { useDebounce } from 'use-debounce';
import get from 'lodash/get';
import TextInput from './TextInput';

const PostCodeInput = function(props) {
  const { field, form, country, onStart, onSuccess, onFailure, ...rest } = props;
  const [fieldvalue, setFieldValue] = useState('');
  const debouncedCheckPostCode = useDebounce(fieldvalue, 2000);

  useEffect(
    () => {
      !!country.length && debouncedCheckPostCode && checkPostCode(debouncedCheckPostCode);
    },
    [debouncedCheckPostCode],
  );

  const checkPostCode = function(value) {
    // only check if the field passes Yup email validation first
    if (
      !get(form, `errors[${field.name}]`, []).includes(
        'supplier-profile.postcode.required' /* or whatever your error message is*/,
      )
    ) {
      if (!country.length) {
        return;
      }
      onStart();
      postCodeValidation(value, country)
        .then(response => {
          if (!response.data.error) {
            form.setFieldError(field.name, undefined);
          } else {
            form.setFieldError(field.name, 'supplier-profile.postcode.valid');
          }
          onSuccess();
        })
        .catch(e => {
          form.setFieldError(field.name, 'supplier-profile.postcode.valid');
          onFailure();
        });
    }
  };

  const onChange = function(e) {
    field.onChange(e); // this will let Formik's default validation take place
    setFieldValue(e.target.value);
  };

  const onBlur = function(e) {
    field.onBlur(e); // this will let Formik's default validation take place
    checkPostCode(e.target.value);
  };

  return (
    <div>
      <TextInput {...field} {...rest} onChange={onChange} onBlur={onBlur} />
    </div>
  );
};

export default PostCodeInput;
