import React from 'react';
import { injectIntl } from 'react-intl';
import TextField from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    width: '100%',
  },
  textField: {
    marginBottom: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit,
    fontSize: theme.spacing.unit * 1.5,
    width: '45%',
  },
});

function DaysAndMonthsFilter(props) {
  const { classes, days, months, onChangeDays, onChangeMonths } = props;
  return (
    <div className={classes.container}>
      <TextField
        value={days}
        onChange={(e) => e.target.value >= 0 && onChangeDays(e.target.value)}
        className={classes.textField}
        type="number"
        label={`${props.intl.formatMessage({ id: 'filter.menu.availability.days.label' })}`}
        placeholder={`${props.intl.formatMessage({ id: 'filter.menu.availability.days.label' })}`}
        inputProps={{ min: 0 }}
      />
      <TextField
        value={months}
        onChange={(e) => e.target.value >= 0 && onChangeMonths(e.target.value)}
        className={classes.textField}
        type="number"
        label={`${props.intl.formatMessage({ id: 'filter.menu.availability.months.label' })}`}
        placeholder={`${props.intl.formatMessage({ id: 'filter.menu.availability.months.label' })}`}
        inputProps={{ min: 0 }}
      />
    </div>
  );
}

export default withStyles(styles)(injectIntl(DaysAndMonthsFilter));
