import React from 'react';
import { Divider, Typography, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { distanceInWordsToNow } from 'date-fns';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit,
  },
  divider: { width: '100%' },
  messageContainer: {
    transform: `translateY(-50%) translateY(${theme.spacing.unit}px)`,
  },
  message: {
    borderRadius: 4,
    backgroundColor: '#f5f5f5',
    padding: theme.spacing.unit,
  },
});

function DividerWithText(props) {
  const { classes, message, date } = props;

  return (
    <div className={classes.root}>
      <Divider className={classes.divider} />
      <div className={classes.messageContainer}>
        <div className={classes.message}>
          <Typography variant="body2">{message}</Typography>
        </div>
        <div className={classes.messageDate}>
          <Tooltip title={date}>
            <Typography variant="caption">{distanceInWordsToNow(new Date(date))}</Typography>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
export default withStyles(styles)(DividerWithText);
