import React from 'react';
import { injectIntl } from 'react-intl';

import TextInput from '../FormFields/TextInput';
import { withStyles } from '@material-ui/core/styles';
import ProgressButton from '../FormFields/ProgressButton';

const styles = theme => ({
  root: {
    '& > *': {
      marginBottom: '20px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '32px',
      },
    },
  },
});

function CompanyInformationForm(props) {
  const {
    values,
    touched,
    errors,
    // dirty,
    isSubmitting,
    isValid,
    handleChange,
    handleBlur,
    // handleSubmit,
    // handleReset,
    classes,
  } = props;

  return (
    <form onSubmit={props.handleSubmit} className={classes.root}>
      <TextInput
        required
        fullWidth
        margin="normal"
        name="companyName"
        value={values.companyName}
        placeholder="company.name.placeholder"
        label="company.name.label"
        helperText={touched.companyName && errors.companyName ? errors.companyName : 'company.name.helper'}
        error={Boolean(touched.companyName && errors.companyName)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput        
        fullWidth
        margin="normal"
        name="companyNumber"
        value={values.companyNumber}
        placeholder="company.number.placeholder"
        label="company.number.label"
        helperText={touched.companyNumber && errors.companyNumber ? errors.companyNumber : 'company.number.helper'}
        error={Boolean(touched.companyNumber && errors.companyNumber)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />
      <TextInput
        fullWidth
        margin="normal"
        name="vatNumber"
        value={values.vatNumber}
        placeholder="company.vatnumber.placeholder"
        label="company.vatnumber.label"
        helperText={touched.vatNumber && errors.vatNumber ? errors.vatNumber : 'company.vatnumber.helper'}
        error={Boolean(touched.vatNumber && errors.vatNumber)}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={isSubmitting}
      />

      <ProgressButton
        type="submit"
        submitting={isSubmitting}
        buttonBusy={isSubmitting}
        disabled={!isValid}
        data-testid="submit-button"
      >
        {props.intl.formatMessage({
          id: 'save',
        })}
      </ProgressButton>
    </form>
  );
}

export default withStyles(styles)(injectIntl(CompanyInformationForm));
