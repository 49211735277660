import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ReactComponent as ProfileImage } from '../assets/Profile-Prompt-Image.svg';
import timeIcon from '../assets/Icon-Time.svg';

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 6,
    width: theme.spacing.unit * 85,
    border: `1px solid ${theme.palette.grey[400]}`,
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  card: {
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    flexGrow: 1,
    display: 'flex',
    paddingTop: theme.spacing.unit * 6,
    paddingBottom: theme.spacing.unit * 6,
  },
  cardContent: {
    maxWidth: theme.spacing.unit * 70,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    flexGrow: 1,
    '& > *': {
      marginBottom: '0.75em',
    },
  },
  timeTaker: {
    paddingTop: '10px',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  timeTakerMedia: {
    display: 'flex',
    margin: '16px',
  },
  timeTakerText: {
    display: 'flex',
  },
  cardActions: {
    flexDirection: 'column',
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
});

function ProfileUpdatePromptPage(props) {
  const { classes, linkTo } = props;
  return (
    <Paper className={classes.paper} elevation={0}>
      <Card className={classes.card}>
        <ProfileImage />
        <CardContent className={classes.cardContent}>
          <Typography gutterBottom variant="h5">
            <FormattedMessage id="profile-update.prompt.header" />
          </Typography>
          <Typography gutterBottom variant="body2">
            <FormattedMessage id="profile-update.prompt.more-info-blurb" />
          </Typography>
          <Typography gutterBottom variant="body2" className={classes.timeTaker}>
            <img src={timeIcon} alt="" className={classes.timeTakerMedia} />
            <FormattedMessage className={classes.timeTakerText} id="profile-update.prompt.time-estimate" />
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button type="submit" fullWidth variant="contained" color="primary" component={Link} to={linkTo}>
            <FormattedHTMLMessage id="profile-update.prompt.get-started" />
          </Button>
        </CardActions>
      </Card>
    </Paper>
  );
}

export default withStyles(styles)(ProfileUpdatePromptPage);
