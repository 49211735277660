import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Progress from './Progress';

const styles = theme => ({
  button: {
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    backgroundColor: '#B00020',
    marginBottom: theme.spacing.unit * 2,
    color: 'white',
    '&:hover': {
      backgroundColor: '#B00020',
    },
  },
  busyButton: {
    backgroundColor: '#B00020',
    color: 'white',
    '&:hover': {
      backgroundColor: '#B00020',
      color: 'white',
    },
    '&:disabled': {
      backgroundColor: '#B00020',
      color: 'white',
    },
    '&:hover:disabled': {
      backgroundColor: '#B00020',
      color: 'white',
    },
  },
  progress: {
    marginLeft: theme.spacing.unit * 2,
  },
});

function ProgressDeleteButton(props) {
  const { classes, buttonBusy, children, className, ...rest } = props;
  return (
    <Button
      className={classNames(classes.button, className, { [classes.busyButton]: buttonBusy })}
      fullWidth
      variant="contained"
      disabled={buttonBusy}
      {...rest}
    >
      {buttonBusy ? <Progress className={classes.progress} /> : children}
    </Button>
  );
}

ProgressDeleteButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

ProgressDeleteButton.defaultProps = {
  buttonBusy: false,
};

export function useProgressDeleteButton(initialValue) {
  const [buttonBusy, setButtonBusy] = useState(initialValue);
  return [buttonBusy, setButtonBusy];
}

export default withStyles(styles)(ProgressDeleteButton);
