import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SmallAvatar } from '../../SupplierProfile/SupplierAvatar';
import { Link } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import statuses from '../ProjectStatuses';
import { FormattedMessage } from 'react-intl';

const styles = (theme) => ({
  supplierRow: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
  },
  avatar: {
    marginRight: theme.spacing.unit * 2,
  },
  buttons: {
    marginTop: theme.spacing.unit * 2,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
});

function FinishProjectSupplierRow(props) {
  const { supplier, onSubmit, classes } = props;

  return (
    <div className={classes.supplierRow} key={supplier.id}>
      <div
        className={classes.titleSection}
        style={
          supplier.job_status === statuses.end_project_client_accepted
            ? {
                backgroundColor: '#FFFDE9',
              }
            : null
        }
      >
        <SmallAvatar item={supplier} className={classes.avatar} />
        <Link to={`/supplier/${supplier.id}`} style={{ textDecoration: 'none' }}>
          <Typography variant="body2">{supplier.name}</Typography>
        </Link>
      </div>
      <div className={classes.buttons}>
        <Button
          className={classes.button}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => onSubmit(10, supplier.id)}
          disabled={
            supplier.job_status !== statuses.end_project_request &&
            supplier.job_status !== statuses.end_project_client_rejected
          }
        >
          <FormattedMessage id="projects.management-page.finish-project.client.accept" />
        </Button>
        <Button
          className={classes.button}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => onSubmit(11, supplier.id)}
          disabled={supplier.job_status !== statuses.end_project_request}
        >
          <FormattedMessage id="projects.management-page.finish-project.client.reject" />
        </Button>
      </div>
    </div>
  );
}

export default withStyles(styles)(FinishProjectSupplierRow);
