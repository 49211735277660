import React from 'react';
import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import uniqueId from 'lodash/uniqueId';
import map from 'lodash/map';
import startsWith from 'lodash/startsWith';
import { ReactComponent as Logo } from '../assets/JP-logo.svg';
import { fetchSupplierProfile, fetchClientProfile } from '../Api/FetchAndCache';
import { getUserType } from '../Api/LocalStorage';
import { getLocalProfile } from '../Api/LocalStorage';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { withTheme } from '@material-ui/core/styles';
import HeaderTab from './HeaderTab';
import NotificationsPopover from '../Notifications/NotificationsPopover';
import { ProfileContext } from '../Profile/ProfileContext';

const styles = (theme) => ({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    height: theme.spacing.unit * 8,
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing.unit * 6,
    },
  },
  toolbar: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    textTransform: 'none',

    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
  },
  smallerScreenAppBar: {},
  smallerScreenToolBar: {},

  margin: {
    margin: theme.spacing.unit * 2,
  },
  logo: {
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing.unit * 12,
    },
  },
});

const supplierTabs = [
  { value: '/dashboard', matcher: '/dashboard', label: 'dashboard', key: uniqueId() },
  { value: '/skills', matcher: '/skills', label: 'skills', key: uniqueId() },
  { value: '/portfolios', matcher: '/portfolio', label: 'portfolio', key: uniqueId() },
  { value: '/profile/details', matcher: '/profile', label: 'profile', key: uniqueId() },
];
const clientTabs = [
  { value: '/dashboard', matcher: '/dashboard', label: 'dashboard', key: uniqueId() },
  { value: '/search', matcher: '/search', label: 'client.find.suppliers', key: uniqueId() },
  { value: '/profile/details', matcher: '/profile', label: 'profile', key: uniqueId() },
];

export function AuthenticatedHeader(props) {
  const { classes, theme, forceScreen, children } = props;
  const [value, setValue] = useState();
  const [profileType, setProfileType] = useState(false);
  const [tabs, setTabs] = useState([]);
  const smallScreen = forceScreen ? false : useMediaQuery(theme.breakpoints.down('xs'));
  const { retrieveProfile } = useContext(ProfileContext);

  useEffect(() => {
    const location = map(tabs).filter((v) => props.location.pathname.startsWith(v.matcher));
    setValue(location.length ? location[0].value : '/dashboard');

    if (getUserType() === 'supplier') {
      fetchSupplierProfile().then((supplier) => {
        if (supplier.profileStep2Complete && supplier.is_admin_active && supplier.last_msa_agreed) {
          setProfileType('supplier');
        }
      });
    } else if (getUserType() === 'client') {
      fetchClientProfile().then((client) => {
        if (client.profileStepsComplete && client.is_admin_active) {
          setProfileType('client');
        }
      });
    }
  }, [tabs, props.location.pathname]);

  useEffect(() => {
    if (profileType === 'supplier') {
      setTabs(supplierTabs);
    } else if (profileType === 'client') {
      const hideSearch = getLocalProfile().hide_suppliers_search;
      if (hideSearch) setTabs(clientTabs.filter((clientTab) => clientTab.value !== '/search'));
      else setTabs(clientTabs);
    }
  }, [profileType]);

  useEffect(() => {
    retrieveProfile();
  }, []);

  useEffect(() => {
    const location = map(tabs).filter((v) => startsWith(props.location.pathname, v.matcher));
    setValue(location.length ? location[0].value : '/dashboard');
  }, [props.location.pathname]);

  return (
    <>
      <AppBar position="static" elevation={1} className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Link to="/dashboard" className="no-underline">
            <Logo className={classes.logo} />
          </Link>

          {!smallScreen && <HeaderTab value={value} tabs={tabs} />}
          {children}
          <NotificationsPopover />
          <Link to="/logout">
            <Typography variant="body2" color="textPrimary">
              <FormattedMessage id="logout" />
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>
      {smallScreen && (
        <AppBar position="static" elevation={1} className={classes.smallerScreenAppBar}>
          <Toolbar className={classes.smallerScreenToolBar} disableGutters={true}>
            <HeaderTab value={value} tabs={tabs} />
          </Toolbar>
        </AppBar>
      )}
    </>
  );
}

AuthenticatedHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

AuthenticatedHeader.defaultProps = {
  forceScreen: false,
};

export default withTheme()(withStyles(styles)(withRouter(AuthenticatedHeader)));
